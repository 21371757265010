import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { CustomButton, CustomButtonStyle } from '../../../../styles/muiRoot';
import { PlusCircle } from 'lucide-react';
import CreateBanner from './createBanner';
import BannerList from './Banner';
import Header from '../../../common/header';
import { Outlet, Route, Routes } from 'react-router-dom';
import BannerDetail from './bannerDetail';

// import FullWidthTabs from '../../../common/tabChanger';

export default function BannerMain() {
    const [showCreateBanner, setShowCreateBanner] = useState(false); // State to toggle view

    const handleCreateBannerClick = () => {
        setShowCreateBanner(true); // Show CreateBanner component
    };

    return (
        <>


            {/* <Header content={"Banner"} /> */}

            {/* Only show header when not in CreateBanner view */}
            {!showCreateBanner && (
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    px={5}
                    py={1}
                >
                    <Typography variant="body1">
                        <h1 className="ml-5 space-x-9 text-xl text-gray-700 font-bold">
                            Live Banner
                        </h1>
                    </Typography>

                    <div className="flex gap-2 items-center">
                        <CustomButton
                            style={{ ...CustomButtonStyle, marginRight: "35px" }}
                            startIcon={<PlusCircle />}
                            onClick={handleCreateBannerClick} // Handle click to show CreateBanner
                        >
                            Create Banner
                        </CustomButton>
                    </div>
                </Stack>
            )}

            {/* Conditional Rendering */}
            {showCreateBanner ? (
                <CreateBanner showBanner={setShowCreateBanner} />
            ) : (
                <>
                <BannerList />
                {/* <Outlet/> */}
            </> 
            )}



        </>
    );
}





