// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { notificationApi } from "../Constant";

export const NotificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
  tagTypes: ["getNotification", "getNotificationPackage"],
  endpoints: (builder) => ({

    getNotificationsList: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: ["getNotificationsList"],
    }),

    createNotification: builder.mutation({
      query: ({ newNotification }) => ({
        url: `${notificationApi.endPoint}`,
        method: "POST",
        body: newNotification,

      }),
      invalidatesTags: [], // Add relevant tags here if necessary
    }),
    uploadNotificationMedia: builder.mutation({
      query: (formData) => {
        return {
          url: `${notificationApi.endPoint}/upload/image`,
          method: 'POST',
          body: formData,
          headers: {
            // Removing 'Content-Type' header lets the browser set the correct boundary
          },
        };
      },
    }),




  }),
});

export const {
   useCreateNotificationMutation,
   useGetNotificationsListQuery,
   useUploadNotificationMediaMutation

   } = NotificationApi;




// notification Api component //


// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { notificationApi } from "../Constant";

// export const NotificationApi = createApi({
//   reducerPath: "notificationApi",
//   baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
//   tagTypes: ["getNotification", "getNotificationPackage"],
//   endpoints: (builder) => ({
//     getNotificationsList: builder.query({
//       query: () => notificationApi.endPoint,  // Directly use the endpoint defined in constants
//       providesTags: ["getNotificationsList"],
//     }),
//     createNotification: builder.mutation({
//       query: ({ newNotification }) => ({
//         url: notificationApi.endPoint,
//         method: "POST",
//         body: newNotification,
//       }),
//       invalidatesTags: ["getNotificationsList"], // If notifications list is affected by this mutation
//     }),
//   }),
// });

// export const {
//    useCreateNotificationMutation,
//    useGetNotificationsListQuery
// } = NotificationApi;






// {{8004}}/exams/v1/notification
// send push notification