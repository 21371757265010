import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Avatar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../ducks/notificationSlice";
import { useCreateNotificationMutation, useUploadNotificationMediaMutation } from "../../../../services/apis/notificationApi";
import { useGetCoursesQuery } from "../../../../services/apis/exam/courses";
import { courseApi, entityApi, entityTypeApi, interestApi } from "../../../../services/Constant";
import { useGetEntityTypeListQuery } from "../../../../services/apis/dataManagement/entityType";
import { useGetEntityListQuery } from "../../../../services/apis/dataManagement/entity";
import { useGetInterestListQuery } from "../../../../services/apis/dataManagement/interest";

const AddNotification = ({ onFormSubmit, onCancel }) => {
  const [title, setNotificationTitle] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [topic, setAudience] = useState("");
  const [sourceType, setsourceType] = useState("");
  const [sourceId, setsourceId] = useState(null);
  const [notificationType, setnotificationType] = useState(null);
  let [uploadMedia] = useUploadNotificationMediaMutation()

  // const [entity, setEntity] = useState("");
  // const [entityType, setEntityType] = useState("");
  // const [course, setCourse] = useState("");
  // const [interest, setInterest] = useState("");


  const dispatch = useDispatch()

  let [createNotification] = useCreateNotificationMutation()


  // {{8004}}/exams/v1/course/basic?page=1&limit=2&search=title11
  const {
    isSuccess: isCoursesSuccess,
    isLoading: isCoursesLoading,
    isError: isCoursesError,
    data: coursesData,
    error: coursesError
  } = useGetCoursesQuery(`${courseApi.endPoint}/basic`);

  const {
    isSuccess: isEntityTypeSuccess,
    isLoading: isEntityTypeLoading,
    isError: isEntityTypeError,
    data: entityTypeData,
    error: entityTypeError
  } = useGetEntityTypeListQuery(`${entityTypeApi.endPoint}`);

  const {
    isSuccess: isEntitySuccess,
    isLoading: isEntityLoading,
    isError: isEntityError,
    data: entityData,
    error: entityError
  } = useGetEntityListQuery(`${entityApi.endPoint}`);


  const {
    isSuccess: isInterestSuccess,
    isLoading: isInterestLoading,
    isError: isInterestError,
    data: interestData,
    error: interestError
  } = useGetInterestListQuery(`${interestApi.endPoint}/list`);


  useEffect(() => {
    // Handle Courses API data
    if (isCoursesSuccess && coursesData) {
      console.log('Fetched Courses Data:', coursesData);
    }
    if (isCoursesError) {
      console.error('Courses Fetch Error:', coursesError);
    }

    // Handle Entity Types API data
    if (isEntityTypeSuccess && entityTypeData) {
      console.log('Fetched Entity Type Data:', entityTypeData);
    }
    if (isEntityTypeError) {
      console.error('Entity Type Fetch Error:', entityTypeError);
    }

    // Handle Entity Types API data
    if (isEntitySuccess && entityData) {
      console.log('Fetched Entity  Data:', entityData);
    }
    if (isEntityError) {
      console.error('Entity  Fetch Error:', entityError);
    }
    // Handle Entity Types API data
    if (isInterestSuccess && interestData) {
      console.log('Fetched Interest  Data:', interestData);
    }
    if (isInterestError) {
      console.error('Interest  Fetch Error:', interestError);
    }
  }, [
    isCoursesSuccess,
    coursesData,
    isCoursesError,
    coursesError,
    isEntityTypeSuccess,
    entityTypeData,
    isEntityTypeError,
    entityTypeError,
    isEntitySuccess,
    entityData,
    isEntityError,
    entityError,
    isInterestSuccess,
    interestData,
    isInterestError,
    interestError
  ]);


  let d = coursesData?.data?.courseList;
  // console.log(d);

  let ETypeData = entityTypeData?.data;
  // console.log(ETypeData);

  let EData = entityData?.data;
  // console.log(EData);

  let interest = interestData?.data?.interest;
  console.log(interest);



  // Handle loading state
  if (isCoursesLoading || isEntityTypeLoading || isEntityLoading || isInterestLoading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (isCoursesError || isEntityTypeError || isEntityError || isInterestError) {
    return <div>Error: {coursesError?.message || entityTypeError?.message || entityError?.message || interestError?.message}</div>;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newNotification = {
      title,
      type: notificationType,
      url,
      description,
      image,
      topic: sourceId,
      sourceType,
      sourceId
    };
    onFormSubmit(newNotification);


    try {
      await createNotification({
        newNotification
      }).unwrap();

      // Dispatch the newFeed to your Redux store
      dispatch(setNotification(newNotification));
    } catch (err) {
      console.error("Failed to create feed algorithm:", err);
    }
  };



  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // Update the bannerData state with the selected file (optional if needed)
    setNotification((prev) => ({
        ...prev,
        imagePreview: URL.createObjectURL(file),
    }));

    // setImagePreview(URL.createObjectURL(file))
  

    // Create FormData and append the file under the 'file' key
    const formData = new FormData();
    formData.append('image', file);  // Key should match what the API expects

    try {
        // Upload the file using the uploadMedia mutation
        const response = await uploadMedia(formData).unwrap();

        // Assuming `response.url` contains the image URL returned by the API
        const imageURL = response.data;

        console.log(imageURL);

        setImage(imageURL)
        // Update the bannerData state with the new image URL
        // setNotification((prev) => ({
        //     ...prev,
        //     image: imageURL,
        // }));
    } catch (error) {
        console.error('Upload failed:', error);
    }
};




  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        maxWidth: "1000px",
        mx: "auto",
        p: 4,
        bgcolor: "background.paper",
        boxShadow: 40,
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}>
            Notification Title
          </Typography>
          <TextField
            placeholder="Enter text here"
            value={title}
            onChange={(e) => setNotificationTitle(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />

          <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}>
            Notification Type
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Notification Type</InputLabel>
            <Select
              value={notificationType}
              onChange={(e) => setnotificationType(e.target.value)}
              label="Select Notification Type"
            >

              <MenuItem value="events">   events </MenuItem>
              <MenuItem value="blog">   blog </MenuItem>
              <MenuItem value="chat">   chat </MenuItem>
              <MenuItem value="groupFeed">   groupFeed </MenuItem>
              <MenuItem value="feed">   feed </MenuItem>
              <MenuItem value="iverse">   iverse </MenuItem>
              <MenuItem value="people">   people </MenuItem>
              <MenuItem value="learn">   learn </MenuItem>
              <MenuItem value="exam">   exam </MenuItem>
              <MenuItem value="course">   course </MenuItem>
              <MenuItem value="analytics">   analytics </MenuItem>
              <MenuItem value="groups">   groups </MenuItem>
              <MenuItem value="assignment">   assignment </MenuItem>
              <MenuItem value="pastPaper">   pastPaper </MenuItem>
              <MenuItem value="dailyQuiz">   dailyQuiz </MenuItem>
              <MenuItem value="practiceQa">   practiceQa </MenuItem>
              <MenuItem value="mockPackage">   mockPackage </MenuItem>
              <MenuItem value="mockTest">   mockTest </MenuItem>

            </Select>
          </FormControl>

          <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}>
            URL
          </Typography>
          <TextField
            placeholder="Enter URL here"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}>
            Select Audience
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Audience</InputLabel>
            <Select
              value={sourceType}
              onChange={(e) => setsourceType(e.target.value)}
              label="Audience"
              required
            >
              <MenuItem value="iverse">iverse</MenuItem>
              <MenuItem value="Entity">Entity</MenuItem>
              <MenuItem value="EntityType">Entity Type</MenuItem>
              <MenuItem value="course">Course</MenuItem>
              <MenuItem value="interest">Interest</MenuItem>
            </Select>
          </FormControl>
          {sourceType === "Entity" && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Entity List</InputLabel>
              <Select
                value={sourceId}
                onChange={(e) => setsourceId(e.target.value)}
                label="Select Entity List"
              >
                {EData?.length > 0 && EData?.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {sourceType === "EntityType" && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Entity Type List</InputLabel>
              <Select
                value={sourceId}
                onChange={(e) => setsourceId(e.target.value)}
                label="Select Entity Type List"
              >
                {ETypeData?.length > 0 && ETypeData?.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.title}
                  </MenuItem>
                ))}

                {/* <MenuItem value="CAT">CAT</MenuItem>
                <MenuItem value="NMAT">NMAT</MenuItem> */}
              </Select>
            </FormControl>
          )}
          {sourceType === "course" && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Course List</InputLabel>
              <Select
                value={sourceId}
                onChange={(e) => setsourceId(e.target.value)}
                label="Select Course List"
              >
                {d?.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {sourceType === "interest" && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Interest List</InputLabel>
              <Select
                value={sourceId}
                onChange={(e) => setsourceId(e.target.value)}
                label="Select Interest List"
              >
                {interest?.map((value) => (
                  <MenuItem key={value._id} value={value._id}>
                    {value.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Typography variant="h6" gutterBottom style={{ fontSize: 15 }}>
            Notification Description
          </Typography>
          <TextField
            placeholder="Enter description here"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={8}
            sx={{ mb: 2, width: "calc(100% + 220px)" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "120px",
              height: "120px",
              border: "1px dashed grey",
              textAlign: "center",
              borderRadius: "50%",
              mx: "auto",
              position: "relative",
            }}
          >
            {image ? (
              <Avatar
                src={image}
                alt="Selected"
                sx={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Typography variant="body2" style={{ color: "blue" }}>
                Add Photo
              </Typography>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: "pointer",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button onClick={onCancel} variant="outlined" sx={{ mr: 1 }}>
          Preview
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default AddNotification;
