// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
  tagTypes: ["getOnboardingList"],
  endpoints: (builder) => ({
    getOnboardingList: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: ["getOnboardingList"],
    }),

    getSingleOnboarding: builder.query({
      query: (endpoint) => `${endpoint}`,
    }),
  
    deleteOnboarding: builder.mutation({
      query: (endpoint) => ({
        url: `${endpoint}`,
        method: "DELETE",
      }),
      invalidatesTags: ["deleteOnboardingList"],
    }),

    addOnboarding: builder.mutation({
      query: ({ endpoint, data }) => ({
        url: endpoint,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["addOnboardingList"],
    }),

    updateOnboarding: builder.mutation({
      query: ({ endpoint, data }) => ({
        url: endpoint,
        method: "PATCH",
        body: JSON.stringify(data),
      }),
      invalidatesTags: ["getInstructionsList"],
    }),
  }),
});

export const {
  useAddOnboardingMutation,
  useGetOnboardingListQuery,
  useUpdateOnboardingMutation
} = onboardingApi;
