import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    viewDetails: {},
    title: '',
    description: '',
    sourceType: '',
    sourceId: '',
    buttonType: '',
    startDate: '',
    endDate: '',
    offer: '',
    thumbnail: ''
}

export const bannerSlice = createSlice({
    name: "bannerSlice",
    initialState,
    reducers: {

        viewBanners: (state = initialState, { payload }) => {
            return {
                ...state,
                viewDetails: payload,
            };
        },

        setBanner: (state = initialState, { payload }) => {
            console.log(payload);

            return {
                ...state,
                title: payload.title,
                description: payload.description,
                sourceType: payload.sourceType,
                sourceId: payload.sourceId,
                offer: payload.offer,
                buttonType: payload.buttonType,
                startDate: payload.startDate,
                endDate: payload.endDate,
                thumbnail: payload.thumbnail

            }
        }

    }
})


export const {
    viewBanners,
    setBanner



} = bannerSlice.actions