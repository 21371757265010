
// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// const GrowthGraph = ({ selectedExams, growth }) => {

//   let Chart = growth.formattedResult;
// // console.log(Chart);


//   let d = Chart.map((val, index) => (
//     {
//       name: val.date,
//       CAT: val.CAT,
//       NMAT: val.NMAT,
//       XAT: val.XAT
//     }
//   ))

//   const data = [
//     { name: 3, CAT: 70, GMAT: 80, TOFEL: 90, GRE: 100 },
//     { name: 9, CAT: 90, GMAT: 100, TOFEL: 110, GRE: 120 },
//     { name: 12, CAT: 95, GMAT: 110, TOFEL: 120, GRE: 130 },
//     { name: 15, CAT: 95, GMAT: 115, TOFEL: 125, GRE: 135 },
//     { name: 18, CAT: 110, GMAT: 120, TOFEL: 130, GRE: 140 },
//     { name: 21, CAT: 100, GMAT: 110, TOFEL: 120, GRE: 130 },
//     { name: 24, CAT: 110, GMAT: 120, TOFEL: 130, GRE: 140 },
//     { name: 27, CAT: 120, GMAT: 130, TOFEL: 140, GRE: 150 },
//   ];

//   const examColors = {
//     CAT: "#8884d8", // Blue
//     NMAT: "#24B670", // Green
//     XAT: "#FCB461", // Yellow
//     // GRE: "#E56C51", // Red
//   };

//   return (
//     <>
//       <LineChart
//         width={1300}
//         height={320}
//         data={d}
//         margin={{
//           top: 5,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis
//           label={{
//             value: "Users (in Lakhs)",
//             angle: -90,
//             position: "insideLeft",
//             dx: -5,
//             dy: 80,
//             style: { fontWeight: 600 },
//           }}
//         />
//         <Tooltip />
//         <Legend />
//         {selectedExams.map((exam) => (
//           <Line
//             key={exam}
//             type="monotone"
//             dataKey={exam}
//             stroke={examColors[exam]}
//             strokeWidth={4}
//           />
//         ))}
//       </LineChart>
//     </>
//   );
// };

// export default GrowthGraph;


import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const GrowthGraph = ({ selectedExams, growth }) => {
  const data = growth.map((val) => {

    const dateString = val.date;
    const date = new Date(dateString);

    const options = { day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return {
      name: formattedDate,
      ...selectedExams.reduce((acc, exam) => {
        acc[exam] = val[exam];
        return acc;
      }, {})
    }
  });

  const examColors = {
    CAT: "#8884d8", // Blue
    NMAT: "#24B670", // Green
    XAT: "#FCB461", // Yellow
    // GRE: "#E56C51", // Red
  };

  return (
    <LineChart
      width={1300}
      height={320}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis
        label={{
          value: "Users (in Lakhs)",
          angle: -90,
          position: "insideLeft",
          dx: -5,
          dy: 80,
          style: { fontWeight: 600 },
        }}
      />
      <Tooltip />
      <Legend />
      {selectedExams.map((exam) => (
        <Line
          key={exam}
          type="monotone"
          dataKey={exam}
          stroke={examColors[exam]}
          strokeWidth={4}
        />
      ))}
    </LineChart>
  );
};

export default GrowthGraph;
