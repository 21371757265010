import React from "react";
// import { BrowserRouter as Route, Routes } from "react-router-dom";
import DashboardPage from "./dashboardPage";

import Home from "./home";
import { Route, Routes } from "react-router-dom";

function DashboardMain() {
  return (
    
      <DashboardPage /> 

  )
}

export default DashboardMain;
