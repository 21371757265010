import React, { useState } from "react";
import SingleImageUpload from "../../common/singleImageUpload";
import { questionApi } from "../../../services/Constant";
import { Box, Card, CardContent, Typography, Button, Divider } from "@mui/material";
import { Empty, message } from "antd";

const UploadFile = () => {
    const [values, setValues] = useState({});
    console.log(values);

    // Function to copy the image URL to the clipboard
    const handleCopy = () => {
        if (values.image) {
            navigator.clipboard.writeText(values.image).then(() => {
                // alert("Image URL copied to clipboard!");
                message.success("Image URL copied to clipboard!");

            }).catch((err) => {
                // console.error("Failed to copy text: ", err);
                message.error("Failed to copy text: ", err);
            });
        }
    };




    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                backgroundColor: "#f9f9f9",
                padding: 2,
            }}
        >
            <Card
                sx={{
                    maxWidth: 600,
                    width: "100%",
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: 3,
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontWeight: "bold", mb: 2 }}>
                        Upload an Image
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                        Select an image to upload. Once uploaded, you can preview it below.
                    </Typography>

                    <Divider sx={{ mb: 3 }} />

                    <Box sx={{ mb: 3 }}>
                        <SingleImageUpload
                            endpoint={`${questionApi.endPoint}/upload`}
                            setData={(val) => setValues({ image: val.video_link })}
                            data={values.image}
                        />
                    </Box>

                    {values.image && (
                        <Box sx={{ mt: 3, textAlign: "center" }}>
                            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                                Uploaded Image Preview
                            </Typography>
                            <img
                                src={values.image}
                                alt="Uploaded"
                                style={{ maxWidth: "100%", height: "auto", borderRadius: "8px", marginBottom: "10px" }}
                            />
                            <Typography variant="body2" color="text.secondary">
                                <strong>Image URL:</strong>{" "}
                                <a
                                    href={values.image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none", color: "#1976d2" }}
                                >
                                    {values.image}
                                </a>
                            </Typography>
                            {/* Copy Button */}
                            <Button
                                onClick={handleCopy}
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                            >
                                Copy URL
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default UploadFile;
