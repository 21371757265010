

import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function MockGraph({ mock }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (mock && Array.isArray(mock)) {
      const transformedData = mock.map((c) => ({
        Courses: c.title,
        Sales: c.dailySales.reduce((acc, item) => acc + item.sales, 0), // Sum of sales for the mock
      }));

      setChartData(transformedData);
    }
  }, [mock]);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <ResponsiveContainer  height={300}>
        <BarChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 40, // Increased bottom margin for rotated labels
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Courses"
            interval={0}
            // angle={-45} // Rotate labels to avoid overlap
            // textAnchor="end"
            height={70} // Adjusted height for the rotated labels
            tick={{ fontSize: 13 }} // Smaller font size for labels
          />
          <YAxis />
          <Tooltip
            wrapperStyle={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "5px" }}
            contentStyle={{ fontSize: 12 }} // Smaller font size for tooltip content
          />
          <Legend />
          <Bar
            dataKey="Sales"
            fill="#ffb24d"
            barSize={20}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

