

// import React from "react";
// import { Card, CardContent, CardMedia, Typography } from "@mui/material";
// import {
//   CustomButton,
//   CustomButtonStyle,
// } from "../../../styles/muiRoot";
// import Stack from "@mui/material/Stack";
// import { PlusCircle } from "lucide-react";


// function Promotion() {
//   return (
//     <>
//       <Stack
//         direction={"row"}
//         alignItems={"center"}
//         justifyContent={"space-between"}
//         px={5}
//         py={1}
//       >
//         <Typography variant="body1">
//           <h1 className="ml-5 space-x-9 text-xl text-gray-700 font-bold">
//             Live Banner
//           </h1>
//         </Typography>

//         <div className="flex gap-2 items-center">
//           <CustomButton
//             style={{ ...CustomButtonStyle, marginRight: "35px" }}
//             startIcon={<PlusCircle />}
//           >
//             Create Banner
//           </CustomButton>
//         </div>
//       </Stack>

//       <div className="rounded-md px-8 py-4 flex flex-wrap justify-around gap-5 overflow-scroll max-h-[100vh]">
//         {[...Array(20)].map((item, ind) => (
//           <Card
//             sx={{
//               width: "30%", // 3 cards per row
//               height: "auto",
//               borderRadius: 2,
//               border: "1px solid #D8D8D8",
//               boxShadow: "none",
//               position: "relative", // For positioning the overlay
//               overflow: "hidden", // Ensure content stays within the card
//             }}
//             key={ind}
//           >
//             <CardMedia
//               component="img"
//               sx={{
//                 height: "13.625rem",
//                 width: "100%",
//                 borderRadius: 2,
//                 m: "auto",
//               }}
//               image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU"
//               alt="Banner"
//             />

//             {/* Normal Content */}
//             <CardContent
//               sx={{
//                 position: "absolute",
//                 top: 0,
//                 left: 0,
//                 right: 0,
//                 padding: "16px",
//                 color: "#fff",
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//               }}
//             >
//               <div>
//                 <h5 className="text-lg font-bold">
//                   Get access to premium content & ace your exams.
//                 </h5>
//                 <p className="text-sm mt-2">
//                   Join our online courses, gain access to our exclusive
//                   community, learning, and practice material.
//                 </p>
//               </div>
//               <div className="text-sm mt-2">
//                 Expires on <span className="font-bold">21 Aug, 21</span>
//               </div>
//             </CardContent>

//             {/* Discount Section with Black Shadow */}
//             <div
//               style={{
//                 position: "absolute",
//                 bottom: "16px", // Adjusts the position near the bottom
//                 left: "16px",
//                 background: "rgba(0, 0, 0, 0.8)", // Dark background for discount section
//                 padding: "8px 12px",
//                 borderRadius: "4px",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 width: "calc(100% - 32px)", // Adjusts to fit within the card's width
//               }}
//             >
//               <span className="text-lg font-bold text-white">50% Off</span>
//               <CustomButton
//                 style={{
//                   backgroundColor: "#fff",
//                   color: "#000",
//                   padding: "6px 12px",
//                   borderRadius: "4px",
//                 }}
//               >
//                 Buy Now
//               </CustomButton>
//             </div>
//           </Card>
//         ))}
//       </div>
//     </>
//   );
// }





// function Promotion() {
//   return (
//     <>
//       <Stack
//         direction={"row"}
//         alignItems={"center"}
//         justifyContent={"space-between"}
//         px={5}
//         py={1}
//         // className="mb-4" // Adds margin below the header to adjust spacing
//       >
//         <Typography
//           variant="body1"
//         >
//           <h1 className="ml-5 space-x-9 text-xl text-gray-700 font-bold ">Live Banner</h1>
//         </Typography>

//         <div className="flex gap-2 items-center">
//           <CustomButton
//             style={{ ...CustomButtonStyle, marginRight: "35px" }}
//             startIcon={<PlusCircle />}
//           >
//             Create Banner
//           </CustomButton>
//         </div>
//       </Stack>
//       <div className="rounded-md px-8 py-4 flex flex-wrap justify-around gap-5 overflow-scroll max-h-[100vh]">
//         {[...Array(20)].map((item, ind) => (
//           <Card
//             sx={{
//               width: "30%", // Adjusted to ensure 3 cards per row
//               height: "auto",
//               justifyContent: "center",
//               alignItems: "center",
//               p: 3,
//               borderRadius: 2,
//               pb: 0,
//               border: "1px solid #D8D8D8",
//               boxShadow: "none",
//             }}
//             key={ind}
//           >
//             <CardMedia
//               component="img"
//               sx={{
//                 height: "13.625rem",
//                 width: "100%", // Adjusted to ensure the image fits the card
//                 borderRadius: 2,
//                 m: "auto",
//               }}
//               image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU"
//               alt="Banner"
//             />
//             <CardContent>
//               <h5 className="text-secondary font-inter font-medium text-center">
//                 Module Name: <span className="font-bold">GRE</span> Full Course
//                 2021
//               </h5>
//             </CardContent>
//           </Card>
//         ))}
//       </div>
//     </>
//   );
// }


// export default Promotion;





import React, { useEffect, useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Select, MenuItem, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, PlusIcon } from 'lucide-react';
import { CustomButton } from '../../../styles/muiRoot';
import { useGetScheduleListQuery } from '../../../services/apis/schedule';
import { scheduleApi } from '../../../services/Constant';

const modules = [
  {
    name: "CAT Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU", // Replace with actual image URLs
  },
  {
    name: "GRE Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU",
  },
  {
    name: "XAT Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU",
  },
  {
    name: "SAT Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU",
  },
  {
    name: "NMAT Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU",
  },
  {
    name: "KMAT Full Course 2021",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU",
  },
];

export default function Promotion() {
  const [filter, setFilter] = useState('All');
  const navigate = useNavigate()

  const { data, isSuccess, isError, error, isLoading, refetch } = useGetScheduleListQuery(`${scheduleApi.endPoint}/list`, {
    refetchOnMountOrArgChange: true
  })




  useEffect(() => {
    if (isSuccess && data) {
      console.log('Fetched banner:', data);
    }
    if (error) {
      console.error('Fetch Error:', error);
    }
    refetch();
  }, [isSuccess, data, error]);


  // Conditionally render based on the loading state
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
  }

  // If there's no data but the request was successful, you might want to handle the case
  if (!isLoading && isSuccess && !data) {
    return <div>No data available</div>;
  }

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }



  let list = data?.data?.scheduledSpotList

  const handleScheduleClick = (id) => {
    // Navigate to the BannerDetail component with the selected banner ID
    console.log(id);
    
    navigate(`/main/sales/schedule/detail/${id}`);
  };


  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {/* Left Dropdown */}
        <Select
          value="current-spot"
          style={{ border: 'none', height: '40px' }}
          disableUnderline
        >
          <MenuItem value="current-spot">Current Spot</MenuItem>
        </Select>

        {/* Right Side Dropdown and Button */}
        <Box display="flex" alignItems="center">
          <Select
            value="All"
            style={{ height: '40px', marginRight: '10px' }}
          >
            <MenuItem value="All">Filter: All</MenuItem>
            <MenuItem value="Category1">Category 1</MenuItem>
            <MenuItem value="Category2">Category 2</MenuItem>
          </Select>
          <Button variant="contained" sx={{ height: '40px' }}
            onClick={() => setTimeout(() => {
              navigate('/main/sales/schedule/create')
            }, 500)}> <PlusCircle /> Schedule Spot</Button>

        </Box>
      </Box>
      <Grid container spacing={9}>
        {list.map((module, index) => {

          const date = new Date(module.startDate);

          const options = { day: '2-digit', month: 'short', year: '2-digit' };
          const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%' }} onClick={()=>handleScheduleClick(module._id)}>
                <CardMedia
                  component="img"
                  alt={module.exam}
                  height="140"
                  image={module.thumbnail}
                  
                  />
                <CardContent>
                  <Typography variant="body1" component="div">
                    Module Name: <strong>{module.exam}</strong>
                  </Typography>
                  <Typography variant="body1" component="div">
                    Goes Live: <strong>{formattedDate}</strong>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  );
}
