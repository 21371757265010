// import React from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   ComposedChart,
// } from "recharts";

// // const RevenueGraph = () => {
// //   const data = [
// //     {
// //       name: 3,
// //       uv: 80,

// //       amt: 0,
// //     },
// //     {
// //       name: 9,
// //       uv: 80,

// //       amt: 40,
// //     },
// //     {
// //       name: 12,
// //       uv: 90,

// //       amt: 80,
// //     },
// //     {
// //       name: 15,
// //       uv: 100,

// //       amt: 100,
// //     },
// //     {
// //       name: 18,
// //       uv: 100,

// //       amt: 120,
// //     },
// //     {
// //       name: 21,
// //       uv: 80,

// //       amt: 140,
// //     },
// //     {
// //       name: 24,
// //       uv: 115,

// //       amt: 160,
// //     },
// //     {
// //       name: 27,
// //       uv: 110,

// //       amt: 120,
// //     },
// //   ];
// //   return (
// //     <>
// //       <ResponsiveContainer>
// //         <LineChart
// //           width={500}
// //           height={600}
// //           data={data}
// //           margin={{
// //             top: 5,
// //             right: 30,
// //             left: 20,
// //             bottom: 5,
// //           }}
// //         >
// //           <CartesianGrid strokeDasharray="3 3" />
// //           <XAxis dataKey="name" />
// //           <YAxis />
// //           <Tooltip />
// //           <Legend />

// //           {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={4} /> */}
// //           <Line type="monotone" dataKey="uv" stroke="#82ca9d" strokeWidth={4} />
// //         </LineChart>
// //       </ResponsiveContainer>
// //     </>
// //   );
// // };

// const RevenueGraph = ({ showComparison, revenue }) => {



//   let result = revenue.result




//   let d = result.map((val) => {
//     const dateString = val.date;
//     const date = new Date(dateString);

//     const options = { day: 'numeric', month: 'short' };
//     const formattedDate = date.toLocaleDateString('en-GB', options);

//     // console.log(formattedDate); // Output: "7 Aug"
//     return {
//       name: formattedDate,
//       TotalRevenue: val.dailyTotalPaidAmount,
//       Bundle: val.bundle,
//       Courses: val.course

//     }
//   })







//   return (
//     <ResponsiveContainer width="100%" height={290}>
//       <ComposedChart
//         data={d}
//         margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis
//           label={{
//             value: "Rupees (in Lakhs)",
//             angle: -90,
//             position: "insideLeft",
//             dx: -5,
//             dy: 90,
//             style: { fontWeight: 600 },
//           }}
//         />
//         <Tooltip />
//         <Legend />
//         <Line
//           type="monotone"
//           dataKey="TotalRevenue"
//           stroke="#82ca9d"
//           strokeWidth={4}
//         />
//         {showComparison && (
//           <>

//             <Bar
//               dataKey="Bundle"
//               fill="#f9c13c"
//               radius={[10, 10, 0, 0]}
//               barSize={6}
//             />
//             <Bar
//               dataKey="Courses"
//               fill="#eb5757"
//               radius={[10, 10, 0, 0]}
//               barSize={6}
//             />
//           </>
//         )}
//       </ComposedChart>
//     </ResponsiveContainer>
//   );
// };

// export default RevenueGraph;



import React, { useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RevenueGraph = ({ showComparison, revenue }) => {


  // console.log(revenue.result);
  let result = revenue?.result


  let d = result.map((val) => {
    const dateString = val.date;
    const date = new Date(dateString);

    const options = { day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    // console.log(formattedDate); // Output: "7 Aug"
    return {
      name: formattedDate,
      TotalRevenue: val.dailyTotalPaidAmount,
      Bundle: val.bundle,
      Courses: val.course

    }
  })





  return (
    <ResponsiveContainer width="100%" height={290}>
      <ComposedChart
        data={d}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "Rupees (in Lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -5,
            dy: 90,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="TotalRevenue"
          stroke="#82ca9d"
          strokeWidth={4}
        />
        {showComparison && (
          <>
            
            <Bar
              dataKey="Bundle"
              fill="#f9c13c"
              radius={[10, 10, 0, 0]}
              barSize={6}
            />
            <Bar
              dataKey="Courses"
              fill="#eb5757"
              radius={[10, 10, 0, 0]}
              barSize={6}
            />
          </>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default RevenueGraph;
