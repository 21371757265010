// import React from "react";
// import FullWidthTabs from "../../common/tabChanger";
// import Header from "../../common/header";
// import Promotion from "./promotion";
// import AnalyticMain from "./sales_Analytic/analyticMain";

// import NotificationMain from "./notification/notificationMain";
// import BannerMain from "./banner/bannerMain";
// function HomePage() {
//   let baseApi = "/main/sales"

//   return (
//     <div className=" overflow-hidden">
//       <Header content={"Sales"} />
//       <div className="bg-mdGrey h-[93%] p-3">
//         <div className="bg-white  ">
//           <FullWidthTabs
//             data={[
//               {
//                 item: 1,
//                 label: "Sales Analytics ",
//                 content: <AnalyticMain />,
//                 // path: `${baseApi}/analystic`
//               },
//               {
//                 item: 2,
//                 label: "Help Request  (08)",
//                 content: <Promotion />,
//                 // path: `${baseApi}/help-request`
//               },
//               {
//                 item: 3,
//                 label: "Promotion",
//                 content: <Promotion />,
//                 // path:  `${baseApi}/promotion`
//               },
//               {
//                 item: 4,
//                 label: "HomePage Banner ",
//                 content: <BannerMain />,
//                 // path:  `${baseApi}/banner`

//               },
//               {
//                 item: 5,
//                 label: "Push Notification",
//                 content: <NotificationMain />,

//               },
//             ]}

//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HomePage;


// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import FullWidthTabs from "../../common/tabChanger";
// import AnalyticMain from "./sales_Analytic/analyticMain";
// import Promotion from "./promotion";
// import NotificationMain from "./notification/notificationMain";
// import BannerMain from "./banner/bannerMain";

// function HomePage() {
//   const [activeTab, setActiveTab] = useState(1);
//   const navigate = useNavigate();

//   useEffect(() => {
//     let basePath = "/main/sales";
//     let fullPath = basePath;

//     switch (activeTab) {
//       case 2:
//         fullPath = `${basePath}/help-request`;
//         break;
//       case 3:
//         fullPath = `${basePath}/promotion`;
//         break;
//       case 4:
//         fullPath = `${basePath}/banner`;
//         break;
//       case 5:
//         fullPath = `${basePath}/push-notification`;
//         break;
//       default:
//         fullPath = basePath;
//     }

//     navigate(fullPath);

//     // Example API call with dynamic path
//     const fetchData = async () => {
//       const response = await fetch(`/api${fullPath}`);
//       // handle the response
//     };

//     fetchData();

//   }, [activeTab, navigate]);

//   return (
//     <div className="overflow-hidden">
//       <div className="bg-mdGrey h-[93%] p-3">
//         <div className="bg-white">
//           <FullWidthTabs
//             data={[
//               {
//                 item: 1,
//                 label: "Sales Analytics",
//                 content: <AnalyticMain />,
//                 path: "/main/sales"
//               },
//               {
//                 item: 2,
//                 label: "Help Request (08)",
//                 content: <Promotion />,
//                 path: "/main/sales/help-request"
//               },
//               {
//                 item: 3,
//                 label: "Promotion",
//                 content: <Promotion />,
//                 path: "/main/sales/promotion"
//               },
//               {
//                 item: 4,
//                 label: "HomePage Banner",
//                 content: <BannerMain />,
//                 path: "/main/sales/banner"
//               },
//               {
//                 item: 5,
//                 label: "Push Notification",
//                 content: <NotificationMain />,
//                 path: "/main/sales/push-notification"
//               }
//             ]}
//             value={activeTab}
//             onTabChange={setActiveTab}
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HomePage;






import React from "react";
import FullWidthTabs from "../../common/tabChanger";
import Promotion from "./promotion";
import AnalyticMain from "./sales_Analytic/analyticMain";

import NotificationMain from "./notification/notificationMain";
import BannerMain from "./banner/bannerMain";
import HelpRequest from "./help-request/helpRequest";
import Header from "../../common/header";

function HomePage() {
  let baseApi = "/main/sales"

  return (
    <>
      <div className=" overflow-hidden">
        <Header content={"Sales"} />
        <div className="bg-mdGrey h-[93%] p-3">
          <div className="bg-white  ">
            <FullWidthTabs
              data={[
                {
                  item: 1,
                  label: "Sales Analytics ",
                  content: <AnalyticMain />,
                //   path: `${baseApi}/analystic`
                },
                {
                  item: 2,
                  label: "Help Request  (08)",
                  content: <HelpRequest />,
                //   path: `${baseApi}/help-request`
                },
                {
                  item: 3,
                  label: "Promotion",
                  content: <Promotion />,
                //   path: `${baseApi}/promotion`
                },
                {
                  item: 4,
                  label: "HomePage Banner ",
                  content: <BannerMain />,
                //   path: `${baseApi}/banner`

                },
                {
                  item: 5,
                  label: "Push Notification",
                  content: <NotificationMain />,
                //   path: `${baseApi}/push-notification`


                },
              ]}

            />

        

          </div>
        </div>
      </div>



    </>
  );
}

export default HomePage;

