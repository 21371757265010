import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const UageGraph = ({ graphData }) => {
  console.log(graphData);

  // Convert the graphData to the format required by the LineChart
  const data = graphData.map((item) => ({
    name: new Date(item.date).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }),
    male: item.male,
    female: item.female,
    other: item.others, // Ensure the key matches 'other'
  }));

  return (
    <>
      <LineChart
        width={1100}
        height={350}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "Users (In lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -4,
            dy: 70,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="male" stroke="#82ca9d" strokeWidth={4} />
        <Line
          type="monotone"
          dataKey="female"
          stroke="#8884d8"
          strokeWidth={4}
        />
        <Line
          type="monotone"
          dataKey="other"
          stroke="#f7c757"
          strokeWidth={4}
        />
      </LineChart>
    </>
  );
};

export default UageGraph;
