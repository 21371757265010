import React, { useEffect, useState } from "react";
import Container from "./container";
import {  salesAnalysticApi } from "../../../../services/Constant";
import { useGetDashboardAnalyticsQuery } from "../../../../services/apis/dashboardApi";
import { useGetSalesAnalysticListQuery } from "../../../../services/apis/salesAnalystic";


const AnalyticMain = () => {

  const [filterType, setFilterType] = useState(30);

//   // Fetch the data from the API
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetSalesAnalysticListQuery(`${salesAnalysticApi.endPoint}/all/list?days=${filterType}`,{
    refetchOnMountOrArgChange: true,
});



  console.log(data);




  useEffect(() => {
    if (isSuccess && data) {
        console.log('Fetched banner:', data);
    }
    if (error) {
        console.error('Fetch Error:', error);
    }
    refetch();
}, [isSuccess, data, error]);


  // Conditionally render based on the loading state
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
  }

  // If there's no data but the request was successful, you might want to handle the case
  if (!isLoading && isSuccess && !data) {
    return <div>No data available</div>;
  }

  if (isError) {
    return <div>Error: {error?.message}</div>;
}



  return (
    <>
      <div className=" overflow-hidden">

      </div>
      <Container data={data} />
    </>
  );
};

export default AnalyticMain;
