import { Stack, Typography, TextField, Input, Button } from "@mui/material";
import React, { useState } from "react";
import { Box, MoreVertical } from "lucide-react";
import { Square } from "lucide-react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const AgeNavbar = ({data}) => {
  // const [age, setAge] = React.useState("");
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };





  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [error, setError] = useState("");
  const [ageRange, setAgeRange] = useState("");


  const handleMinAgeChange = (e) => {
    setMinAge(e.target.value);
  };

  const handleMaxAgeChange = (e) => {
    setMaxAge(e.target.value);
  };

  const handleSubmit = (e) => {

    setAgeRange(e.target.value);

    if (minAge === "" || maxAge === "") {
      setError("Please enter both minimum and maximum age.");
      return;
    }
    if (parseInt(minAge) > parseInt(maxAge)) {
      setError("Minimum age cannot be greater than maximum age.");
      return;
    }
    if (parseInt(minAge) < 0 || parseInt(maxAge) < 0) {
      setError("Age cannot be negative.");
      return;
    }
    // Clear error and apply filter logic
    setError("");
    console.log(`Filtering ages between ${minAge} and ${maxAge}`);
    // Apply your age filter logic here
  };


  // const handleAgeRangeChange = (e) => {
  //   setAgeRange(e.target.value);
  // };





  return (
    <>
      <div className="flex justify-between items-center">
        <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
          Users By Age and Gender
        </Typography>
        <Stack
          direction={"row"}
          spacing={3}
          justifyContent={"center"}
          alignItems={"center"}
        >




          <Typography variant="subtitle1">Set age range :</Typography>
          <TextField
            id="outlined-basic"
            sx={{ width: "130px", height: "37px" }}
            label="Min Age"
            size="small"
            value={minAge}
            onChange={handleMinAgeChange}
            variant="outlined"
          />
          <Typography variant="subtitle2">to </Typography>
          <TextField
            id="outlined-basic"
            sx={{ width: "130px", height: "37px" }}
            label="Max Age"
            value={maxAge}
            onChange={handleMaxAgeChange}
            variant="outlined"
            size="small"
          />
          {/* <Button onClick={handleSubmit} variant="contained" sx={{ marginLeft: "10px" }}>
              Apply
            </Button> */}

          {error && (
            <Typography color="error" variant="body2" sx={{ marginTop: "10px" }}>
              {error}
            </Typography>
          )}





          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ageRange} // Changed from "age" to "ageRange"
              onChange={handleSubmit} // Updated change handler
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Past one Month</em>; // Default display
                }
                return selected;
              }}
              sx={{ width: "170px", height: "37px" }}
            >


              <MenuItem hidden value="">
                <em>Past one Month</em>
              </MenuItem>
              <MenuItem value={"10 Days"}>Ten Days</MenuItem>
              <MenuItem value={"20 Days"}>Twenty Days</MenuItem>
              <MenuItem value={"30 Days"}>Thirty Days</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl> */}
          <MoreVertical />
        </Stack>
      </div>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ marginTop: "10px", marginRight: "10px" }}
      >
        <Square fill="#24B670" strokeWidth={0} />
        <Typography variant="body1">Male</Typography>
        <Square fill="#5146D6" strokeWidth={0} />
        <Typography variant="body1">Female</Typography>
        <Square fill="#FCB461" strokeWidth={0} />
        <Typography variant="body1">Others</Typography>
      </Stack>
    </>
  );
};

export default AgeNavbar;







// import { Stack, Typography, TextField, Button } from "@mui/material";
// import React, { useState } from "react";
// import { Box, MoreVertical, Square } from "lucide-react";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// const AgeNavbar = ({ data, setFilteredData }) => {
//   const [minAge, setMinAge] = useState("");
//   const [maxAge, setMaxAge] = useState("");
//   const [error, setError] = useState("");
//   const [ageRange, setAgeRange] = useState(""); // For days filtering (10, 20, 30 days)

//   const handleMinAgeChange = (e) => {
//     setMinAge(e.target.value);
//   };

//   const handleMaxAgeChange = (e) => {
//     setMaxAge(e.target.value);
//   };

//   const filterData = () => {
//     let filtered = [...data];

//     if (minAge !== "" && maxAge !== "") {
//       if (parseInt(minAge) > parseInt(maxAge)) {
//         setError("Minimum age cannot be greater than maximum age.");
//         return;
//       }
//       if (parseInt(minAge) < 0 || parseInt(maxAge) < 0) {
//         setError("Age cannot be negative.");
//         return;
//       }

//       // Filter data by age
//       filtered = data.filter(
//         (item) => item.age >= parseInt(minAge) && item.age <= parseInt(maxAge)
//       );
//     } else {
//       setError(""); // Clear any previous errors
//     }

//     setFilteredData(filtered);
//   };

//   const handleSubmit = () => {
//     filterData();
//   };

//   return (
//     <>
//       <div className="flex justify-between items-center">
//         <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
//           Users By Age and Gender
//         </Typography>
//         <Stack
//           direction={"row"}
//           spacing={3}
//           justifyContent={"center"}
//           alignItems={"center"}
//         >
//           <Typography variant="subtitle1">Set age range :</Typography>
//           <TextField
//             id="outlined-basic"
//             sx={{ width: "130px", height: "37px" }}
//             label="Min Age"
//             size="small"
//             value={minAge}
//             onChange={handleMinAgeChange}
//             variant="outlined"
//           />
//           <Typography variant="subtitle2">to </Typography>
//           <TextField
//             id="outlined-basic"
//             sx={{ width: "130px", height: "37px" }}
//             label="Max Age"
//             size="small"
//             value={maxAge}
//             onChange={handleMaxAgeChange}
//             variant="outlined"
//           />
//           <Button
//             onClick={handleSubmit}
//             variant="contained"
//             sx={{ marginLeft: "10px" }}
//           >
//             Apply
//           </Button>

//           {error && (
//             <Typography color="error" variant="body2" sx={{ marginTop: "10px" }}>
//               {error}
//             </Typography>
//           )}

//           <FormControl>
//             <Select
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               value={ageRange} // Changed from "age" to "ageRange"
//               onChange={(e) => setAgeRange(e.target.value)} // Keep the age range selection separate
//               displayEmpty
//               renderValue={(selected) => {
//                 if (!selected) {
//                   return <em>Past one Month</em>; // Default display
//                 }
//                 return selected;
//               }}
//               sx={{ width: "170px", height: "37px" }}
//             >
//               <MenuItem hidden value="">
//                 <em>Past one Month</em>
//               </MenuItem>
//               <MenuItem value={"10 Days"}>Ten Days</MenuItem>
//               <MenuItem value={"20 Days"}>Twenty Days</MenuItem>
//               <MenuItem value={"30 Days"}>Thirty Days</MenuItem>
//             </Select>
//           </FormControl>
//           <MoreVertical />
//         </Stack>
//       </div>
//       <Stack
//         spacing={2}
//         direction={"row"}
//         justifyContent={"flex-end"}
//         sx={{ marginTop: "10px", marginRight: "10px" }}
//       >
//         <Square fill="#24B670" strokeWidth={0} />
//         <Typography variant="body1">Male</Typography>
//         <Square fill="#5146D6" strokeWidth={0} />
//         <Typography variant="body1">Female</Typography>
//         <Square fill="#FCB461" strokeWidth={0} />
//         <Typography variant="body1">Others</Typography>
//       </Stack>
//     </>
//   );
// };




// export default AgeNavbar;











// Alternate Code

// import React, { useState } from "react";
// import { Stack, Typography, TextField, FormControl, MenuItem, Select } from "@mui/material";
// import { MoreVertical, Square } from "lucide-react";

// const AgeNavbar = ({ data, setFilteredData }) => {
//   const [minAge, setMinAge] = useState("");
//   const [maxAge, setMaxAge] = useState("");
//   const [error, setError] = useState("");
//   const [ageRange, setAgeRange] = useState(""); // For days filtering (10, 20, 30 days)

//   const handleMinAgeChange = (e) => {
//     setMinAge(e.target.value);
//   };

//   const handleMaxAgeChange = (e) => {
//     setMaxAge(e.target.value);
//   };

//   const handleDaysChange = (e) => {
//     setAgeRange(e.target.value);
//     filterData(e.target.value, minAge, maxAge);
//   };

//   const filterData = (days, minAge, maxAge) => {
//     if (!data || !data.length) {
//       console.error('No data available to filter.');
//       return;
//     }

//     let now = new Date();
//     let daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

//     // Filter by date range first
//     let filtered = data.filter((item) => {
//       let itemDate = new Date(item.date);
//       return itemDate >= daysAgo;
//     });

//     // Optionally filter by age if provided
//     if (minAge && maxAge) {
//       filtered = filtered.filter((item) => {
//         return item.age >= minAge && item.age <= maxAge;
//       });
//     }

//     setFilteredData(filtered);
//   };

//   return (
//     <>
//       <div className="flex justify-between items-center">
//         <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
//           Users By Age and Gender
//         </Typography>
//         <Stack direction={"row"} spacing={3} justifyContent={"center"} alignItems={"center"}>
//           <Typography variant="subtitle1">Set age range :</Typography>
//           <TextField
//             sx={{ width: "130px", height: "37px" }}
//             label="Min Age"
//             size="small"
//             value={minAge}
//             onChange={handleMinAgeChange}
//             variant="outlined"
//           />
//           <Typography variant="subtitle2">to</Typography>
//           <TextField
//             sx={{ width: "130px", height: "37px" }}
//             label="Max Age"
//             size="small"
//             value={maxAge}
//             onChange={handleMaxAgeChange}
//             variant="outlined"
//           />

//           <FormControl>
//             <Select
//               id="days-select"
//               value={ageRange}
//               onChange={handleDaysChange}
//               displayEmpty
//               renderValue={(selected) => !selected ? <em>Past one Month</em> : selected}
//               sx={{ width: "170px", height: "37px" }}
//             >
//               <MenuItem hidden value=""><em>Past one Month</em></MenuItem>
//               <MenuItem value={10}>10 Days</MenuItem>
//               <MenuItem value={20}>20 Days</MenuItem>
//               <MenuItem value={30}>30 Days</MenuItem>
//             </Select>
//           </FormControl>

//           <MoreVertical />
//         </Stack>
//       </div>

//       <Stack spacing={2} direction={"row"} justifyContent={"flex-end"} sx={{ marginTop: "10px", marginRight: "10px" }}>
//         <Square fill="#24B670" strokeWidth={0} />
//         <Typography variant="body1">Male</Typography>
//         <Square fill="#5146D6" strokeWidth={0} />
//         <Typography variant="body1">Female</Typography>
//         <Square fill="#FCB461" strokeWidth={0} />
//         <Typography variant="body1">Others</Typography>
//       </Stack>
//     </>
//   );
// };

// export default AgeNavbar;




