import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./home";
import CreateMain from "./create/createMain";
import Preview from "./create/preview/preview";
import PastPaperDetail from "./detail/pastPaperDetail";
import PackageDetail from "./package/createPackage/mockDetail";
import PackageCreate from "./package/packageCreate";
import ViewPackage from "./package/viewPackage.js/page";
import EditPackage from "./package/createPackage/editPackage";
import CreatePackage from "./package/createPackage/createPackage";


function PastPapersMain() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create" element={<CreateMain />} />
        <Route path="/edit/:paperId" element={<CreateMain />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/preview/:paperId" element={<Preview />} />
        <Route path="/:paperId" element={<PastPaperDetail />} />

        {/* package route */}
        {/* <Route path="package/create" element={<PackageCreate />} /> */}
        {/* <Route path="package/:packageId" element={<PackageDetail />} /> */}
        <Route path="/package/create" element={<CreatePackage />} />

        <Route path="/package/edit/:packageId" element={<EditPackage />} />

        <Route path="/package/view/:packageId" element={<ViewPackage />} />

      </Routes>
    </>

  );
}

export default PastPapersMain;
