
import React, { useEffect, useState } from 'react';
import { TextField, Grid, Button, Select, MenuItem, FormControl, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setBanner } from '../../../../ducks/bannerSlice';
import { useCreateHomeBannerMutation, useGetHomeBannerByIdQuery, useGetHomeBannersListQuery, useUpdateHomeBannerMutation, useUploadMediaMutation } from '../../../../services/apis/bannerApi';
import { blogApi, courseApi, eventApi, groupApi, homeBannerApi, mockPackageApi, rewardsApi } from '../../../../services/Constant';
import { useGetCoursesQuery } from '../../../../services/apis/exam/courses';
import { useGetGroupListQuery } from '../../../../services/apis/exam/group';
import { useGetMockPackagesQuery } from '../../../../services/apis/exam/mock';
import { useGetEventListQuery } from '../../../../services/apis/eventApi';
import { useGetBlogListQuery } from '../../../../services/apis/blogApi';
import { useGetEntityTypeListQuery } from "../../../../services/apis/dataManagement/entityType";
import { useGetBundleListQuery, useGetRewardListQuery } from '../../../../services/apis/rewardsApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import Header from '../../../common/header';
import { entityTypeApi, subjectApi } from "../../../../services/Constant";

function CreateBanner({ showBanner }) {

    let { id } = useParams()
    // console.log(id);


    let [createBanner] = useCreateHomeBannerMutation();

    let [updateBanner] = useUpdateHomeBannerMutation()

    const [file, setFile] = useState(null);




    const {
        isSuccess: isCoursesSuccess,
        isLoading: isCoursesLoading,
        isError: isCoursesError,
        data: coursesData,
        error: coursesError
    } = useGetCoursesQuery(`${courseApi.endPoint}/basic`);

    const {
        isSuccess: isGroupSuccess,
        isLoading: isGroupLoading,
        isError: isGroupError,
        data: groupData,
        error: groupError
    } = useGetGroupListQuery(`${groupApi.endPoint}`);

    const {
        isSuccess: isMockPackageSuccess,
        isLoading: isMockPackageLoading,
        isError: isMockPackageError,
        data: mockPackageData,
        error: mockPackageError
    } = useGetMockPackagesQuery(`${mockPackageApi.endPoint}`);

    const {
        isSuccess: isEventSuccess,
        isLoading: isEventLoading,
        isError: isEventError,
        data: eventData,
        error: eventError
    } = useGetEventListQuery(`${eventApi.endPoint}/list`);


    const {
        isSuccess: isBlogSuccess,
        isLoading: isBlogLoading,
        isError: isBlogError,
        data: blogData,
        error: blogError
    } = useGetBlogListQuery(`${blogApi.endPoint}/list`);

    const {
        isSuccess: isBundleSuccess,
        isLoading: isBundleLoading,
        isError: isBundleError,
        data: bundleData,
        error: bundleError
    } = useGetBundleListQuery(`${rewardsApi.bundleEndPoint}/list`);


    const {
        isSuccess: isRewardSuccess,
        isLoading: isRewardLoading,
        isError: isRewardError,
        data: rewardData,
        error: rewardError
    } = useGetRewardListQuery(`${rewardsApi.rewardsEndPoint}/list`);
    const {
        isSuccess: isExamSuccess,
        isLoading: isExamLoading,
        isError: isExamError,
        data: examData,
        error: examError
    } = useGetEntityTypeListQuery(`${entityTypeApi.endPoint}`);

    let { isSuccess, isError, error, data, isLoading, } = useGetHomeBannerByIdQuery(`${homeBannerApi.endPoint}/details/${id}`)
    const [uploadMedia, { isLoading: loading, error: err, data: img }] = useUploadMediaMutation();



    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [bannerData, setBannerData] = useState({
        title: '',
        description: '',
        offer: '',
        buttonType: 'buy',
        startDate: '',
        endDate: '',
        sourceType: '',
        sourceId: '',
        thumbnail: ''
    });

    let { title, description, offer, buttonType, startDate, endDate, sourceType, sourceId, thumbnail } = bannerData

    let datas = data?.data?.homeBanner






    const handleApiResponse = (isSuccess, data, isError, error, apiName) => {
        if (isSuccess && data) {
            console.log(`Fetched ${apiName} Data:`, data);
        }
        if (isError) {
            console.error(`${apiName} Fetch Error:`, error);
        }
    };

    // useEffect(() => {
    //     // Handle Courses API data
    //     if (isCoursesSuccess && coursesData) {
    //         console.log('Fetched Courses Data:', coursesData);
    //     }
    //     if (isCoursesError) {
    //         console.error('Courses Fetch Error:', coursesError);
    //     }

    //     // Handle Group API data
    //     if (isGroupSuccess && groupData) {
    //         console.log('Fetched Group Data:', groupData);
    //     }
    //     if (isGroupError) {
    //         console.error('Group Fetch Error:', groupError);
    //     }

    //     // Handle mockPackage API data
    //     if (isMockPackageSuccess && mockPackageData) {
    //         console.log('Fetched MockPackage Data:', mockPackageData);
    //     }
    //     if (isMockPackageError) {
    //         console.error('MockPackage Fetch Error:', mockPackageError);
    //     }

    //     // Handle Event API data
    //     if (isEventSuccess && eventData) {
    //         console.log('Fetched Event Data:', eventData);
    //     }
    //     if (isEventError) {
    //         console.error('Event Fetch Error:', eventError);
    //     }

    //     // Handle Blog API data
    //     if (isBlogSuccess && blogData) {
    //         console.log('Fetched Blog Data:', blogData);
    //     }
    //     if (isBlogError) {
    //         console.error('Blog Fetch Error:', blogError);
    //     }

    //     // Handle reward API data
    //     if (isRewardSuccess && rewardData) {
    //         console.log('Fetched Reward Data:', rewardData);
    //     }
    //     if (isRewardError) {
    //         console.error('Blog Fetch Error:', rewardError);
    //     }

    // }, [
    //     isCoursesSuccess,
    //     coursesData,
    //     isCoursesError,
    //     coursesError,
    //     isGroupSuccess,
    //     isGroupError,
    //     groupData,
    //     groupError,
    //     isMockPackageSuccess,
    //     mockPackageData,
    //     isMockPackageError,
    //     mockPackageError,
    //     isEventSuccess,
    //     eventData,
    //     isEventError,
    //     eventError,
    //     isBlogSuccess,
    //     blogData,
    //     isBlogError,
    //     blogError,
    //     isRewardSuccess,
    //     rewardData,
    //     isRewardError,
    //     rewardError


    // ]);



    useEffect(() => {
        handleApiResponse(isCoursesSuccess, coursesData, isCoursesError, coursesError, 'Courses');
        handleApiResponse(isGroupSuccess, groupData, isGroupError, groupError, 'Group');
        handleApiResponse(isMockPackageSuccess, mockPackageData, isMockPackageError, mockPackageError, 'MockPackage');
        handleApiResponse(isEventSuccess, eventData, isEventError, eventError, 'Event');
        handleApiResponse(isBlogSuccess, blogData, isBlogError, blogError, 'Blog');
        handleApiResponse(isRewardSuccess, rewardData, isRewardError, rewardError, 'Reward');
        handleApiResponse(isBundleSuccess, bundleData, isBundleError, bundleError, 'Bundle');
        handleApiResponse(isExamSuccess, examData, isExamError, examError, 'exam');

        if (id) {
            let banerrr = datas?._id === id;


            if (id && datas?._id === id) {
                setBannerData({
                    title: datas.title,
                    description: datas.description,
                    offer: datas.offer,
                    buttonType: datas.buttonType,
                    startDate: datas.startDate,
                    endDate: datas.endDate,
                    sourceType: datas.sourceType,
                    sourceId: datas.sourceId,
                    thumbnail: datas.thumbnail,
                })

            } else {
                title = '';
                description = '';
                offer = '';
                buttonType = 'buy';
                startDate = '';
                endDate = '';
                sourceType = '';
                sourceId = '';
                thumbnail = '';
            }
        } else {
            title = '';
            description = '';
            offer = '';
            buttonType = 'buy';
            startDate = '';
            endDate = '';
            sourceType = '';
            sourceId = '';
            thumbnail = '';
        }



    }, [
        isCoursesSuccess, coursesData, isCoursesError, coursesError,
        isGroupSuccess, groupData, isGroupError, groupError,
        isMockPackageSuccess, mockPackageData, isMockPackageError, mockPackageError,
        isEventSuccess, eventData, isEventError, eventError,
        isBlogSuccess, blogData, isBlogError, blogError,
        isRewardSuccess, rewardData, isRewardError, rewardError,
        isBundleSuccess, bundleData, isBundleError, bundleError,
        isExamSuccess, examData, isExamError, examError,
        id
    ]);

    let d = coursesData?.data?.courseList;
    // console.log(d);

    let group = groupData?.data;
    // console.log(group);

    let mock = mockPackageData?.data;
    // console.log(mock);

    let event = eventData?.data;
    // console.log(event);

    let blog = blogData?.data?.blogs
    // console.log(blog);

    let reward = rewardData?.data?.rewards
    // console.log(reward);

    let bundle = bundleData?.data?.bundleList;
    // console.log(bundle);
    let exam = examData?.data;
    console.log(exam);





    // Handle loading state
    if (isCoursesLoading || isGroupLoading || isMockPackageLoading || isEventLoading || isBlogLoading || isBundleLoading || isRewardLoading || isExamLoading) {
        return <div>Loading...</div>;
    }

    // Handle error state
    if (isCoursesError || isGroupError || isMockPackageError || isEventError || isBlogError || isBundleError || isRewardError || isExamError) {
        return <div>Error: {coursesError?.message || groupError?.message || mockPackageError?.message || eventError?.message || blogError?.message || bundleError?.message || rewardError?.message || examError?.message}</div>;
    }



    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name === 'startDate' || name === 'endDate') {
            setBannerData({
                ...bannerData,
                [name]: new Date(value).toISOString()
            });
        } else if (name === 'sourceType') {
            setBannerData({
                ...bannerData,
                [name]: value,
                sourceId: '' // Reset sourceId when sourceType changes
            });
        } else {

            setBannerData({
                ...bannerData,
                [name]: value,

            });
        }

    };



    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // If sourceId is being changed, directly update it
    //     if (name === 'sourceId') {
    //         setBannerData({ ...bannerData, [name]: value });
    //     } else if (name === 'sourceType') {
    //         // When sourceType changes, reset the sourceId
    //         setBannerData({ ...bannerData, sourceType: value, sourceId: '' });
    //     } else if (name === 'startDate' || name === 'endDate') {
    //         // Convert dates to ISO format
    //         setBannerData({ ...bannerData, [name]: new Date(value).toISOString() });
    //     } else {
    //         setBannerData({ ...bannerData, [name]: value });
    //     }
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(bannerData);



        try {
            if (id) {
                await updateBanner({
                    bannerData, id
                }).unwrap()

                setTimeout(() => {
                    navigate('/main/sales')

                }, 1000);

            } else {
                await createBanner({
                    bannerData
                }).unwrap();

                // Dispatch the newFeed to your Redux store
                dispatch(setBanner(bannerData));

                showBanner(false)
            }



        } catch (err) {
            console.error("Failed to create banner:", err);
        }
    };



    const handleImageChange = async (e) => {
        const file = e.target.files[0];

        // Update the bannerData state with the selected file (optional if needed)
        setBannerData((prev) => ({
            ...prev,
            image: file,
        }));

        // Create FormData and append the file under the 'file' key
        const formData = new FormData();
        formData.append('image', file);  // Key should match what the API expects

        try {
            // Upload the file using the uploadMedia mutation
            const response = await uploadMedia(formData).unwrap();

            // Assuming `response.url` contains the image URL returned by the API
            const imageURL = response.data;

            console.log(imageURL);

            // Update the bannerData state with the new image URL
            setBannerData((prev) => ({
                ...prev,
                media: imageURL,
            }));
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };








    return (
        <>
            {/* <Header content={"Sales"} /> */}

            <Box
                component="form"
                onSubmit={handleSubmit}
            >
                <Grid container spacing={3} style={{ padding: '20px' }}>

                    {/* Form Section on the right side */}
                    <Grid item xs={12} md={8}>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
                                    Banner Title
                                </Typography>
                                <TextField
                                    placeholder="Enter Title here"
                                    name="title"
                                    value={title}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
                                    Banner Description
                                </Typography>
                                <TextField
                                    placeholder="Enter description here"
                                    name="description"
                                    value={description}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{ mb: 2, width: 'calc(100%)', '& textarea': { minHeight: '60px' } }}
                                />
                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={8}>
                                    <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}>
                                        Offer Details
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="Enter offer details here"
                                        name="offer"
                                        value={offer}
                                        onChange={handleChange}
                                        sx={{ mb: 0, width: 'calc(100% - 20px)', ml: '20px' }} // Width aur left margin adjust ki gayi hai
                                        style={{ padding: 0 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth variant="outlined">
                                        <Select
                                            name="buttonType"
                                            value={buttonType}
                                            onChange={handleChange}
                                            style={{ height: '55px', marginTop: "30px" }}
                                        >
                                            <MenuItem value="buy">Buy now</MenuItem>
                                            <MenuItem value="visitGroup">Visit Group</MenuItem>
                                            <MenuItem value="watch">Watch</MenuItem>
                                            <MenuItem value="learnMore">Learn More</MenuItem>
                                            <MenuItem value="read">Read</MenuItem>
                                            <MenuItem value="join">Join</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="From"
                                    type="date"
                                    name="startDate"
                                    value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="To"
                                    type="date"
                                    name="endDate"
                                    value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}>
                                    Redirect To
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={sourceType}
                                        onChange={handleChange}
                                        name="sourceType"
                                    >
                                        <MenuItem value="course">Course </MenuItem>
                                        <MenuItem value="event">Event</MenuItem>
                                        <MenuItem value="photo">Photo</MenuItem>
                                        <MenuItem value="video">Video</MenuItem>
                                        <MenuItem value="mockPackage">Mock-Package</MenuItem>
                                        <MenuItem value="bundle">Bundle</MenuItem>
                                        <MenuItem value="post">Post</MenuItem>
                                        <MenuItem value="profile">Profile</MenuItem>
                                        <MenuItem value="groups">Groups</MenuItem>
                                        <MenuItem value="blog">Blog</MenuItem>
                                        <MenuItem value="rewards">Rewards</MenuItem>
                                        <MenuItem value="exam">Exam</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: "30px" }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={sourceId}
                                        onChange={handleChange}
                                        name="sourceId"
                                    >
                                        {sourceType == 'course' && d?.map((course, index) => (
                                            <MenuItem key={index} value={course._id}>
                                                {course.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'groups' && group?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'mockPackage' && mock?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'event' && event?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'blog' && blog?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'bundle' && bundle?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'rewards' && reward?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}
                                        {sourceType == 'exam' && exam?.map((value, index) => (
                                            <MenuItem key={index} value={value._id}>
                                                {value.title}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}
                                >
                                    Background Image
                                </Typography>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    style={{
                                        height: "250px",
                                        width: "350px",
                                        backgroundColor: "whiteSmoke",
                                        backgroundImage: thumbnail ? `url(${thumbnail})` : 'none',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    + Add Photo/Video
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleImageChange}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Preview Section on the left side */}
                    <Grid item xs={12} md={4}>
                        <Box border={1} borderColor="grey.300" padding={2} height="60%">
                            <Typography variant="h6">Preview</Typography>
                            <p><strong>Title:</strong> {title}</p>
                            <p><strong>Description:</strong> {description}</p>
                            <p><strong>Offer Details:</strong> {offer}</p>
                            <p><strong>From:</strong> {startDate ? new Date(startDate).toLocaleDateString() : ''}</p>
                            <p><strong>To:</strong> {endDate ? new Date(endDate).toLocaleDateString() : ''}</p>
                            <p><strong>Redirect To:</strong> {sourceType}</p>
                            <p>
                                <strong>Background Image:</strong>
                                {thumbnail && (
                                    <img
                                        src={thumbnail}
                                        alt="Preview"
                                        style={{ width: '100px', height: "100px", marginTop: '10px' }}
                                    />
                                )}
                            </p>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, }} >
                            <Button type="submit" variant="contained" color="primary" >
                                Send
                            </Button>
                            {
                                id ? <Button variant="contained" color="primary" style={{ marginLeft: "5px" }} onClick={() => navigate('/main/sales')}>Cancel</Button> :

                                    <Button variant="contained" color="primary" style={{ marginLeft: "5px" }} onClick={() => showBanner(false)}>Cancel</Button>
                            }

                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    );
}



export default CreateBanner;





// import React, { useState } from 'react';
// import { TextField, Grid, Button, Select, MenuItem, InputLabel, FormControl, Typography, Box } from '@mui/material';

// function CreateBanner() {
//   const [bannerData, setBannerData] = useState({
//     description: '',
//     offer: '',
//     fromDate: '',
//     toDate: '',
//     redirectTo: '',
//     backgroundImage: ''
//   });

//   const handleChange = (e) => {
//     setBannerData({ ...bannerData, [e.target.name]: e.target.value });
//   };

//   return (
//     <Grid container spacing={3} style={{ padding: '20px' }}>
//       <Grid item xs={12}>
//         <Typography variant="h5" gutterBottom>
//           Create Home Banner
//         </Typography>
//       </Grid>

//       <Grid item xs={12} md={8}>
//         <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
//           Banner Description
//         </Typography>
//         <TextField
//           placeholder="Enter description here"
//           name="description"
//           value={bannerData.description}
//           onChange={handleChange}
//           fullWidth
//           multiline
//           rows={3}
//           sx={{ mb: 2, width: 'calc(100%)','& textarea': { minHeight: '60px' } }}

//         />
//       </Grid>

//       <Grid item xs={12} md={4}>
//         {/* Preview Section */}
//         <Box border={1} borderColor="grey.300" padding={2} height="100%">
//           <Typography variant="h6">Preview</Typography>
//           <p><strong>Description:</strong> {bannerData.description}</p>
//           <p><strong>Offer Details:</strong> {bannerData.offer}</p>
//           <p><strong>From:</strong> {bannerData.fromDate}</p>
//           <p><strong>To:</strong> {bannerData.toDate}</p>
//           <p><strong>Redirect To:</strong> {bannerData.redirectTo}</p>
//           <p><strong>Background Image:</strong> {bannerData.backgroundImage}</p>
//         </Box>
//       </Grid>

//       <Grid item xs={12} md={8}>
//         <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
//           Offer Details
//         </Typography>
//         <Grid container >
//           <Grid item xs={8}>
//             <TextField
//               fullWidth
//               placeholder="Enter offer details here"
//               name="offer"
//               value={bannerData.offer}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={4} >
//             <FormControl fullWidth variant="outlined">
//               <InputLabel id="dropdown-label">Action</InputLabel>
//               <Select
//                 labelId="dropdown-label"
//                 value=""
//                 onChange={(e) => console.log(e.target.value)}
//                 style={{ height: '100%' }}
//                 label="Action"
//               >
//                 <MenuItem value="1">Option 1</MenuItem>
//                 <MenuItem value="2">Option 2</MenuItem>
//                 <MenuItem value="3">Option 3</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//       </Grid>

//       <Grid item xs={6} md={4}>
//         <TextField
//           fullWidth
//           label="From"
//           type="date"
//           name="fromDate"
//           value={bannerData.fromDate}
//           onChange={handleChange}
//           InputLabelProps={{
//             shrink: true,
//           }}
//         />
//       </Grid>

//       <Grid item xs={6} md={4}>
//         <TextField
//           fullWidth
//           label="To"
//           type="date"
//           name="toDate"
//           value={bannerData.toDate}
//           onChange={handleChange}
//           InputLabelProps={{
//             shrink: true,
//           }}
//         />
//       </Grid>

//       <Grid item xs={12} md={4}>
//         <FormControl fullWidth>
//           <InputLabel>Redirect To</InputLabel>
//           <Select
//             value={bannerData.redirectTo}
//             onChange={handleChange}
//             name="redirectTo"
//           >
//             <MenuItem value="Course 1">Course 1</MenuItem>
//             <MenuItem value="Course 2">Course 2</MenuItem>
//           </Select>
//         </FormControl>
//       </Grid>

//       <Grid item xs={12} md={8}>
//         <Button
//           variant="outlined"
//           component="label"
//           fullWidth
//         >
//           + Add Photo/Video
//           <input
//             type="file"
//             hidden
//             onChange={(e) => setBannerData({ ...bannerData, backgroundImage: e.target.files[0].name })}
//           />
//         </Button>
//       </Grid>
//     </Grid>
//   );
// }

// export default CreateBanner;


// import React, { useState } from 'react';
// import { TextField, Grid, Button, Select, MenuItem, InputLabel, FormControl, Typography, Box } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { setBanner } from '../../../../ducks/bannerSlice';
// import { useCreateHomeBannerMutation } from '../../../../services/apis/bannerApi';

// function CreateBanner() {

//     let [createBanner] = useCreateHomeBannerMutation()


//     const dispatch = useDispatch()

//     const [bannerData, setBannerData] = useState({
//         title: '',
//         description: '',
//         offer: '',
//         buttonType: 'buy',
//         startDate: '',
//         endDate: '',
//         sourceType: '',
//         sourceId: '',
//         thumbnail: ""
//     });



//     const handleChange = (e) => {
//         setBannerData({ ...bannerData, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault()
//         console.log(bannerData);
//         try {
//             await createBanner({
//                 bannerData
//             }).unwrap();

//             // Dispatch the newFeed to your Redux store
//             dispatch(setBanner(bannerData));
//         } catch (err) {
//             console.error("Failed to create feed algorithm:", err);
//         }
//     }

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         const imageURL = URL.createObjectURL(file);
//         setBannerData({ ...bannerData, thumbnail: imageURL }); // Set the image URL in thumbnail
//     };

//     return (

//         <Box
//             component="form"
//             onSubmit={handleSubmit}
//         >
//             <Grid container spacing={3} style={{ padding: '20px' }}>

//                 {/* Form Section on the right side */}
//                 <Grid item xs={12} md={8}>



//                     <Grid container spacing={3}>

//                         <Grid item xs={12}>
//                             <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
//                                 Banner Title
//                             </Typography>
//                             <TextField
//                                 placeholder="Enter Title here"
//                                 name="title"
//                                 value={bannerData.title}
//                                 onChange={handleChange}
//                                 fullWidth
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold' }}>
//                                 Banner Description
//                             </Typography>
//                             <TextField
//                                 placeholder="Enter description here"
//                                 name="description"
//                                 value={bannerData.description}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 multiline
//                                 rows={3}
//                                 sx={{ mb: 2, width: 'calc(100%)', '& textarea': { minHeight: '60px' } }}
//                             />
//                         </Grid>

//                         <Grid container spacing={0}>
//                             <Grid item xs={8}>
//                                 <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}>
//                                     Offer Details
//                                 </Typography>
//                                 <TextField
//                                     fullWidth
//                                     placeholder="Enter offer details here"
//                                     name="offer"
//                                     value={bannerData.offer}
//                                     onChange={handleChange}
//                                     sx={{ mb: 0, width: 'calc(100% - 20px)', ml: '20px' }} // Width aur left margin adjust ki gayi hai
//                                     style={{ padding: 0 }}
//                                 />
//                             </Grid>
//                             <Grid item xs={4}>
//                                 <FormControl fullWidth variant="outlined">
//                                     <Select
//                                         name="buttonType"
//                                         value={bannerData.buttonType}
//                                         onChange={handleChange}
//                                         style={{ height: '55px', marginTop: "30px" }}
//                                     >
//                                         <MenuItem value="buy">Buy now</MenuItem>
//                                         <MenuItem value="visitGroup">Visit Group</MenuItem>
//                                         <MenuItem value="watch">Watch</MenuItem>
//                                         <MenuItem value="learnMore">Learn More</MenuItem>
//                                         <MenuItem value="read">Read</MenuItem>
//                                         <MenuItem value="join">Join</MenuItem>
//                                     </Select>
//                                 </FormControl>
//                             </Grid>
//                         </Grid>


//                         <Grid item xs={6}>

//                             <TextField
//                                 fullWidth
//                                 label="From"
//                                 type="date"
//                                 name="startDate"
//                                 value={bannerData.startDate}
//                                 onChange={handleChange}
//                                 InputLabelProps={{
//                                     shrink: true,
//                                 }}
//                             />
//                         </Grid>

//                         <Grid item xs={6}>
//                             <TextField
//                                 fullWidth
//                                 label="To"
//                                 type="date"
//                                 name="endDate"
//                                 value={bannerData.endDate}
//                                 onChange={handleChange}
//                                 InputLabelProps={{
//                                     shrink: true,
//                                 }}
//                             />
//                         </Grid>

//                         <Grid item xs={6}>
//                             <Typography variant="h6" gutterBottom style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}>
//                                 Redirect To
//                             </Typography>
//                             <FormControl fullWidth>
//                                 <Select
//                                     value={bannerData.sourceType}
//                                     onChange={handleChange}
//                                     name="sourceType"
//                                 >
//                                     <MenuItem value="Course">Course </MenuItem>
//                                     <MenuItem value="Course 1">Course 1</MenuItem>
//                                     <MenuItem value="Course 2">Course 2</MenuItem>
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                         <Grid item xs={6} style={{ marginTop: "30px" }}>
//                             <FormControl fullWidth>
//                                 <Select
//                                     value={bannerData.sourceId}
//                                     onChange={handleChange}
//                                     name="sourceId"
//                                 >
//                                     <MenuItem value="Select Course">Select Course </MenuItem>
//                                     <MenuItem value="Course 1">Course 1</MenuItem>
//                                     <MenuItem value="Course 2">Course 2</MenuItem>
//                                 </Select>
//                             </FormControl>
//                         </Grid>

//                         <Grid item xs={12}>
//                             <Typography
//                                 variant="h6"
//                                 gutterBottom
//                                 style={{ fontSize: 15, fontWeight: 'bold', marginLeft: "20px" }}
//                             >
//                                 Background Image
//                             </Typography>
//                             <Button
//                                 variant="outlined"
//                                 component="label"
//                                 style={{
//                                     height: "250px",
//                                     width: "350px",
//                                     backgroundColor: "whiteSmoke",
//                                     backgroundImage: bannerData.thumbnail ? `url(${bannerData.thumbnail})` : 'none',
//                                     backgroundSize: 'cover',
//                                     backgroundPosition: 'center',
//                                 }}
//                             >
//                                 + Add Photo/Video
//                                 <input
//                                     type="file"
//                                     hidden
//                                     onChange={handleImageChange} // Image change handler
//                                 />
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 </Grid>

//                 {/* Preview Section on the left side */}
//                 <Grid item xs={12} md={4}>
//                     <Box border={1} borderColor="grey.300" padding={2} height="100%">
//                         <Typography variant="h6">Preview</Typography>
//                         {/* Display the thumbnail as an image */}
//                         <p><strong>Title:</strong> {bannerData.title}</p>
//                         <p><strong>Description:</strong> {bannerData.description}</p>
//                         <p><strong>Offer Details:</strong> {bannerData.offer}</p>
//                         <p><strong>From:</strong> {bannerData.startDate}</p>
//                         <p><strong>To:</strong> {bannerData.endDate}</p>
//                         <p><strong>Redirect To:</strong> {bannerData.sourceType}</p>
//                         <p>
//                             <strong>Background Image:</strong>
//                             {bannerData.thumbnail && (
//                                 <img
//                                     src={bannerData.thumbnail}
//                                     alt="Preview"
//                                     style={{ width: '100px', height: "100px", marginTop: '10px' }}
//                                 />
//                             )}
//                         </p>
//                     </Box>
//                     <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
//                         {/* <Button onClick={onCancel} variant="outlined" sx={{ mr: 1 }}>
//                             Preview
//                         </Button> */}
//                         <Button type="submit" variant="contained" color="primary">
//                             Send
//                         </Button>
//                     </Box>
//                 </Grid>

//             </Grid>
//         </Box>
//     );
// }

// export default CreateBanner;