import React, { useEffect, useState } from "react";
import DatamapsIndia from "react-datamaps-india";

const Maps = ({ datas }) => {
  const [secondArry, setsecondArry] = useState()

  let data = datas.map((val) => (
    {

      stateCounts: val.stateCounts
    }
  ))

  //  console.log(data);
  //  if(data){
  //   let seconArr = data.map(item=>item.stateCounts)
  //   console.log(seconArr,1234);

  //  }








  // console.log(secondArry);





  return (
    <div className="absolute w-full scale-150">
      <DatamapsIndia
        regionData={{ data }}
        hoverComponent={({ value }) => {
          return (
            <div className="w-1/3 h-20 p-2 bg-white">
              {console.log(value)
              }
              <p >{value?.name}</p>
              <p >{value?.value}</p>
            </div>
          );
        }}
        mapLayout={{
          title: "",
          legendTitle: "",
          startColor: "#17cf0a",
          endColor: "#FF6347",
          hoverTitle: "Count",
          noDataColor: "#f5f5f5",
          borderColor: "#8D8D8D",
          hoverBorderColor: "#8D8D8D",
          hoverColor: "green",
        }}
      />
    </div>
  );
};
export default Maps;
