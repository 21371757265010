import React, { useEffect, useState } from "react";
import { Typography, Box, Stack, Card, CardContent, MenuItem, FormControl, Select } from "@mui/material";
import { MoreVertical, CircleDot } from "lucide-react";
import PieGraph from "./pieGraph";
import LineGraphs from "./lineGraphs";
import BarGraph from "./barGraph";
import { useGetClassAnalyticQuery } from "../../../../../../services/apis/exam/class";
import { classApi } from "../../../../../../services/Constant";
import { useParams } from "react-router-dom";

const ClassAnalytics = () => {
  const params = useParams();

  // Filter states
  const [recordFilter, setRecordFilter] = useState(30);
  const [growthFilter, setGrowthFilter] = useState(30);
  const [performanceFilter, setPerformanceFilter] = useState(30);

  // Data states
  const [classRecordData, setClassRecordData] = useState({
    totalCount: 0,
    upcomingCount: 0,
    completedCount: 0
  });
  const [userGrowthData, setUserGrowthData] = useState({
    userIncreasePercentage: "0%",
    userGrowthStatsCurrentPeriod: []
  });
  const [liveClassPerformanceList, setLiveClassPerformanceList] = useState([]);
  const [overAllClassPerformanceList, setOverAllClassPerformanceList] = useState([]);
  const [appClassPerformanceList, setAppClassPerformanceList] = useState([]);
  const [classType, setClassType] = useState("all");

  // Fetch data using custom hooks
  const { data: classRecordResponse, isSuccess: classRecordSuccess } = useGetClassAnalyticQuery(
    `${classApi.endPoint}/analysis/classRecord/${params.examId}/?days=${recordFilter}`
  );

  const { data: growthData, isSuccess: growthSuccess } = useGetClassAnalyticQuery(
    `${classApi.endPoint}/analysis/growth/${params.examId}/?days=${growthFilter}`
  );

  const { data: performanceData, isSuccess: performanceSuccess } = useGetClassAnalyticQuery(
    `${classApi.endPoint}/analysis/performance/${params.examId}/?days=${performanceFilter}`
  );

  // Update data states on successful fetch
  useEffect(() => {
    if (classRecordSuccess && classRecordResponse?.data) {
      setClassRecordData(classRecordResponse.data.classRecord);
    }
  }, [classRecordResponse, classRecordSuccess]);

  useEffect(() => {
    if (growthSuccess && growthData?.data) {
      setUserGrowthData({
        userIncreasePercentage: growthData.data.userGrowth.userIncreasePercentage,
        userGrowthStatsCurrentPeriod: growthData.data.userGrowth.userGrowthStatsCurrentPeriod
      });
    }
  }, [growthData, growthSuccess]);

  useEffect(() => {
    if (performanceSuccess && performanceData?.data) {
      setLiveClassPerformanceList(performanceData.data.liveClassPerformanceList);
      setOverAllClassPerformanceList(performanceData.data.overAllClassPerformanceList);
      setAppClassPerformanceList(performanceData.data.applicationClassPerformanceList);

    }
  }, [performanceData, performanceSuccess]);

  //handling event
  const handleRecordFilterChange = (event) => {
    setRecordFilter(event.target.value);
  };

  const handleGrowthFilterChange = (event) => {
    setGrowthFilter(event.target.value);
  };

  const handlePerformanceFilterChange = (event) => {
    setPerformanceFilter(event.target.value);
  };

  const handleClassTypeChange = (event) => {
    setClassType(event.target.value);
  };

  //consditionaly render performance graph

  const renderBarGraph = () => {
    if (classType === "all") {
      return <BarGraph performanceData={overAllClassPerformanceList} classType="all" />;
    } else if (classType === "appClass") {
      return <BarGraph performanceData={appClassPerformanceList} classType="appClass" />;
    } else if (classType === "liveClass") {
      return <BarGraph performanceData={liveClassPerformanceList} classType="liveClass" />;
    }
  };
  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };

  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Stack sx={{ margin: "10px", padding: "10px" }} direction={"row"} justifyContent={"space-around"}>
        {/* Class Record Card */}
        <Card sx={{ width: "50%" }}>
          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>Class Record</Typography>
              <Box display={"flex"} alignItems={"center"}>
                <FormControl sx={{ width: "150px" }}>
                  <Select
                    labelId="record-filter-label"
                    id="record-filter-select"
                    value={recordFilter}
                    onChange={handleRecordFilterChange}
                    size="small"
                  >
                    {Object.keys(filterTypeLabels).map((key) => (
                      <MenuItem key={key} value={parseInt(key)}>{filterTypeLabels[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <MoreVertical />
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ paddingY: "10px" }}>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h6">{classRecordData.totalCount}</Typography>
                <Typography variant="body2">Total Classes Created</Typography>
                <Typography variant="body1">{classRecordData.upcomingCount}</Typography>
                <Typography variant="body2">
                  <CircleDot size={20} color="#0fd730" strokeWidth={4} style={{ marginRight: "3px" }} />
                  Total Classes Scheduled
                </Typography>
                <Typography variant="body1">{classRecordData.completedCount}</Typography>
                <Typography variant="body2">
                  <CircleDot size={20} color="#5924bc" strokeWidth={4} style={{ marginRight: "3px" }} />
                  Total Classes Conducted
                </Typography>
              </Stack>
              <div className="w-full flex justify-center items-center">
                <div className="w-[480px]">
                  <PieGraph totalRecord={classRecordData} />
                </div>
              </div>
            </Stack>
          </CardContent>
        </Card>

        {/* Users Growth Card */}
        <Card sx={{ width: "50%", marginLeft: "10px" }}>
          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>Users Growth</Typography>
              <Box display={"flex"} alignItems={"center"}>
                <FormControl sx={{ width: "150px" }}>
                  <Select
                    labelId="growth-filter-label"
                    id="growth-filter-select"
                    value={growthFilter}
                    onChange={handleGrowthFilterChange}
                    size="small"
                  >
                    {Object.keys(filterTypeLabels).map((key) => (
                      <MenuItem key={key} value={parseInt(key)}>{filterTypeLabels[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <MoreVertical />
              </Box>
            </Stack>
            <Box sx={{ padding: "10px" }}>
              <Stack direction={"row"} spacing={0.8} alignItems={"center"}>
                <Typography variant="h3">{userGrowthData.userIncreasePercentage}</Typography>
                <Typography variant="body2">increase in users</Typography>
              </Stack>
              <LineGraphs data={userGrowthData.userGrowthStatsCurrentPeriod} />
            </Box>
          </CardContent>
        </Card>
      </Stack>

      {/* Overall Class Performance Card */}
      <Stack sx={{ margin: "10px", padding: "10px", marginTop: "-10px" }}>
        <Card>
          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>Overall Class Performance</Typography>
              <Box display={"flex"} alignItems={"center"}>
                <FormControl sx={{ width: "150px" }}>
                  <Select
                    labelId="performance-filter-label"
                    id="performance-filter-select"
                    value={performanceFilter}
                    onChange={handlePerformanceFilterChange}
                    size="small"
                  >
                    {Object.keys(filterTypeLabels).map((key) => (
                      <MenuItem key={key} value={parseInt(key)}>{filterTypeLabels[key]}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "150px", marginLeft: "10px" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-type"
                    value={classType}
                    onChange={handleClassTypeChange}
                    displayEmpty
                    renderValue={(selected) => (selected.length === 0 ? <em>All</em> : selected)}
                    size="small"
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="appClass">App Class</MenuItem>
                    <MenuItem value="liveClass">Live Class</MenuItem>
                  </Select>
                </FormControl>
                <MoreVertical />
              </Box>
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              {renderBarGraph()}
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default ClassAnalytics;
