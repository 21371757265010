

import React from "react";
import Product from "./product";
import Revenue from "./revenue";
import Catgory from "./catgory";
import Sales from "./sales";
import Growth from "./growth";
import AppInstall from "./appInstall";
import ActiveUser from "./activeUser";
import Work from "./work";
import AgeNavbar from "./ageNavbar";
import AgeAnalytical from "./ageAnalytical";
import Hour from "./hour";
import AverageReviews from "./averageReviews";
import CancelIcon from "@mui/icons-material/Cancel";

function MainContent({ activeSection, onSectionClose, data, setFilterTypevalue }) {

  const revenueTrend = data?.data?.revenueTrend;
  const salesCategory = data?.data?.salesCategory;
  const userGrowthOverDate = data?.data?.userGrowthOverDate;
  const salesCount = data?.data?.salesCount;
  const totalUserAndPremiumUser = data?.data?.totalUserAndPremiumUser;
  const usersWorkExperienceByLocation = data?.data?.usersWorkExperienceByLocation;
  const ageAndGender = data?.data?.ageAndGender;



  const renderDefaultScreen = () => (
    <>
      <div className="flex space-x-7 m-6">
        <Product product = {totalUserAndPremiumUser} />
        <Revenue revenue={revenueTrend}  />
      </div>
      <div className="flex space-x-5 m-6 mx-2">
        <Catgory category={salesCategory}  />
        <Sales sales={salesCount}  />
      </div>
      <div className="p-1 m-3 rounded-md border border-r-emerald-50">
        <Growth growth={userGrowthOverDate}  />
      </div>
    </>
  );

  const renderCloseButton = () => (
    <div className="flex justify-end">
      <button onClick={onSectionClose} className="text-black-500 font-bold">
        <CancelIcon />
      </button>
    </div>
  );

  const renderSection = (SectionComponent) => (
    <>
      {renderCloseButton()}
      <div className="p-1 m-3 border-2 border-blue-500 rounded-md">
        <SectionComponent />
      </div>
    </>
  );

  return (
    <div className="p-4">
      {activeSection === "Active Users" && renderSection(ActiveUser)}
      {activeSection === "App Install" && renderSection(AppInstall)}
      {activeSection === "Top Location" && renderSection(() => (
        <>
          <Work userExperience={usersWorkExperienceByLocation} />
        </>
      ))}
      {activeSection === "Avg Age Group" && renderSection(() => (
        <>
          <AgeNavbar  data={ageAndGender}  />
          <AgeAnalytical ageAndGender={ageAndGender} />
        </>
      ))}
      {activeSection === "Hours Engaged" && renderSection(Hour)}
      {activeSection === "Avg Rating" && renderSection(AverageReviews)}
      {renderDefaultScreen()}
    </div>
  );
}

export default MainContent;
