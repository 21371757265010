

import React from "react";
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

const reviews = [
  {
    name: "Vishal Talwar",
    rating: 4.5,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_1",
  },
  {
    name: "Krisha Raj",
    rating: 3.5,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_2",
  },
  {
    name: "Ritu Rana",
    rating: 3,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_3",
  },
  {
    name: "Nikhil Rana",
    rating: 3,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_3",
  },
  {
    name: "Riya Rana",
    rating: 3,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_3",
  },
  {
    name: "Piya Rana",
    rating: 4,
    text: "The app is great to use, very easy to access and all the questions are answered instantly. The app has...",
    avatar: "path_to_avatar_3",
  },
  {
    name: "shetal",
    rating: 5,
    text: "this App is really good....... ",
    avatar: "path_to_avatar_3",
  },
  {
    name: "pshetal",
    rating: 1,
    text: "this App is really good....... ",
    avatar: "path_to_avatar_3",
  },
  {
    name: "Anshul",
    rating: 3,
    text: "this App is really good....... ",
    avatar: "path_to_avatar_3",
  },
  {
    name: "hetal",
    rating: 2,
    text: "this App is really good....... ",
    avatar: "path_to_avatar_3",
  },
];

const renderStars = (rating) => {
  return (
    <Box className="flex">
      {Array.from({ length: 5 }, (_, i) => (
        <React.Fragment key={i}>
          {rating >= i + 1 ? (
            <StarIcon className="text-yellow-500" />
          ) : rating > i ? (
            <StarHalfIcon className="text-yellow-500" />
          ) : (
            <StarOutlineIcon className="text-yellow-500" />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

const getStarCounts = (reviews) => {
  const starCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  reviews.forEach((review) => {
    const fullStars = Math.floor(review.rating);
    starCounts[fullStars]++;
    if (review.rating > fullStars) {
      starCounts[fullStars + 1]++;
    }
  });
  return starCounts;
};

const getTotalReviews = (starCounts) => {
  return Object.values(starCounts).reduce((a, b) => a + b, 0);
};

const AverageReviews = () => {
  const starCounts = getStarCounts(reviews);
  const totalReviews = getTotalReviews(starCounts);

  return (
    <Box className="flex flex-col lg:flex-row lg:justify-between p-4 bg-white shadow-md rounded-md">
      <Box className="lg:w-1/3 p-4">
        <Typography
          variant="h5"
          className="font-bold"
          style={{ marginBottom: "20px" }}
        >
          Average Reviews
        </Typography>
        <Box className="flex justify-between items-center">
          <Box>
            <Typography variant="h6" className="mt-2 text-black-700">
              4.0
            </Typography>
            <Typography variant="body2" className="text-gray-500">
              5k Rating
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" className="text-black-800">
              6,875
            </Typography>
            <Typography variant="body2" className="mt-4 text-gray-600">
              Total Users
            </Typography>
          </Box>
        </Box>
        <Box className="mt-4">
          {Object.keys(starCounts)
            .sort((a, b) => b - a)
            .map((star) => (
              <Box className="flex items-center mt-2" key={star}>
                <Typography variant="body2" className="w-1/4">
                  {star} star
                </Typography>
                <Box className="w-3/4 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <Box
                    className="h-2.5 rounded-full"
                    style={{
                      width: `${(starCounts[star] / totalReviews) * 100}%`,
                      backgroundColor:
                        star == 5
                          ? "#24B670"
                          : star == 4
                          ? "#90B624"
                          : star == 3
                          ? "#FFD43E"
                          : star == 2
                          ? "#FCA522"
                          : "#EA3434",
                    }}
                  ></Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        className="lg:w-2/3 p-4 overflow-auto"
        style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        <Typography variant="h5" className="font-bold">
          Top reviews and ratings
        </Typography>
        <List>
          {reviews.map((review, index) => (
            <ListItem alignItems="flex-start" key={index}>
              <ListItemAvatar>
                <Avatar alt={review.name} src={review.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box className="flex items-center">
                    <Typography className="font-semibold">
                      {review.name}
                    </Typography>
                    {renderStars(review.rating)}
                  </Box>
                }
                secondary={review.text}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default AverageReviews;
