import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { doubtApi } from "../../../../../services/Constant";
import { useAddDoubtSettingMutation } from "../../../../../services/apis/doubtApi";
import { useGetDoubtListQuery } from "../../../../../services/apis/doubtApi";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Paper,
  IconButton,
  FormControlLabel,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export const EditForm = ({ onSave }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      coinChart: [{}],
      extendedDuration: [{}],
      extensionAllowed: false,
      autoPassAllowed: false,
      incorrectPenalty: {},
      reviewRewards: {},
    },
  });

  const [addDoubtSetting, { isLoading: isLoadingAdd, isError: isErrorAdd }] =
    useAddDoubtSettingMutation();

  const [isExtensionAllowed, setIsExtensionAllowed] = useState(false);
  const [isAutoPassAllowed, setIsAutoPassAllowed] = useState(false);

  const {
    fields: coinChartFields,
    append: appendCoinChart,
    remove: removeCoinChart,
  } = useFieldArray({
    control,
    name: "coinChart",
  });

  const {
    fields: extendedDurationFields,
    append: appendExtendedDuration,
    remove: removeExtendedDuration,
  } = useFieldArray({
    control,
    name: "extendedDuration",
  });

  const onSubmit = async (formData) => {
    try {

      const updatedFormData = {
        ...formData,
        extensionAllowed: isExtensionAllowed,
        autoPassAllowed: isAutoPassAllowed,
      };

      const response = await addDoubtSetting({
        endpoint: `${doubtApi.endPoint}/solving/setting`,
        doubtData: updatedFormData,
      });

      if (response && onSave) {
        onSave(updatedFormData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        margin: "20px auto",
        maxWidth: "800px",
        overflowY: "auto",
        maxHeight: "80vh",
      }}
    >
      <div style={{ maxHeight: "calc(80vh - 80px)", overflowY: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="entityType"
                  control={control}
                  rules={{ required: "Entity Type is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Entity Type"
                      fullWidth
                      error={!!errors.entityType}
                      helperText={errors.entityType?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="category"
                  control={control}
                  rules={{ required: "Category is required" }}
                  render={({ field }) => (
 <FormControl fullWidth error={!!errors.category}>
                      <InputLabel>Category</InputLabel>
                      <Select {...field} label="Category">
                        <MenuItem value="public">Public</MenuItem>
                        <MenuItem value="private">Private</MenuItem>
                      </Select>
                      {errors.category && (
                        <Typography color="error" variant="body2">
                          {errors.category.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>

            {/* Setting Type and Time Limit */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="settingType"
                  control={control}
                  rules={{ required: "Setting Type is required" }}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.settingType}>
                      <InputLabel>Setting Type</InputLabel>
                      <Select {...field} label="Setting Type">
                        <MenuItem value="topic">Topic</MenuItem>
                        <MenuItem value="subTopic">SubTopic</MenuItem>
                        <MenuItem value="subject">Subject</MenuItem>
                      </Select>
                      {errors.settingType && (
                        <Typography color="error" variant="body2">
                          {errors.settingType.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="timeLimit"
                  control={control}
                  rules={{ required: "Time Limit is required", min: 1 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Time Limit"
                      type="number"
                      fullWidth
                      error={!!errors.timeLimit}
                      helperText={errors.timeLimit?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Coin Chart
              </Typography>
              {coinChartFields.map((item, index) => (
                <Box key={item.id} mb={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <Controller
                        name={`coinChart.${index}.minute`}
                        control={control}
                        rules={{ required: "Minute is required", min: 1 }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Minute"
                            type="number"
                            fullWidth
                            error={!!errors.coinChart?.[index]?.minute}
                            helperText={
                              errors.coinChart?.[index]?.minute?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name={`coinChart.${index}.coin`}
                        control={control}
                        rules={{ required: "Coin is required", min: 1 }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Coin"
                            type="number"
                            fullWidth
                            error={!!errors.coinChart?.[index]?.coin}
                            helperText={
                              errors.coinChart?.[index]?.coin?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={`coinChart.${index}.point`}
                        control={control}
                        rules={{ required: "Point is required", min: 1 }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Point"
                            type="number"
                            fullWidth
                            error={!!errors.coinChart?.[index]?.point}
                            helperText={
                              errors.coinChart?.[index]?.point?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => removeCoinChart(index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={() => appendCoinChart({})}
                startIcon={<AddIcon />}
                size="small"
              >
                Add Coin Chart
              </Button>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isExtensionAllowed}
                    onChange={(e) => {
                      setIsExtensionAllowed(e.target.checked);
                      setValue("extensionAllowed", e.target.checked);
                      if (!e.target.checked) {
                        setValue("extendedDuration", []);
                      }
                    }}
                  />
                }
                label="Extension Allowed"
              />
            </Grid>

            {isExtensionAllowed && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Extended Duration
                </Typography>
                {extendedDurationFields.map((item, index) => (
                  <Box key={item.id} mb={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={5}>
                        <Controller
                          name={`extendedDuration.${index}.penalty`}
                          control={control}
                          rules={{
                            required: "Penalty is required",
                            min: 1,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Penalty"
                              type="number"
                              fullWidth
                              error={
                                !!errors.extendedDuration?.[index]?.penalty
                              }
                              helperText={
                                errors.extendedDuration?.[index]?.penalty
                                  ?.message
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          name={`extendedDuration.${index}.extensionPeriod`}
                          control={control}
                          rules={{
                            required: "Extension Period is required",
                            min: 1,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Extension Period"
                              type="number"
                              fullWidth
                              error={
                                !!errors.extendedDuration?.[index]
                                  ?.extensionPeriod
                              }
                              helperText={
                                errors.extendedDuration?.[index]
                                  ?.extensionPeriod?.message
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => removeExtendedDuration(index)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => appendExtendedDuration({})}
                  startIcon={<AddIcon />}
                  size="small"
                >
                  Add Duration
                </Button>
              </Grid>
            )}

            {/* Auto Pass Allowed */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAutoPassAllowed}
                    onChange={(e) => {
                      setIsAutoPassAllowed(e.target.checked);
                      setValue("autoPassAllowed", e.target.checked);
                    }}
                  />
                }
                label="Auto Pass Allowed"
              />
            </Grid>

            {/* Auto Pass Duration */}
            {isAutoPassAllowed && (
              <Grid item xs={12}>
                <Controller
                  name="autoPassDuration"
                  control={control}
                  rules={{ required: "Auto Pass Duration is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Auto Pass Duration"
                      type="number"
                      fullWidth
                      error={!!errors.autoPassDuration}
                      helperText={errors.autoPassDuration?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {/* Incorrect Penalty */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Incorrect Penalty
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="incorrectPenalty.coin"
                    control={control}
                    rules={{ required: "Penalty Coin is required", min: 0 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Penalty Coin"
                        type="number"
                        fullWidth
                        error={!!errors.incorrectPenalty?.coin}
                        helperText={errors.incorrectPenalty?.coin?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="incorrectPenalty.point"
                    control={control}
                    rules={{ required: "Penalty Point is required", min: 0 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Penalty Point"
                        type="number"
                        fullWidth
                        error={!!errors.incorrectPenalty?.point}
                        helperText={errors.incorrectPenalty?.point?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Review Rewards */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Review Rewards
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="reviewRewards.coin"
                    control={control}
                    rules={{ required: "Reward Coin is required", min: 0 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Reward Coin"
                        type="number"
                        fullWidth
                        error={!!errors.reviewRewards?.coin}
                        helperText={errors.reviewRewards?.coin?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="reviewRewards.point"
                    control={control}
                    rules={{ required: "Reward Point is required", min: 0 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Reward Point"
                        type="number"
                        fullWidth
                        error={!!errors.reviewRewards?.point}
                        helperText={errors.reviewRewards?.point?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoadingAdd}
              >
                Save
              </Button>
              {isErrorAdd && (
                <Typography color="error" variant="body2">
                  Error submitting form
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
};

export default EditForm;


// import React, { useState, useEffect } from "react";
// import { useForm, Controller, useFieldArray } from "react-hook-form";
// import { doubtApi } from "../../../../../services/Constant";
// import {
//   useAddDoubtSettingMutation,
//   useGetDoubtListQuery,
// } from "../../../../../services/apis/doubtApi";
// import {
//   TextField,
//   Select,
//   MenuItem,
//   Checkbox,
//   Button,
//   FormControl,
//   InputLabel,
//   Grid,
//   Typography,
//   Paper,
//   IconButton,
//   FormControlLabel,
//   Box,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useNavigate, useParams } from "react-router-dom";

// export const EditForm = ({ onSave }) => {
//   const {
//     control,
//     handleSubmit,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     defaultValues: {
//       coinChart: [{}],
//       extendedDuration: [{}],
//       extensionAllowed: false,
//       autoPassAllowed: false,
//       incorrectPenalty: {},
//       reviewRewards: {},
//     },
//   });

//   const [values, setValues] = useState({});
//   const params = useParams();
//   const [entityTypes, setEntityTypes] = useState([]);
//   const [addDoubtSetting, { isLoading: isLoadingAdd, isError: isErrorAdd }] =
//     useAddDoubtSettingMutation();

//   const [isExtensionAllowed, setIsExtensionAllowed] = useState(false);
//   const [isAutoPassAllowed, setIsAutoPassAllowed] = useState(false);

//   const {
//     fields: coinChartFields,
//     append: appendCoinChart,
//     remove: removeCoinChart,
//   } = useFieldArray({
//     control,
//     name: "coinChart",
//   });

//   const {
//     fields: extendedDurationFields,
//     append: appendExtendedDuration,
//     remove: removeExtendedDuration,
//   } = useFieldArray({
//     control,
//     name: "extendedDuration",
//   });

//   const onSubmit = async (formData) => {
//     try {
//       const updatedFormData = {
//         ...formData,
//         extensionAllowed: isExtensionAllowed,
//         autoPassAllowed: isAutoPassAllowed,
//       };

//       const response = await addDoubtSetting({
//         endpoint: `${doubtApi.endPoint}/solving/setting`,
//         doubtData: updatedFormData,
//       });

//       if (response && onSave) {
//         onSave(updatedFormData);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const {
//     data: sectionData,
//     isLoading: sectionLoad,
//     isError: sectionErr,
//     isSuccess: sectionSuccess,
//   } = useGetDoubtListQuery(
//     `${doubtApi.endPoint}/list/setting/${params?.examId}`,
//     {
//       refetchOnMountOrArgChange: true,
//       refetchOnReconnect: true,
//     }
//     );
 
 
//   useEffect(() => {
//     if (params.examId) {
//       setValues({ ...values });
//     }
//   }, [params]);
//   useEffect(() => {
//     if (params.examId) {
//       console.log("Params Exam ID:", params.examId); // Log the examId
//       setValues((prevValues) => ({ ...prevValues, entityType: params.examId }));
//       setValue("entityType", params.examId); // Set the value in the form
//     }
//   }, [params, setValue]);

//   useEffect(() => {
//     if (sectionSuccess) {
//       console.log("Fetched entity types:", sectionData);
//     }
//     if (sectionErr) {
//       console.error("Error fetching entity types:", sectionErr);
//     }
//   }, [sectionSuccess, sectionErr, sectionData]);

//   return (
//     <Paper
//       elevation={3}
//       style={{
//         padding: "20px",
//         margin: "20px auto",
//         maxWidth: "800px",
//         overflowY: "auto",
//         maxHeight: "80vh",
//       }}
//     >
//       <div style={{ maxHeight: "calc(80vh - 80px)", overflowY: "auto" }}>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} container spacing={2}>
//               <Grid item xs={6}>
//                 <Controller
//                   name="entityType"
//                   control={control}
//                   rules={{ required: "Entity Type is required" }}
//                   render={({ field }) => (
//                     <FormControl fullWidth error={!!errors.entityType}>
//                       <InputLabel>Entity Type</InputLabel>
//                       <Select
//                         {...field}
//                         label="Entity Type"
//                         fullWidth
//                         error={!!errors.entityType}
//                       >
//                         {Array.isArray(sectionData) &&
//                         sectionData.length > 0 ? (
//                           sectionData.map((entity) => (
//                             <MenuItem
//                               key={entity._id}
//                               value={entity.entityType}
//                             >
//                               {entity.entityType}
//                             </MenuItem>
//                           ))
//                         ) : (
//                           <MenuItem value="" disabled>
//                             No Entity Types Available
//                           </MenuItem>
//                         )}
//                       </Select>
//                       {errors.entityType && (
//                         <Typography color="error" variant="body2">
//                           {errors.entityType.message}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <Controller
//                   name="category"
//                   control={control}
//                   rules={{ required: "Category is required" }}
//                   render={({ field }) => (
//                     <FormControl fullWidth error={!!errors.category}>
//                       <InputLabel>Category</InputLabel>
//                       <Select {...field} label="Category">
//                         <MenuItem value="public">Public</MenuItem>
//                         <MenuItem value="private">Private</MenuItem>
//                       </Select>
//                       {errors.category && (
//                         <Typography color="error" variant="body2">
//                           {errors.category.message}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   )}
//                 />
//               </Grid>
//             </Grid>

//             {/* Setting Type and Time Limit */}
//             <Grid item xs={12} container spacing={2}>
//               <Grid item xs={6}>
//                 <Controller
//                   name="settingType"
//                   control={control}
//                   rules={{ required: "Setting Type is required" }}
//                   render={({ field }) => (
//                     <FormControl fullWidth error={!!errors.settingType}>
//                       <InputLabel>Setting Type</InputLabel>
//                       <Select {...field} label="Setting Type">
//                         <MenuItem value="topic">Topic</MenuItem>
//                         <MenuItem value="subTopic">SubTopic</MenuItem>
//                         <MenuItem value="subject">Subject</MenuItem>
//                       </Select>
//                       {errors.settingType && (
//                         <Typography color="error" variant="body2">
//                           {errors.settingType.message}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   )}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <Controller
//                   name="timeLimit"
//                   control={control}
//                   rules={{ required: "Time Limit is required", min: 1 }}
//                   render={({ field }) => (
//                     <TextField
//                       {...field}
//                       label="Time Limit"
//                       type="number"
//                       fullWidth
//                       error={!!errors.timeLimit}
//                       helperText={errors.timeLimit?.message}
//                     />
//                   )}
//                 />
//               </Grid>
//             </Grid>

//             <Grid item xs={12}>
//               <Typography variant="h6" gutterBottom>
//                 Coin Chart
//               </Typography>
//               {coinChartFields.map((item, index) => (
//                 <Box key={item.id} mb={2}>
//                   <Grid container spacing={2} alignItems="center">
//                     <Grid item xs={4}>
//                       <Controller
//                         name={`coinChart.${index}.minute`}
//                         control={control}
//                         rules={{ required: "Minute is required", min: 1 }}
//                         render={({ field }) => (
//                           <TextField
//                             {...field}
//                             label="Minute"
//                             type="number"
//                             fullWidth
//                             error={!!errors.coinChart?.[index]?.minute}
//                             helperText={
//                               errors.coinChart?.[index]?.minute?.message
//                             }
//                           />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={4}>
//                       <Controller
//                         name={`coinChart.${index}.coin`}
//                         control={control}
//                         rules={{ required: "Coin is required", min: 1 }}
//                         render={({ field }) => (
//                           <TextField
//                             {...field}
//                             label="Coin"
//                             type="number"
//                             fullWidth
//                             error={!!errors.coinChart?.[index]?.coin}
//                             helperText={
//                               errors.coinChart?.[index]?.coin?.message
//                             }
//                           />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={3}>
//                       <Controller
//                         name={`coinChart.${index}.point`}
//                         control={control}
//                         rules={{ required: "Point is required", min: 1 }}
//                         render={({ field }) => (
//                           <TextField
//                             {...field}
//                             label="Point"
//                             type="number"
//                             fullWidth
//                             error={!!errors.coinChart?.[index]?.point}
//                             helperText={
//                               errors.coinChart?.[index]?.point?.message
//                             }
//                           />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={1}>
//                       <IconButton
//                         onClick={() => removeCoinChart(index)}
//                         aria-label="delete"
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ))}
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => appendCoinChart({})}
//                 startIcon={<AddIcon />}
//                 size="small"
//               >
//                 Add Coin Chart
//               </Button>
//             </Grid>

//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={isExtensionAllowed}
//                     onChange={(e) => {
//                       setIsExtensionAllowed(e.target.checked);
//                       setValue("extensionAllowed", e.target.checked);
//                       if (e.target.checked) {
//                         setValue("extendedDuration", [{}]);
//                       } else {
//                         setValue("extendedDuration", [{}]);
//                       }
//                     }}
//                   />
//                 }
//                 label="Extension Allowed"
//               />
//               {isExtensionAllowed &&
//                 extendedDurationFields.map((item, index) => (
//                   <Box key={item.id} mb={2}>
//                     <Grid container spacing={2} alignItems="center">
//                       <Grid item xs={4}>
//                         <Controller
//                           name={`extendedDuration.${index}.penalty`}
//                           control={control}
//                           rules={{
//                             required: "Penalty is required",
//                             min: 1,
//                           }}
//                           render={({ field }) => (
//                             <TextField
//                               {...field}
//                               label="Penalty"
//                               type="number"
//                               fullWidth
//                               error={
//                                 !!errors.extendedDuration?.[index]?.penalty
//                               }
//                               helperText={
//                                 errors.extendedDuration?.[index]?.penalty
//                                   ?.message
//                               }
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={4}>
//                         <Controller
//                           name={`extendedDuration.${index}.extensionPeriod`}
//                           control={control}
//                           rules={{
//                             required: "Extension Period is required",
//                             min: 1,
//                           }}
//                           render={({ field }) => (
//                             <TextField
//                               {...field}
//                               label="Extension Period"
//                               type="number"
//                               fullWidth
//                               error={
//                                 !!errors.extendedDuration?.[index]
//                                   ?.extensionPeriod
//                               }
//                               helperText={
//                                 errors.extendedDuration?.[index]
//                                   ?.extensionPeriod?.message
//                               }
//                             />
//                           )}
//                         />
//                       </Grid>
//                       <Grid item xs={4}>
//                         <IconButton
//                           onClick={() => removeExtendedDuration(index)}
//                           aria-label="delete"
//                         >
//                           <DeleteIcon />
//                         </IconButton>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 ))}
//               {isExtensionAllowed && (
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={() => appendExtendedDuration({})}
//                   startIcon={<AddIcon />}
//                   size="small"
//                 >
//                   Add Extended Duration
//                 </Button>
//               )}
//             </Grid>

//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={isAutoPassAllowed}
//                     onChange={(e) => {
//                       setIsAutoPassAllowed(e.target.checked);
//                       setValue("autoPassAllowed", e.target.checked);
//                       if (!e.target.checked) {
//                         setValue("autoPassDuration", "");
//                       }
//                     }}
//                   />
//                 }
//                 label="Auto Pass Allowed"
//               />
//               {isAutoPassAllowed && (
//                 <Controller
//                   name="autoPassDuration"
//                   control={control}
//                   rules={{
//                     required: "Auto Pass Duration is required",
//                     min: 1,
//                   }}
//                   render={({ field }) => (
//                     <TextField
//                       {...field}
//                       label="Auto Pass Duration"
//                       type="number"
//                       fullWidth
//                       error={!!errors.autoPassDuration}
//                       helperText={errors.autoPassDuration?.message}
//                     />
//                   )}
//                 />
//               )}
//             </Grid>

//             {/* Incorrect Penalty */}
//             <Grid item xs={12}>
//               <Typography variant="h6" gutterBottom>
//                 Incorrect Penalty
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={6}>
//                   <Controller
//                     name="incorrectPenalty.penalty"
//                     control={control}
//                     rules={{
//                       required: "Penalty is required",
//                       min: 0,
//                     }}
//                     render={({ field }) => (
//                       <TextField
//                         {...field}
//                         label="Penalty"
//                         type="number"
//                         fullWidth
//                         error={!!errors.incorrectPenalty?.penalty}
//                         helperText={errors.incorrectPenalty?.penalty?.message}
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Controller
//                     name="incorrectPenalty.period"
//                     control={control}
//                     rules={{
//                       required: "Penalty Period is required",
//                       min: 1,
//                     }}
//                     render={({ field }) => (
//                       <TextField
//                         {...field}
//                         label="Penalty Period"
//                         type="number"
//                         fullWidth
//                         error={!!errors.incorrectPenalty?.period}
//                         helperText={errors.incorrectPenalty?.period?.message}
//                       />
//                     )}
//                   />
//                 </Grid>
//               </Grid>
//             </Grid>

//             {/* Review Rewards */}
//             <Grid item xs={12}>
//               <Typography variant="h6" gutterBottom>
//                 Review Rewards
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={6}>
//                   <Controller
//                     name="reviewRewards.reward"
//                     control={control}
//                     rules={{
//                       required: "Reward is required",
//                       min: 0,
//                     }}
//                     render={({ field }) => (
//                       <TextField
//                         {...field}
//                         label="Reward"
//                         type="number"
//                         fullWidth
//                         error={!!errors.reviewRewards?.reward}
//                         helperText={errors.reviewRewards?.reward?.message}
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Controller
//                     name="reviewRewards.period"
//                     control={control}
//                     rules={{
//                       required: "Reward Period is required",
//                       min: 1,
//                     }}
//                     render={({ field }) => (
//                       <TextField
//                         {...field}
//                         label="Reward Period"
//                         type="number"
//                         fullWidth
//                         error={!!errors.reviewRewards?.period}
//                         helperText={errors.reviewRewards?.period?.message}
//                       />
//                     )}
//                   />
//                 </Grid>
//               </Grid>
//             </Grid>

//             <Grid item xs={3} style={{ marginTop: "16px" }}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 disabled={isLoadingAdd}
//               >
//                 {isLoadingAdd ? "Saving..." : "Save"}
//               </Button>
//               {isErrorAdd && (
//                 <Typography color="error" variant="body2">
//                   Error saving data
//                 </Typography>
//               )}
//             </Grid>
//           </Grid>
//         </form>
//       </div>
//     </Paper>
//   );
// };

