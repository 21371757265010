

import React, { useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
  Grid,
  Box,
  Typography, // Import Typography for the static label
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedAlgo } from "../../../ducks/exams/feedAlgoSlice";
import { useCreateFeedAlgoMutation } from "../../../services/apis/feedAlgoApi";

const AddFeed = ({ addFeed, handleClose }) => {
  const [feedType, setFeedType] = useState("");
  const [algoType, setAlgoType] = useState("");
  const [engagement, setEngagement] = useState(false);
  const [engagementParameters, setEngagementParameters] = useState([
    {
      title: "reactionCount",
      weightage: 0,
    },
    {
      title: "commentCount",
      weightage: 0,
    },
    {
      title: "shareCount",
      weightage: 0,
    },
    {
      title: "reportCount",
      weightage: 0,
    },
    {
      title: "followCount",
      weightage: 0,
    },
  ]);
  const [content, setContent] = useState(false);
  const [contentParameters, setContentParameters] = useState([
    {
      title: "text",
      weightage: 0,
    },
    {
      title: "audio",
      weightage: 0,
    },
    {
      title: "video",
      weightage: 0,
    },
  ]);

  const [interest, setInterest] = useState(false);
  const [interestWeightage, setInterestWeightage] = useState(0);

  const [category, setCategory] = useState(false);
  const [categoryParameters, setCategoryParameters] = useState([
    {
      title: "boosted",
      weightage: 0,
    },
    {
      title: "promoted",
      weightage: 0,
    },
    {
      title: "sponsored",
      weightage: 0,
    },
  ]);


  const dispatch = useDispatch();
  const [createFeedAlgo] = useCreateFeedAlgoMutation();

  const handleSave = async () => {
    const newFeed = {
      id: Date.now(),
      content,
      feedType,
      algoType,
      engagement,
      engagementParameters,
      contentParameters,
      interest,
      interestWeightage,
      category,
      categoryParameters
    };
    addFeed(newFeed);

    try {
      await createFeedAlgo({
        feedType,
        algoType,
        newFeed,
      }).unwrap();

      dispatch(setFeedAlgo(newFeed));
    } catch (err) {
      console.error("Failed to create feed algorithm:", err);
    }

    handleClose();
  };

  const handleWeightChange = (updateFunction, index, event) => {
    let updatedValues;
  
    // Determine which state to update based on the updateFunction
    if (updateFunction === setEngagementParameters) {
      updatedValues = [...engagementParameters];
    } else if (updateFunction === setContentParameters) {
      updatedValues = [...contentParameters];
    } else if (updateFunction === setCategoryParameters) {
      updatedValues = [...categoryParameters];
    } else {
      return; // No valid updateFunction
    }
  
    // Update the weightage value
    updatedValues[index].weightage = parseFloat(event.target.value) || 0;
  
    // Apply the update to the appropriate state
    if (updateFunction === setEngagementParameters) {
      setEngagementParameters(updatedValues);
    } else if (updateFunction === setContentParameters) {
      setContentParameters(updatedValues);
    } else if (updateFunction === setCategoryParameters) {
      setCategoryParameters(updatedValues);
    }
  };
  


  return (
    <Box p={5} width="1200px" maxWidth="100%" mx="auto">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Feed Type</InputLabel>
            <Select value={feedType} onChange={(e) => setFeedType(e.target.value)}>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="group">Group</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel>Algo Type</InputLabel>
            <Select value={algoType} onChange={(e) => setAlgoType(e.target.value)}>
              <MenuItem value="post">Post</MenuItem>
              {/* <MenuItem value="comment">Comment</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={engagement}
                  onChange={(e) => setEngagement(e.target.checked)}
                />
              }
              label="Engagement"
            />
            {engagement &&
              engagementParameters.map((value, index) => (
                <Grid container spacing={1} key={index} alignItems="center" marginBottom={"10px"}>
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {value.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Weightage"
                      name="weightage"
                      type="number"
                      value={value.weightage}
                      onChange={(e) => handleWeightChange(setEngagementParameters, index, e)}
                      InputProps={{
                        style: {
                          height: "40px",
                          padding: "8px",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>

              ))}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={content}
                  onChange={(e) => setContent(e.target.checked)}
                />
              }
              label="Content"
            />
            {content &&
              contentParameters.map((value, index) => (
                <Grid container spacing={1} key={index} alignItems="center" marginBottom={"10px"}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {value.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Weightage"
                    name="weightage"
                    type="number"
                    value={value.weightage}
                    onChange={(e) => handleWeightChange(setContentParameters, index, e)}
                    InputProps={{
                      style: {
                        height: "40px",
                        padding: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              ))}
          </Grid>



          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={interest}
                  onChange={(e) => setInterest(e.target.checked)}
                />
              }
              label="Interest"
            />
            {interest && (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    label="Interest Weightage"
                    name="weightage"
                    type="number"
                    value={interestWeightage}
                    onChange={(e) =>
                      setInterestWeightage(parseFloat(e.target.value) || 0)
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>



          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={category}
                  onChange={(e) => setCategory(e.target.checked)}
                />
              }
              label="Category"
            />
            {category &&
              categoryParameters.map((value, index) => (
                <Grid container spacing={1} key={index} alignItems="center" marginBottom={"10px"}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {value.title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Weightage"
                    name="weightage"
                    type="number"
                    value={value.weightage}
                    onChange={(e) => handleWeightChange(setCategoryParameters, index, e)}
                    InputProps={{
                      style: {
                        height: "40px",
                        padding: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              ))}
          </Grid>
        </Grid>
      </Box>

      <Box mt={2} display="flex" justifyContent="flex-start">
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          style={{ marginRight: "8px" }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AddFeed;



