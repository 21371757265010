import React from "react";
import Header from "../../../common/header";
import OnboardingList from "./onboardingList";

function Onboarding() {
  return (
    <div className="h-screen">
      <Header content={"Manage Onboarding"} />
      <div className="bg-white p-3 m-2 rounded-md">
        <OnboardingList />
      </div>
    </div>
  );
}

export default Onboarding;
