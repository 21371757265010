
// import React, { useEffect, useState } from "react";
// import {
//   Stack,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import { MoreVertical, Square } from "lucide-react";
// import GrowthGraph from "./growthGraph";

// const Growth = ({ growth }) => {
//   const [selectedExams, setSelectedExams] = useState([]);
//   const [age, setAge] = React.useState("");


//   const [filteredGrowth, setFilteredGrowth] = useState(growth);

//   useEffect(() => {
//     const filterGrowth = (days) => {
//       const now = new Date();
//       const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
//       return growth.result.filter((item) => {
//         const itemDate = new Date(item.date);
//         return itemDate >= daysAgo;
//       });
//     };

//     if (age) {
//       setFilteredGrowth({
//         ...growth,
//         result: filterGrowth(age),
//       });
//     } else {
//       setFilteredGrowth(growth);
//     }
//   }, [age, growth]);
// // console.log(growth);



//   const handleExamChange = (event) => {
//     setSelectedExams(event.target.value);
//   };

//   const handleAgeChange = (event) => {
//     setAge(event.target.value);
//   };

//   const examColors = {
//     CAT: "#8884d8", // Blue
//     NMAT: "#24B670", // Green
//     XAT: "#FCB461", // Yellow
//     // GRE: "#E56C51", // Red
//   };

//   return (
//     <>
//       <div className="flex justify-between items-center">
//         <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
//           User Growth Over Time
//         </Typography>
//         <Stack
//           direction={"row"}
//           spacing={3}
//           justifyContent={"center"}
//           alignItems={"center"}
//         >
//           <FormControl>
//             <Select
//               multiple
//               value={selectedExams}
//               onChange={handleExamChange}
//               displayEmpty
//               renderValue={(selected) => {
//                 if (selected.length === 0) {
//                   return <em>Exam</em>;
//                 }
//                 return selected.join(", ");
//               }}
//               sx={{ width: "170px", height: "37px" }}
//             >
//               <MenuItem disabled value="">
//                 <em>Exam</em>
//               </MenuItem>
//               <MenuItem value={"CAT"}>CAT</MenuItem>
//               <MenuItem value={"NMAT"}>NMAT</MenuItem>
//               <MenuItem value={"XAT"}>XAT</MenuItem>
//               {/* <MenuItem value={"GRE"}>GRE</MenuItem> */}
//             </Select>
//           </FormControl>
//           <FormControl>
//             <Select
//               value={age}
//               onChange={handleAgeChange}
//               displayEmpty
//               renderValue={(selected) => {
//                 if (selected.length === 0) {
//                   return <em>Past One Month</em>;
//                 }
//                 return selected;
//               }}
//               sx={{ width: "170px", height: "37px" }}
//             >
//               <MenuItem disabled value="">
//                 <em>Past One Month</em>
//               </MenuItem>
//               <MenuItem value={10}>Ten</MenuItem>
//               <MenuItem value={20}>Twenty</MenuItem>
//               <MenuItem value={30}>Thirty</MenuItem>
//             </Select>
//           </FormControl>
//           <MoreVertical />
//         </Stack>
//       </div>
//       <Stack
//         spacing={2}
//         direction={"row"}
//         justifyContent={"flex-end"}
//         sx={{ marginTop: "10px", marginRight: "10px" }}
//       >
//         {selectedExams.map((exam) => (
//           <React.Fragment key={exam}>
//             <Square fill={examColors[exam]} strokeWidth={0} />
//             <Typography variant="body1">{exam}</Typography>
//           </React.Fragment>
//         ))}
//       </Stack>
//       <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
//         <div>
//           <h2 className=" text-xl font-bold">998 Users</h2>
//           <p className="mt-3 text-sm">
//             Top Exam:{" "}
//             <span>
//               {selectedExams.length > 0 ? selectedExams.join(", ") : "None"}
//             </span>
//           </p>
//         </div>
//         <div className=" ml-3 h-100 ">
//           <GrowthGraph selectedExams={selectedExams} growth={filteredGrowth} />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Growth;



import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { MoreVertical, Square } from "lucide-react";
import GrowthGraph from "./growthGraph";

const Growth = ({ growth }) => {
  const [selectedExams, setSelectedExams] = useState([]);
  const [days, setDays] = useState(30); // Default to 30 days
  const [filteredGrowth, setFilteredGrowth] = useState([]);

  useEffect(() => {
    const filterGrowth = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
      if(growth&&growth.formattedResult)
      {return growth.formattedResult.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= daysAgo;
      });}
    };

    setFilteredGrowth(filterGrowth(days));
  }, [days, growth]);

  const handleExamChange = (event) => {
    setSelectedExams(event.target.value);
  };

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  const examColors = {
    CAT: "#8884d8", // Blue
    NMAT: "#24B670", // Green
    XAT: "#FCB461", // Yellow
    // GRE: "#E56C51", // Red
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
          User Growth Over Time
        </Typography>
        <Stack
          direction={"row"}
          spacing={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FormControl>
            <Select
              multiple
              value={selectedExams}
              onChange={handleExamChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Exam</em>;
                }
                return selected.join(", ");
              }}
              sx={{ width: "170px", height: "37px" }}
            >
              <MenuItem disabled value="">
                <em>Exam</em>
              </MenuItem>
              <MenuItem value={"CAT"}>CAT</MenuItem>
              <MenuItem value={"NMAT"}>NMAT</MenuItem>
              <MenuItem value={"XAT"}>XAT</MenuItem>
              {/* <MenuItem value={"GRE"}>GRE</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              value={days}
              onChange={handleDaysChange}
              displayEmpty
              sx={{ width: "170px", height: "37px" }}
            >
              <MenuItem value={30}>Past One Month</MenuItem>
              <MenuItem value={20}>Past 20 Days</MenuItem>
              <MenuItem value={10}>Past 10 Days</MenuItem>
            </Select>
          </FormControl>
          <MoreVertical />
        </Stack>
      </div>
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ marginTop: "10px", marginRight: "10px" }}
      >
        {selectedExams.map((exam) => (
          <React.Fragment key={exam}>
            <Square fill={examColors[exam]} strokeWidth={0} />
            <Typography variant="body1">{exam}</Typography>
          </React.Fragment>
        ))}
      </Stack>
      <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
        <div>
          <h2 className=" text-xl font-bold">{growth?.totalOverallCount||0} Users</h2>
          <p className="mt-3 text-sm">
            Top Exam:{" "}
            <span>
              {selectedExams.length > 0 ? selectedExams.join(", ") : "None"}
            </span>
          </p>
        </div>
        <div className=" ml-3 h-100 ">
          <GrowthGraph selectedExams={selectedExams} growth={filteredGrowth||[]} />
        </div>
      </div>
    </>
  );
};

export default Growth;

