import React from "react";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import DownloadIcon from "@mui/icons-material/Download";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarRateIcon from "@mui/icons-material/StarRate";

const sections = [
  { label: "Active Users", icon: <PeopleIcon />, value: 788 },
  { label: "App Install", icon: <DownloadIcon />, value: 784 },
  { label: "Top Location", icon: <LocationOnIcon />, value: 1994 },
  { label: "Avg Age Group", icon: <CalendarTodayIcon />, value: "18-24" },
  { label: "Hours Engaged", icon: <AccessTimeIcon />, value: 794 },
  { label: "Avg Rating", icon: <StarRateIcon />, value: 4.0 },
];

function DashboardSidebar({ activeSection, onSectionChange }) {
  return (
    <div className="w-full p-6">
      <p style={{ fontWeight: 700, color: "#314259" }}>Today</p>
      <List className="flex flex-row justify-between overflow-auto space-x-5">
        {sections.map((section, index) => (
          <ListItem
            button
            key={section.label}
            onClick={() => onSectionChange(section.label)}
            sx={{
              border:
                activeSection === section.label ? "2px solid #1976D2" : "",
            }}
            className={`bg-white rounded-lg shadow-md p-2 m-2 flex flex-col w-20 `}
          >
            <div className="flex items-center justify-center mb-1">
              <div className="text-black-700 text-lg font-semibold mr-2">
                {section.value}
                {section.label === "Top Location" && " users"}
                {section.label === "Avg Age Group" && " years"}
                {section.label === "Hours Engaged" && " hours"}
              </div>
              <ListItemIcon className="min-w-0">{section.icon}</ListItemIcon>
            </div>
            <ListItemText
              primary={section.label}
              className="text-center text-sm"
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default DashboardSidebar;
