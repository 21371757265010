import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const GroupGraph = ({ totalUser = [], newUser = [] }) => {
  // Combine totalUser and newUser arrays into a single data array for the graph
  const combinedData = [];
  const dates = new Set([
    ...totalUser.map((item) => item.name),
    ...newUser.map((item) => item.name),
  ]);

  dates.forEach((date) => {
    const totalUserData = totalUser.find((item) => item.name === date);
    const newUserData = newUser.find((item) => item.name === date);

    combinedData.push({
      name: date,
      totaluser: totalUserData ? totalUserData.totaluser : 0,
      newuser: newUserData ? newUserData.newuser : 0,
    });
  });
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={combinedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            label={{
              value: "Users (in lakhs)",
              angle: -90,
              position: "insideLeft",
              dx: -5,
              dy: 30,
              style: { fontWeight: 600 },
            }}
          />
          <Tooltip />
          <Legend />

          <Line
            type="monotone"
            dataKey="newuser"
            stroke="#82ca9d"
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey="totaluser"
            stroke="#8884d8"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default GroupGraph;
