import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { scheduleApi } from "../Constant";

export const ScheduleApi = createApi({
    reducerPath: "ScheduleApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    tagTypes: ['getSchedule'],
    endpoints: (builder) => ({
        createSchedule: builder.mutation({
            query: ({ formData }) => {
                return {
                    url: `${scheduleApi.endPoint}/add`,
                    method: "POST",
                    body: formData,

                };
            },
            // invalidatesTags: ["getSchedule"],
        }),
        updateSchedule: builder.mutation({
            query: ({ formData,id }) => {
                return {
                    url: `${scheduleApi.endPoint}/update/${id}`,
                    method: "PATCH",
                    body: formData,

                };
            },
            // invalidatesTags: ["getSchedule"],
        }),

        getScheduleList: builder.query({
            query: (endpoint) => `${endpoint}`,
            providesTags: ["getScheduleList"],
        }),

        getScheduleById: builder.query({
            query: (endpoint) => `${endpoint}`,
            providesTags: [""],
        }),

        uploadMedia: builder.mutation({
            query: (formData) => {
                return {
                    url: `${scheduleApi.endPoint}/upload/image`,
                    method: 'POST',
                    body: formData,
                    headers: {
                        // Removing 'Content-Type' header lets the browser set the correct boundary
                    },
                };
            },
        }),

        statusSchedule: builder.mutation({
            query: ({ isPublished, id }) => {
              console.log(id);
      
              return {
                url: `${scheduleApi.endPoint}/status/${id}`,
                method: 'PATCH',
                body: {
                  isPublished
                },
      
              };
            },
          }),
    })
})

export const { useCreateScheduleMutation, useGetScheduleListQuery, useUploadMediaMutation, useGetScheduleByIdQuery,useStatusScheduleMutation,useUpdateScheduleMutation } = ScheduleApi