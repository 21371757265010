// import React, { useState } from 'react';
// import {
//   Card,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActions,
//   Divider,
//   Skeleton,
//   IconButton,
//   Modal,
//   Box,
//   Stack,
//   CircularProgress,
//   MenuItem, // Add MenuItem for dropdown
//   FormControl,
//   Select,
//   InputLabel
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { Empty } from 'antd';

// import { useGetOnboardingListQuery } from '../../../../services/apis/dataManagement/onboarding';
// import { onboardingApi } from '../../../../services/Constant';
// import { CustomButton, CustomButtonStyle, ButtonStyle } from '../../../../styles/muiRoot';
// import BootstrapTextField from '../../../common/bootstrapTextField';
// import {
//   useAddOnboardingMutation,
//   useUpdateOnboardingMutation,
// } from "../../../../services/apis/dataManagement/onboarding";
// import { message } from "antd";
// import { toast } from "react-toastify";

// function OnboardingList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const { details, isLoading, isError, isFetching, isSuccess, refetch } = useGetOnboardingListQuery(
//     `${onboardingApi.endPoint}/basic/list`,
//     {
//       refetchOnMountOrArgChange: true,
//       selectFromResult: ({ data, isLoading, isError }) => {
//         if (!isLoading && !isError && data) {
//           return { details: data.data };
//         }
//         return {};
//       }
//     }
//   );
//   const initialState = {
//     autoPlayDuration: 0,
//     media: null,
//     // isPublished:
//   };

//   const [addOnboarding] = useAddOnboardingMutation();

//   const [open, setOpen] = useState(false);
//   const [compType, setCompType] = useState("create");
//   const [values, setValues] = useState(initialState);
//   const [isAdding, setIsAdding] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);

//   const handleOpen = () => {
//     setCompType("create");
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // const handleFileChange = (event) => {
//   //   const file = event.target.files[0];
//   //   setValues({ ...values, file });
//   // };
//   const handleFileChange = (event) => {
//     const files = event.target.files; // Access all selected files
//     let uploadedFiles = [];

//     // Convert FileList to an array and store each file object
//     for (let i = 0; i < files.length; i++) {
//       uploadedFiles.push(files[i]);
//     }

//     setValues({ ...values, files: uploadedFiles }); // Store all selected files in state
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setValues({ ...values, [name]: value });
//   };

//   const handleCreate = async () => {
//     try {
//       setIsAdding(true);

//       const formData = new FormData();
//       formData.append("autoPlayDuration", values.autoPlayDuration);
//       // formData.append("media", values.file);
//       for (let i = 0; i < values.files.length; i++) {
//         formData.append("media", values.files[i]);
//       }

//       const response = await addOnboarding({
//         endpoint: `${onboardingApi.endPoint}/basic/add`,
//         data: formData,
//       });

//       if (response.data && response.data.success) {
//         handleClose();
//         message.success("Added successfully");
//         refetch(); // Assuming refetch is a function from useGetOnboardingListQuery
//       } else {
//         toast.error("Some error occurred while submitting the form!");
//       }
//     } catch (error) {
//       console.error("Error adding Onboarding content:", error);
//     } finally {
//       setIsAdding(false);
//     }
//   };

//   const handleUpdate = (id) => {
//     // Handle update if needed
//   };


//   if (isError) <Empty description=""
//   />

//   console.log("values", values);

//   return (
//     <div className="w-full h-screen">
//       <div className="bg-white p-3 m-2">
//         <div className="p-2 flex justify-between items-center">
//           <h2 className="text-xl font-inter font-semibold">All Onboarding Content</h2>
//           <CustomButton
//             sx={{ ...CustomButtonStyle, borderRadius: 2 }}
//             disabled={isLoading || isFetching}
//             onClick={handleOpen}
//           >
//             + Create Onboarding Content
//           </CustomButton>
//         </div>
//         <Divider />
//         <br />
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-[80vh] overflow-scroll">
//           {isLoading || isFetching ? (
//             Array.from({ length: 20 }).map((_, ind) => (
//               <div key={ind} className="skeleton-card">
//                 <Skeleton variant="rounded" width={"100%"} height={168} />
//                 <Skeleton height={78} />
//               </div>
//             ))
//           ) : isSuccess && details?.onboardingContent?.length === 0 ? (
//             <div className="flex justify-center items-center h-[80vh]">
//               <Empty description="No Data Found" className="text-center" />
//             </div>
//           ) : (
//             details?.onboardingContent?.map((card, index) => <MuiCard key={index} {...card} />)
//           )}
//         </div>
//       </div>
//       <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
//         <Box className="modalRoot max-h-[60vh] overflow-scroll">
//           <header className="ps-2 flex justify-between items-center bg-medGrey rounded-t-md">
//             <h4 className="text-xl font-inter font-semibold text-gray-700">
//               {compType === "create" ? "Create Onboarding Content" : "Edit Onboarding Content"}
//             </h4>
//             <IconButton onClick={handleClose}>
//               {/* <XIcon className="text-gray-700" /> */}
//             </IconButton>
//           </header>
//           <div className="w-[627px] mt-7 p-2 flex flex-col gap-4">
//             <BootstrapTextField
//               label="Auto Play Duration"
//               size="small"
//               value={values.autoPlayDuration}
//               onChange={handleChange}
//               name="autoPlayDuration"
//               type="number"
//             />

//             <div>
//               <h1 style={{ paddingBottom: "20px", fontWeight: "bold" }}>Upload File</h1>
//               <input id="file-upload" multiple type="file" onChange={handleFileChange} />
//             </div>
//             <Stack direction="row" justifyContent="flex-end" spacing={2} my={2}>
//               <CustomButton
//                 style={{
//                   ...ButtonStyle,
//                   width: 117,
//                   height: 39,
//                   borderRadius: 6,
//                 }}
//                 onClick={handleClose}
//               >
//                 Cancel
//               </CustomButton>
//               <CustomButton
//                 disabled={isAdding || isUpdating}
//                 style={{
//                   ...ButtonStyle,
//                   width: isAdding || isUpdating ? "fit-content" : 117,
//                   height: 39,
//                   borderRadius: 6,
//                 }}
//                 onClick={compType === "create" ? handleCreate : () => handleUpdate(values._id)}
//               >
//                 {isAdding || isUpdating ? (
//                   <CircularProgress size={18} color="inherit" />
//                 ) : compType === "create" ? (
//                   "Continue"
//                 ) : (
//                   "Save changes"
//                 )}
//               </CustomButton>
//             </Stack>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// const MuiCard = ({ _id, media, isPublished, resolution, size, autoPlayDuration, createdAt, updatedAt }) => {
//   return (
//     <Card sx={{ width: 345, height: "35ch" }}>
//       <CardMedia
//         component="img"
//         sx={{ height: "15ch" }}
//         image={media[0]} // Assuming there's always at least one media item
//         alt="Onboarding Content"
//       />
//       <CardContent className='relative'>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Published: {isPublished ? "Yes" : "No"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Resolution: {resolution}
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Size: {size} KB
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Auto Play Duration: {autoPlayDuration} seconds
//         </Typography>
//         {/* <Typography variant="body2" color="textSecondary" component="p">
//           Created At: {new Date(createdAt).toLocaleString()}
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Updated At: {new Date(updatedAt).toLocaleString()}
//         </Typography> */}
//       </CardContent>
//       <CardActions>
//         {/* Add actions if needed */}
//       </CardActions>
//     </Card>
//   );
// };

// export default OnboardingList;



//!Test

// import React, { useState } from 'react';
// import {
//   Card,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActions,
//   Divider,
//   Skeleton,
//   IconButton,
//   Modal,
//   Box,
//   Stack,
//   CircularProgress,
//   MenuItem, // Add MenuItem for dropdown
//   FormControl,
//   Select,
//   InputLabel
// } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { Empty } from 'antd';

// import { useGetOnboardingListQuery } from '../../../../services/apis/dataManagement/onboarding';
// import { onboardingApi } from '../../../../services/Constant';
// import { CustomButton, CustomButtonStyle, ButtonStyle } from '../../../../styles/muiRoot';
// import BootstrapTextField from '../../../common/bootstrapTextField';
// import {
//   useAddOnboardingMutation,
//   useUpdateOnboardingMutation,
// } from "../../../../services/apis/dataManagement/onboarding";
// import { message } from "antd";
// import { toast } from "react-toastify";
// import MultipleImageUpload from '../../../common/multipleImage';

// function OnboardingList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const { details, isLoading, isError, isFetching, isSuccess, refetch } = useGetOnboardingListQuery(
//     `${onboardingApi.endPoint}/basic/list`,
//     {
//       refetchOnMountOrArgChange: true,
//       selectFromResult: ({ data, isLoading, isError }) => {
//         if (!isLoading && !isError && data) {
//           return { details: data.data };
//         }
//         return {};
//       }
//     }
//   );
//   const initialState = {
//     autoPlayDuration: 0,
//     media: [],
//     // isPublished:
//   };

//   const [addOnboarding] = useAddOnboardingMutation();

//   const [open, setOpen] = useState(false);
//   const [compType, setCompType] = useState("create");
//   const [values, setValues] = useState(initialState);
//   const [isAdding, setIsAdding] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);

//   const handleOpen = () => {
//     setCompType("create");
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setValues({ ...values, [name]: value });
//   };

//   const handleCreate = async () => {
//     try {
//       setIsAdding(true);

//       const formData = new FormData();
//       formData.append("autoPlayDuration", values.autoPlayDuration);
//       formData.append("autoPlayDuration", values.autoPlayDuration);
//       values.media.forEach((file) => {
//         formData.append("media", file);
//       });

//       const response = await addOnboarding({
//         endpoint: `${onboardingApi.endPoint}/basic/add`,
//         data: formData,
//       });

//       if (response.data && response.data.success) {
//         handleClose();
//         message.success("Added successfully");
//         refetch(); // Assuming refetch is a function from useGetOnboardingListQuery
//       } else {
//         toast.error("Some error occurred while submitting the form!");
//       }
//     } catch (error) {
//       console.error("Error adding Onboarding content:", error);
//     } finally {
//       setIsAdding(false);
//     }
//   };

//   const handleUpdate = (id) => {
//     // Handle update if needed
//   };


//   if (isError) <Empty description=""
//   />

//   console.log("values", values);

//   return (
//     <div className="w-full h-screen">
//       <div className="bg-white p-3 m-2">
//         <div className="p-2 flex justify-between items-center">
//           <h2 className="text-xl font-inter font-semibold">All Onboarding Content</h2>
//           <CustomButton
//             sx={{ ...CustomButtonStyle, borderRadius: 2 }}
//             disabled={isLoading || isFetching}
//             onClick={handleOpen}
//           >
//             + Create Onboarding Content
//           </CustomButton>
//         </div>
//         <Divider />
//         <br />
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-[80vh] overflow-scroll">
//           {isLoading || isFetching ? (
//             Array.from({ length: 20 }).map((_, ind) => (
//               <div key={ind} className="skeleton-card">
//                 <Skeleton variant="rounded" width={"100%"} height={168} />
//                 <Skeleton height={78} />
//               </div>
//             ))
//           ) : isSuccess && details?.onboardingContent?.length === 0 ? (
//             <div className="flex justify-center items-center h-[80vh]">
//               <Empty description="No Data Found" className="text-center" />
//             </div>
//           ) : (
//             details?.onboardingContent?.map((card, index) => <MuiCard key={index} {...card} />)
//           )}
//         </div>
//       </div>
//       <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
//         <Box className="modalRoot max-h-[60vh] overflow-scroll">
//           <header className="ps-2 flex justify-between items-center bg-medGrey rounded-t-md">
//             <h4 className="text-xl font-inter font-semibold text-gray-700">
//               {compType === "create" ? "Create Onboarding Content" : "Edit Onboarding Content"}
//             </h4>
//             <IconButton onClick={handleClose}>
//               {/* <XIcon className="text-gray-700" /> */}
//             </IconButton>
//           </header>
//           <div className="w-[627px] mt-7 p-2 flex flex-col gap-4">
//             <BootstrapTextField
//               label="Auto Play Duration"
//               size="small"
//               value={values.autoPlayDuration}
//               onChange={handleChange}
//               name="autoPlayDuration"
//               type="number"
//             />

//             <div>
//               <h1 style={{ paddingBottom: "20px", fontWeight: "bold" }}>Upload File</h1>

//               <MultipleImageUpload
//                 setData={(val) => 
//                   handleChange({
//                     target: {
//                       name: "media",
//                       value: val
//                     }
//                   })


//                 }
//                 data={values?.media}
//                 endpoint={`admin/v1/onboardingContent/basic/upload/image`}
//                 circle={false}

//               />
//             </div>
//             <Stack direction="row" justifyContent="flex-end" spacing={2} my={2}>
//               <CustomButton
//                 style={{
//                   ...ButtonStyle,
//                   width: 117,
//                   height: 39,
//                   borderRadius: 6,
//                 }}
//                 onClick={handleClose}
//               >
//                 Cancel
//               </CustomButton>
//               <CustomButton
//                 disabled={isAdding || isUpdating}
//                 style={{
//                   ...ButtonStyle,
//                   width: isAdding || isUpdating ? "fit-content" : 117,
//                   height: 39,
//                   borderRadius: 6,
//                 }}
//                 onClick={compType === "create" ? handleCreate : () => handleUpdate(values._id)}
//               >
//                 {isAdding || isUpdating ? (
//                   <CircularProgress size={18} color="inherit" />
//                 ) : compType === "create" ? (
//                   "Continue"
//                 ) : (
//                   "Save changes"
//                 )}
//               </CustomButton>
//             </Stack>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// const MuiCard = ({ _id, media, isPublished, resolution, size, autoPlayDuration, createdAt, updatedAt }) => {
//   return (
//     <Card sx={{ width: 345, height: "35ch" }}>
//       <CardMedia
//         component="img"
//         sx={{ height: "15ch" }}
//         image={media[0]} // Assuming there's always at least one media item
//         alt="Onboarding Content"
//       />
//       <CardContent className='relative'>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Published: {isPublished ? "Yes" : "No"}
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Resolution: {resolution}
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Size: {size} KB
//         </Typography>
//         <Typography variant="body2" color="textSecondary" component="p">
//           Auto Play Duration: {autoPlayDuration} seconds
//         </Typography>

//       </CardContent>
//       <CardActions>
//         {/* Add actions if needed */}
//       </CardActions>
//     </Card>
//   );
// };

// export default OnboardingList;


import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Divider,
  Skeleton,
  IconButton,
  Modal,
  Box,
  Stack,
  CircularProgress,
  MenuItem, // Add MenuItem for dropdown
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Empty } from 'antd';

import { useGetOnboardingListQuery } from '../../../../services/apis/dataManagement/onboarding';
import { onboardingApi } from '../../../../services/Constant';
import { CustomButton, CustomButtonStyle, ButtonStyle } from '../../../../styles/muiRoot';
import BootstrapTextField from '../../../common/bootstrapTextField';
import {
  useAddOnboardingMutation,
  useUpdateOnboardingMutation,
} from "../../../../services/apis/dataManagement/onboarding";
import { message } from "antd";
import { toast } from "react-toastify";
import MultipleImageUpload from '../../../common/multipleImage';

function OnboardingList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { details, isLoading, isError, isFetching, isSuccess, refetch } = useGetOnboardingListQuery(
    `${onboardingApi.endPoint}/basic/list`,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, isLoading, isError }) => {
        if (!isLoading && !isError && data) {
          return { details: data.data };
        }
        return {};
      }
    }
  );
  const initialState = {
    autoPlayDuration: 0,
    media: [],
    // isPublished:
  };

  const [addOnboarding] = useAddOnboardingMutation();

  const [open, setOpen] = useState(false);
  const [compType, setCompType] = useState("create");
  const [values, setValues] = useState(initialState);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleOpen = () => {
    setCompType("create");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "media") {

      setValues({ ...values, media: value });
    } else {

      setValues({ ...values, [name]: value });
    }
  };


  const handleCreate = async () => {
    try {
      setIsAdding(true);
      const response = await addOnboarding({

        endpoint: `${onboardingApi.endPoint}/basic/add`,
        data: values,
      });
      if (response.data && response.data.success) {
        handleClose();
        message.success("Added successfully");
        refetch();
      } else {
        toast.error("Some error occurred while submitting the form!");
      }
    } catch (error) {
      console.error("Error adding Onboarding content:", error);
    } finally {
      setIsAdding(false);
    }
  };


  const handleUpdate = (id) => {
    // Handle update if needed
  };


  if (isError) <Empty description=""
  />

  console.log("values", values);

  return (
    <div className="w-full h-screen">
      <div className="bg-white p-3 m-2">
        <div className="p-2 flex justify-between items-center">
          <h2 className="text-xl font-inter font-semibold">All Onboarding Content</h2>
          <CustomButton
            sx={{ ...CustomButtonStyle, borderRadius: 2 }}
            disabled={isLoading || isFetching}
            onClick={handleOpen}
          >
            + Create Onboarding Content
          </CustomButton>
        </div>
        <Divider />
        <br />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-[80vh] overflow-scroll">
          {isLoading || isFetching ? (
            Array.from({ length: 20 }).map((_, ind) => (
              <div key={ind} className="skeleton-card">
                <Skeleton variant="rounded" width={"100%"} height={168} />
                <Skeleton height={78} />
              </div>
            ))
          ) : isSuccess && details?.onboardingContent?.length === 0 ? (
            <div className="flex justify-center items-center h-[80vh]">
              <Empty description="No Data Found" className="text-center" />
            </div>
          ) : (
            details?.onboardingContent?.map((card, index) => <MuiCard key={index} {...card} />)
          )}
        </div>
      </div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modalRoot max-h-[60vh] overflow-scroll">
          <header className="ps-2 flex justify-between items-center bg-medGrey rounded-t-md">
            <h4 className="text-xl font-inter font-semibold text-gray-700">
              {compType === "create" ? "Create Onboarding Content" : "Edit Onboarding Content"}
            </h4>
            <IconButton onClick={handleClose}>
              {/* <XIcon className="text-gray-700" /> */}
            </IconButton>
          </header>
          <div className="w-[627px] mt-7 p-2 flex flex-col gap-4">
            <BootstrapTextField
              label="Auto Play Duration"
              size="small"
              value={values.autoPlayDuration}
              onChange={handleChange}
              name="autoPlayDuration"
              type="number"
            />

            <div>
              <h1 style={{ paddingBottom: "20px", fontWeight: "bold" }}>Upload File</h1>

              <MultipleImageUpload
                setData={(val) =>
                  handleChange({
                    target: {
                      name: "media",
                      value: val
                    }
                  })


                }
                data={values?.media}
                endpoint={`admin/v1/onboardingContent/basic/upload/image`}
                circle={false}

              />
            </div>
            <Stack direction="row" justifyContent="flex-end" spacing={2} my={2}>
              <CustomButton
                style={{
                  ...ButtonStyle,
                  width: 117,
                  height: 39,
                  borderRadius: 6,
                }}
                onClick={handleClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                disabled={isAdding || isUpdating}
                style={{
                  ...ButtonStyle,
                  width: isAdding || isUpdating ? "fit-content" : 117,
                  height: 39,
                  borderRadius: 6,
                }}
                onClick={compType === "create" ? handleCreate : () => handleUpdate(values._id)}
              >
                {isAdding || isUpdating ? (
                  <CircularProgress size={18} color="inherit" />
                ) : compType === "create" ? (
                  "Continue"
                ) : (
                  "Save changes"
                )}
              </CustomButton>
            </Stack>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
const MuiCard = ({ _id, media, isPublished, resolution, size, autoPlayDuration, createdAt, updatedAt }) => {
  return (
    <Card sx={{ width: 345, height: "35ch" }}>
      <CardMedia
        component="img"
        sx={{ height: "15ch" }}
        image={media[0]} // Assuming there's always at least one media item
        alt="Onboarding Content"
      />
      <CardContent className='relative'>
        <Typography variant="body2" color="textSecondary" component="p">
          Published: {isPublished ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Resolution: {resolution}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Size: {size} KB
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Auto Play Duration: {autoPlayDuration} seconds
        </Typography>

      </CardContent>
      <CardActions>
        {/* Add actions if needed */}
      </CardActions>
    </Card>
  );
};


export default OnboardingList;


