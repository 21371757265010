import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";



export default function CategoryGraph({salesByCategory}) {



  const data = [
    {
      Cources: "CAT 2021",
      Sales: salesByCategory?.CAT,
    },
    {
      Cources: "TISS 2021",
      Sales: salesByCategory?.TISS,
    },
    {
      Cources: "NMAT Crash",
      Sales: salesByCategory?.NMAT,
    },
    {
      Cources: "XAT ",
      Sales: salesByCategory?.XAT,
    },
    // {
    //   Cources: "GRE Crash",
    //   Sales: 100,
    // },
    // {
    //   Cources: "Others",
    //   Sales: 130,
    // },
  ];
  return (
    <BarChart
      width={700}
      height={280}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Cources" />
      <YAxis
        label={{
          value: "Sales Units (in thousands)",
          angle: -90,
          position: "insideLeft",
          dx: -5,
          dy: 70,
          style: { fontWeight: 600 },
        }}
      />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="Sales"
        fill="#ffb24d"
        barSize={20}
        radius={[10, 10, 0, 0]}
      />
    </BarChart>
  );
}
