import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";

import CoinsGraph from "./coinsGraph";

const Coins = ({coin}) => {
  const [age, setAge] = useState(30); // Default to 30 days (one month)
  const [filteredBundle, setFilteredBundle] = useState(coin);
  // console.log(coin);


  useEffect(() => {
    const filterBundle = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

      return coin.map((item) => {
        const filteredSales = item.dailySales.filter((sales) => {
          const salesDate = new Date(sales.date);
          return salesDate >= daysAgo;
        });
        return { ...item, dailySales: filteredSales };
      });
    };

    setFilteredBundle(filterBundle(age));
  }, [age, coin]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="w-2/4 h-96   border border-#cfd0d1-50 rounded-md ml-4">
        <div>
          <div>
            <div className="flex  w-full text-center h-12 justify-between p-2">
              <h2 className=" text-lg font-bold">Coins Bundles Purchased</h2>
              <div className="mr-6">
                <Box sx={{ minWidth: 150 }} height="5px">
                  <FormControl fullWidth size="small">
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) =>
                        selected === 30 ? (
                          <em>Past one Month</em>
                        ) : (
                          `${selected} Days`
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Past one Month</em>
                      </MenuItem>
                      {/* <MenuItem value={"Past One Month"}>
                        Past one Month
                      </MenuItem> */}

                      <MenuItem value={30}>Thirty</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex justify-end -mr-7">
                  <MoreVertical />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div className="w-full ml-3 h-72">
            <CoinsGraph coin = {filteredBundle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Coins;
