import React, { Suspense, useDeferredValue, useState } from "react";
import { Paper, Button, Avatar, Menu, MenuItem, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { dateFormatting } from "../../../../services/common";
import InputWithIcon from "../../../common/searchBox";
import UseCustomRouter from "../../../../services/utilities/customRouter";
import { Skeleton } from "@mui/material";
import { CustomButton, ButtonStyle } from "../../../../styles/muiRoot";
import SearchField from "../../../common/searchField";
import TruncateText from "../../../common/FunctionComponents/truncate";
import { useStatusSubjectMutation } from "../../../../services/apis/dataManagement/subject";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F3FE",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SubjectList({
  data,
  comp,
  columns,
  placeholder,
  path,
  loading,
  editFun,
}) {
  const [filteredData, setFilteredData] = React.useState(data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { navigate } = UseCustomRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // New state to store selected row
  const open = Boolean(anchorEl);
  const [status, setStatus] = useState(false);
  const [stats] = useStatusSubjectMutation()


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  const statusChange = async () => {
    if (selectedRow) {
      try {
        // Perform the mutation and wait for it to complete
        await stats({
          isPublished: !selectedRow.isPublished, // Toggle the published status
          id: selectedRow._id,
        }).unwrap();

        // If the mutation is successful, trigger a re-render by updating the filteredData
        const updatedData = filteredData.map((row) =>
          row._id === selectedRow._id ? { ...row, isPublished: !row.isPublished } : row
        );

        setFilteredData(updatedData); // Update the state with the new data

      } catch (error) {
        console.error("Failed to update status:", error);
      }
    }
    handleClose();
  };


  const EditChange = () => {
    editFun(selectedRow);
    setAnchorEl(null); // Close the menu after action
  };

  const handleClick = (event, row) => {
    event.stopPropagation(); // Prevent triggering row click event
    setAnchorEl(event.currentTarget);
    setSelectedRow(row); // Store the selected row data when the button is clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };


  return (
    <>
      <div className="mb-2 flex justify-between items-center gap-4 sticky top-0  ">
        <div className="basis-1/3">
          <SearchField
            data={data}
            onFilter={(val) => setFilteredData(val)}
            searchBy={"title"}
            placeholder={placeholder}
          />
        </div>
        <div className="basis-auto">{comp}</div>
      </div>
      <Suspense fallback={<Skeleton active />}>
        {loading ? (
          <TableContainer sx={{ height: "75vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <StyledTableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Skeleton />
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: rowsPerPage }).map((_, index) => (
                  <StyledTableRow key={index}>
                    {columns.map((column, ind) => (
                      <StyledTableCell key={ind} align={column.align}>
                        <Skeleton />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer sx={{ height: "75vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <StyledTableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align={"right"} sx={{ pr: 3 }}>
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, ind) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={ind}
                        className="cursor-pointer"
                        onClick={() => navigate(`${path}/${row._id}`)}
                      >
                        {columns?.map((column, ind) => {
                          let value;
                          if (column.type === "date") {
                            value = dateFormatting(row[column.dataKey]).date;
                          } else if (Array.isArray(row[column.dataKey])) {
                            value = row[column.dataKey]?.[0]?.title;
                          } else if (column.dataKey === "isPublished") {
                            value = row.isPublished ? "Publish" : "Unpublish";
                          } else if (column.type === "truncate") {
                            value = (
                              <TruncateText
                                text={row[column.dataKey]}
                                maxLength={80}
                              />
                            );
                          } else {
                            value = row[column.dataKey];
                          }
                          return (
                            <StyledTableCell
                              key={column.dataKey}
                              align={column.align}
                              width={column.maxWidth}
                            >
                              {ind === 0 ? (
                                <div className="flex items-center gap-2">
                                  <Avatar
                                    sx={{ height: 40, width: 40 }}
                                    src={row["thumbnail"]}
                                  />
                                  {value}
                                </div>
                              ) : (
                                value
                              )}
                            </StyledTableCell>
                          );
                        })}
                        <StyledTableCell align={"right"}>
                          {/* <CustomButton
                            onClick={() => editFun(row)}
                            sx={{
                              ...ButtonStyle,
                              width: "fit-content",
                              height: "max-content",
                              borderRadius: 2,
                            }}
                          >
                            Edit
                          </CustomButton> */}
                          <CustomButton
                            sx={{
                              ...ButtonStyle,
                              width: "fit-content",
                              height: "max-content",
                              borderRadius: 2,
                            }}
                            id="demo-positioned-button"
                            aria-controls={
                              open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(event) => handleClick(event, row)}
                          >
                            ...
                          </CustomButton>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Lighter and less intense shadow
                                border: "1px solid #ddd", // Light border
                              },
                            }}
                          >
                            <MenuItem onClick={EditChange}>Edit</MenuItem>
                            <MenuItem onClick={statusChange}>
                              <Typography variant="body1">
                                {status ? "PUBLISH" : "UNPUBLISH"}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Suspense>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
