//!testing2
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import GrowthGraph from "./growthGraph";
import { useGetCoursesAnalyticsQuery } from "../../../../../../services/apis/exam/courses";
import { courseApi } from "../../../../../../services/Constant";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const Growth = () => {
  const params = useParams();
  const [filterType, setFilterType] = useState(30);
  const { data, isLoading, isSuccess } = useGetCoursesAnalyticsQuery(
    `${courseApi.endPoint}/analysis/growth/${params.examId}/?days=${filterType}`
  );
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalNewUsers, setTotalNewUsers] = useState(0);
  const [totalUserArray, setTotalUserArray] = useState([]);
  const [newUserArray, setNewUserArray] = useState([]);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };

  useEffect(() => {
    if (isSuccess && data) {
      const totalUserArray = data.data.totalUsersCountByDay.map((item) => ({
        name: new Date(item.date).getDate(),
        totaluser: item.count,
      }));
      const newUserArray = data.data.newUsersCountByDay.map((item) => ({
        name: new Date(item.date).getDate(),
        newuser: item.count,
      }));

      setTotalUserArray(totalUserArray);
      setNewUserArray(newUserArray);

      const totalUsersCount = data.data.totalUsersCountByDay.reduce(
        (acc, curr) => acc + curr.count,
        0
      );
      const totalNewUsersCount = data.data.newUsersCountByDay.reduce(
        (acc, curr) => acc + curr.count,
        0
      );

      setTotalUsers(totalUsersCount);
      setTotalNewUsers(totalNewUsersCount);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess || !data) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <>
      <div className="flex justify-between flex-col border border-#cfd0d1-50 rounded-md w-2/4">
        <div className="flex w-full text-center h-12 justify-between p-2">
          <h2 className="text-lg font-bold">User Growth</h2>
          <div className="mr-6">
            <Box sx={{ minWidth: 150 }} height="5px">
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterType}
                  onChange={handleFilterChange}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length === 0 ? (
                      <em>This Week</em>
                    ) : (
                      filterTypeLabels[selected]
                    )
                  }
                  size="small"
                >
                  <MenuItem value={7}>
                    <em>This Week</em>
                  </MenuItem>
                  <MenuItem value={30}>Month</MenuItem>
                  <MenuItem value={90}>3 Month</MenuItem>
                  <MenuItem value={180}>6 Month</MenuItem>
                  <MenuItem value={365}>1 Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <div className="flex justify-end -mr-7">
              <MoreVertical />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex p-2 w-1/5">
            <div className="p-2 flex flex-col">
              <div className="p-1">
                <h3 className="font-bold text-xl">{totalUsers}</h3>
                <p>Total users</p>
              </div>
              <div className="p-1 mt-5">
                <h3 className="font-bold text-xl">{totalNewUsers}</h3>
                <p>Total New users</p>
              </div>
            </div>
          </div>
          <div className="p-3 w-full h-80">
            <GrowthGraph totalUser={totalUserArray} newUser={newUserArray} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Growth;
