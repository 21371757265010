import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Search, MoreVertical } from "lucide-react";
import { useGetGroupAnalyticsQuery } from "../../../../../../../services/apis/exam/group";
import { groupApi } from "../../../../../../../services/Constant";
import { useParams } from "react-router-dom";

import Map from "./map";
import Counter from "./counter";

const Years = () => {
  const [filterType, setFilterType] = useState(30);
  const [selectedCard, setSelectedCard] = useState(null);
  const [rangeData, setRangeData] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const params = useParams();
  const { data, isLoading, isError } = useGetGroupAnalyticsQuery(
    `${groupApi.endPoint}/analytics/list/${params.groupId}/experience?days=${filterType}&minAge=10&maxAge=30`
  );

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleCardClick = (id) => {
    setSelectedCard(id);
    setSelectedState(null);
  };

  const handleMapClick = (state) => {
     alert("map click props");
     console.log(state, "16");
    console.log("State clicked in Years.js:", state);
    setSelectedState(state);
  };

  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      const formattedData = data.data.map((item, index) => ({
        id: index + 1,
        title: item.workExperienceRange,
        users: item.stateCounts.reduce((sum, state) => sum + state.count, 0),
        stateCounts: item.stateCounts,
      }));
      setRangeData(formattedData);
    }
  }, [data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError || !data || !Array.isArray(data.data)) {
    console.error("Error loading data:", isError);
    return <Typography>Error loading data</Typography>;
  }

  const selectedCardData = rangeData.find((ele) => ele.id === selectedCard);

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 600, marginLeft: "10px" }}
        >
          User Work Experience by Location
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Search />
          <Typography
            variant="subtitle2"
            style={{ color: "blue", fontWeight: 500 }}
          >
            Views State Ranking
          </Typography>
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterType}
              onChange={handleFilterChange}
              displayEmpty
              renderValue={(selected) =>
                selected.length === 0 ? (
                  <em>This Week</em>
                ) : (
                  filterTypeLabels[selected]
                )
              }
              size="small"
            >
              {Object.entries(filterTypeLabels).map(([key, value]) => (
                <MenuItem key={key} value={parseInt(key)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MoreVertical />
        </Stack>
      </Stack>
      <Typography variant="body1" sx={{ marginTop: "2%", marginLeft: "10px" }}>
        Work Experience
      </Typography>
      <Box display="flex" justifyContent="center">
        <Grid container spacing={0} p={4} m={2}>
          {rangeData.map((ele) => (
            <Grid item xs={4} key={ele.id}>
              <Card
                sx={{
                  width: "220px",
                  height: "120px",
                  border:
                    selectedCard === ele.id
                      ? "2px solid #24B670"
                      : "1px solid #EAEAEC",
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                onClick={() => handleCardClick(ele.id)}
              >
                <CardContent>
                  <Typography variant="h5" style={{ fontWeight: 600 }}>
                    {ele.title} yrs
                  </Typography>
                  <Typography variant="body1">{ele.users} users</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box sx={{ p: 1, width: "100%" }}>
              <Counter experienceData={rangeData} />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            flexBasis: "45%",
            position: "relative",
            height: "80vh",
            bottom: 70,
          }}
        >
          <Map
            selectedCardData={selectedCardData}
            handleMapClick={handleMapClick}
            selectedState={selectedState}
          />
        </div>
      </Box>
    </>
  );
};

export default Years;





