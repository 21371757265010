

import React, { useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RevenueGraph = ({ showComparison, revenue }) => {


  
  // console.log(revenue.result);
  let result = revenue?.result




  let d = result.map((val) => {
    const dateString = val.date;
    const date = new Date(dateString);

    const options = { day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    // console.log(formattedDate); // Output: "7 Aug"
    return {
      name: formattedDate,
      TotalRevenue: val.dailyTotalPaidAmount,
      Bundle: val.bundle,
      Courses: val.course

    }
  })


 

  // const data = [
  //   {
  //     name: 3,
  //     TotalRevenue: 80,
  //     Mocktest: 45,
  //     "Practice Paper": 30,
  //     Learn: 35,
  //     Courses: 40,
  //   },
  //   {
  //     name: 9,
  //     TotalRevenue: 80,
  //     Mocktest: 35,
  //     "Practice Paper": 25,
  //     Learn: 20,
  //     Courses: 45,
  //   },
  //   {
  //     name: 12,
  //     TotalRevenue: 90,
  //     Mocktest: 30,
  //     "Practice Paper": 20,
  //     Learn: 25,
  //     Courses: 35,
  //   },
  //   {
  //     name: 15,
  //     TotalRevenue: 100,
  //     Mocktest: 25,
  //     "Practice Paper": 15,
  //     Learn: 15,
  //     Courses: 30,
  //   },
  //   {
  //     name: 18,
  //     TotalRevenue: 100,
  //     Mocktest: 20,
  //     "Practice Paper": 10,
  //     Learn: 10,
  //     Courses: 25,
  //   },
  //   {
  //     name: 21,
  //     TotalRevenue: 80,
  //     Mocktest: 30,
  //     "Practice Paper": 25,
  //     Learn: 20,
  //     Courses: 30,
  //   },
  //   {
  //     name: 24,
  //     TotalRevenue: 115,
  //     Mocktest: 105,
  //     "Practice Paper": 80,
  //     Learn: 95,
  //     Courses: 100,
  //   },
  //   {
  //     name: 27,
  //     TotalRevenue: 110,
  //     Mocktest: 100,
  //     "Practice Paper": 75,
  //     Learn: 90,
  //     Courses: 95,
  //   },
  // ];



  return (
    <ResponsiveContainer width="100%" height={290}>
      <ComposedChart
        data={d}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "Rupees (in Lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -5,
            dy: 90,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="TotalRevenue"
          stroke="#82ca9d"
          strokeWidth={4}
        />
        {showComparison && (
          <>
            {/* <Bar
              dataKey="Mocktest"
              fill="#6185f0"
              radius={[10, 10, 0, 0]}
              barSize={6}
            /> */}
            {/* <Bar
              dataKey="Practice Paper"
              fill="#74788d"
              radius={[10, 10, 0, 0]}
              barSize={6}
            /> */}
            {/* <Bar
              dataKey="Learn"
              fill="#f9c13c"
              radius={[10, 10, 0, 0]}
              barSize={6}
            /> */}
            <Bar
              dataKey="Bundle"
              fill="#f9c13c"
              radius={[10, 10, 0, 0]}
              barSize={6}
            />
            <Bar
              dataKey="Courses"
              fill="#eb5757"
              radius={[10, 10, 0, 0]}
              barSize={6}
            />
          </>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default RevenueGraph;
