import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const AppInstallGraph = () => {
  const data = [
    {
      name: 3,
      TotalInstall: 50,
      TotalUninstall: 70,
    },
    {
      name: 9,
      TotalInstall: 60,
      TotalUninstall: 90,
    },
    {
      name: 12,
      TotalInstall: 70,
      TotalUninstall: 95,
    },
    {
      name: 15,
      TotalInstall: 80,
      TotalUninstall: 95,
    },
    {
      name: 18,
      TotalInstall: 90,
      TotalUninstall: 110,
    },
    {
      name: 21,
      TotalInstall: 110,
      TotalUninstall: 100,
    },
    {
      name: 24,
      TotalInstall: 90,
      TotalUninstall: 110,
    },
    {
      name: 27,
      TotalInstall: 120,
      TotalUninstall: 120,
    },
  ];
  return (
    <>
      <LineChart
        width={1300}
        height={320}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "Users (in Lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -5,
            dy: 80,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />

        <Line
          type="monotone"
          dataKey="TotalInstall"
          stroke="#82ca9d"
          strokeWidth={4}
        />
        <Line
          type="monotone"
          dataKey="TotalUninstall"
          stroke="#5146D6"
          strokeWidth={4}
        />
      </LineChart>
    </>
  );
};

export default AppInstallGraph;
