import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function BarGraph({ performanceData, classType }) {
  console.log("pero",performanceData);
  const formattedData = performanceData.map((item) => ({
    Title: item.title,
    Date: new Date(item.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }),
    Host: item.host,
    Duration: `${Math.round(item.duration / 60)} mins`,
    Exam: item.topic,
    Enrolled: item.expectedParticipantsCount,
    Attendees: item.actualParticipantsCount,
    Time: formatTime(new Date(item.time || item.date)),
  }));

  function formatTime(date) {
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
console.log("formated",formattedData);
  const colorSets = {
    all: {
      Host: "#f2c299",
      Enrolled: "#f2c299",
      Attendees: "orange",
      Date: "#f2c299",
      Time: "#f2c299",
      Duration: "#f2c299",
    },
    appClass: {
      Host: "green",
      Enrolled: "blue",
      Attendees: "purple",
      Date: "green",
      Time: "purple",
      Duration: "blue",
    },
    liveClass: {
      Host: "red",
      Enrolled: "orange",
      Attendees: "yellow",
      Date: "red",
      Time: "yellow",
      Duration: "orange",
    },
  };

  const currentColorSet = colorSets[classType] || colorSets.all;

  return (
    <BarChart
      width={1180}
      height={600}
      data={formattedData}
      layout="vertical"
      margin={{ top: 20, right: 30, left: 90, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis
        dataKey="Exam"
        type="category"
        label={{
          value: "Class Name",
          angle: -90,
          position: "insideLeft",
          dx: -90,
          dy: -9,
        }}
      />
      <Tooltip />
      <Legend />
      {Object.keys(currentColorSet).map((key) => (
        <Bar
          key={key}
          dataKey={key}
          fill={currentColorSet[key]}
          barSize={20}
          stackId="a"
          radius={[0, 10, 10, 0]}
        />
      ))}
    </BarChart>
  );
}

















