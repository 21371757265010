
import React from "react";
import { Stack, Typography } from "@mui/material";
import NavbarsGraph from "./navbarsGraph";

const Analytic = ({ userData, ageStart, ageEnd }) => {

  let maleCount = 0;
  let femaleCount = 0;
  let otherCount = 0;

 
  const graphData = userData?.data?.ageAndGender?.insights?.map((entry) => {
    if (entry) {
      maleCount += entry.male;
      femaleCount += entry.female;
      otherCount += entry.others;
      return {
        date: entry.date,
        male: entry.male,
        female: entry.female,
        others: entry.others,
      };
    }
    return null;
  });

 
  if (!graphData || graphData.length === 0) {
    return null; 
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{ marginTop: "30px" }}
      >
        <Stack direction="column" spacing={1} padding="10px">
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {ageStart} - {ageEnd}
          </Typography>
          <Typography variant="subtitle2">Selected Age Range</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {maleCount}
          </Typography>
          <Typography variant="subtitle2">Total Male</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {femaleCount}
          </Typography>
          <Typography variant="subtitle2">Total Female</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {otherCount}
          </Typography>
          <Typography variant="subtitle2">Total Others</Typography>
        </Stack>

        <NavbarsGraph graphData={graphData} />
      </Stack>
    </>
  );
};

export default Analytic;



