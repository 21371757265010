import React from "react";
import Container from "./container";

const DailyAnalyticMain = () => {
  return (
    <>
      <Container />
    </>
  );
};

export default DailyAnalyticMain;
