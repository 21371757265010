import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RevenueGraph = ({ salesData }) => {
  console.log(salesData);
  if (!salesData || salesData.length === 0) {
    return <div>No sales data available</div>;
  }

  const flattenedData = salesData.flatMap((course) =>
    course.sales.map((sale) => ({
      date: new Date(sale.date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
      totalPaidAmount: sale.totalPaidAmount,
    }))
  );

  console.log("Flattened Data", flattenedData);
  return (
    <>
      <LineChart
        width={500}
        height={290}
        data={flattenedData}
        margin={{
          top: 5,
          right: 20,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          label={{
            value: "Rupees (In Lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -3,
            dy: 80,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />

        <Line
          type="monotone"
          dataKey="totalPaidAmount"
          stroke="#82ca9d"
          strokeWidth={4}
        />
      </LineChart>
    </>
  );
};

export default RevenueGraph;
