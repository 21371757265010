// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
    tagTypes: ["getDashoardAnalytics"],
    endpoints: (builder) => ({

        getDashboardAnalytics: builder.query({
            query: (endpoint) => `${endpoint}`,
            providesTags: ["getDashoardAnalytics"],
        }),


    }),
});

export const {
    useGetDashboardAnalyticsQuery
} = dashboardApi;