import React from "react";
import Chart from "react-apexcharts";

const LineGraphs = ({ data }) => {
  console.log("lineGraph", data);
  const getMonthName = (dateStr) => {
    const dateObj = new Date(dateStr);
    return dateObj.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., "Jan", "Feb", etc.)
  };

  // Extracting month names and day from dates
  const dates = data.map((entry) => {
    const monthName = getMonthName(entry.date);
    const day = new Date(entry.date).getDate();
    return `${monthName} ${day}`;
  });
  const newUsers = data.map((entry) => entry.newUsers);


  const chartOptions = {
    series: [
      {
        name: "New User",
        data: newUsers,
      },
    ],
    chart: {
      height: 400,
      type: "line",
    },
    xaxis: {
      categories: dates,
    },
    yaxis: {
      title: {
        text: "Users (in lakh)",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
        offsetX: -6, // Adjust this value to add padding between the y-axis label and the y-axis
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        width: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    colors: ["#4a1cd6"],
  };

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={300}
        width={550}
      />
    </div>
  );
};

export default LineGraphs;

