import React from 'react'
import Container from './Container'
const MockAnalysis = () => {
  return (
    <>
    <Container/>
    </>
  )
}

export default MockAnalysis