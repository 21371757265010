import React, { useState } from "react";
import { AddForm } from "./addForm";

import DoubtSettingList from "./addForm";

const DoubtSettingMain = () => {
  const [doubtSettings, setDoubtSettings] = useState([]);

  const handleSave = (data) => {

    setDoubtSettings((prevSettings) => [...prevSettings, data]);
  };

  return (
    <div>
      <AddForm onSave={handleSave} />
      <DoubtSettingList settings={doubtSettings} />
    </div>
  );
};

export default DoubtSettingMain;

// import React, { useState, useEffect } from "react";
// import { AddForm } from "./addForm";
// import DoubtSettingList from "./addForm";

// import {
//   useGetDoubtListQuery,
//   useAddDoubtSettingMutation,
// } from "../../../../../services/apis/doubtApi";
// import { doubtApi } from "../../../../../services/Constant";
// import { useParams } from "react-router-dom";

// const DoubtSettingMain = () => {
//   const params = useParams();
//   const [doubtSettings, setDoubtSettings] = useState([]);
//   const { data, isLoading, isError } = useGetDoubtListQuery(
//     `/exams/v1/doubts/list/setting/${params.examId}`
//   );
//   const [addDoubtSetting] = useAddDoubtSettingMutation();

//   useEffect(() => {
//     if (data) {
//       setDoubtSettings(data.doubtSolvingSetting);
//       console.log("1234567", data);
//     }
//   }, [data]);

//   const handleSave = async (newSetting) => {
//     try {
//       const response = await addDoubtSetting({
//         endpoint: "/list/setting/entityType", // Replace with your actual endpoint
//         doubtData: newSetting,
//       }).unwrap();
//       if (response) {
//         setDoubtSettings((prevSettings) => [...prevSettings, newSetting]);
//       }
//     } catch (error) {
//       console.error("Error adding doubt setting:", error);
//     }
//   };

//   return (
//     <div>
//       <AddForm onSave={handleSave} />
//       {isLoading && <p>Loading...</p>}
//       {isError && <p>Error fetching data...</p>}
//       <DoubtSettingList settings={doubtSettings} />
//     </div>
//   );
// };

// export default DoubtSettingMain;
