// import React, { useEffect, useState } from "react";
// import { Card, CardContent, CardMedia } from "@mui/material";
// // import Stack from "@mui/material/Stack";
// // import { PlusCircle } from "lucide-react";
// import {
//     CustomButton
//     // , CustomButtonStyle
// } from "../../../../styles/muiRoot";
// import { useGetHomeBannersListQuery } from "../../../../services/apis/bannerApi";
// import { homeBannerApi } from "../../../../services/Constant";
// import CreateBanner from "./createBanner";
// import { Link } from "react-router-dom";

// function BannerList() {

//     let { isSuccess, isError, error, data, isLoading, refetch } = useGetHomeBannersListQuery(`${homeBannerApi.endPoint}/list`, {
//         refetchOnMountOrArgChange: true,

//     })

//     // let [id, setId] = useState('')



//     // const [showCreateBanner, setShowCreateBanner] = useState(false); // State to toggle view

//     // const handleCreateBannerClick = () => {

//     //     setShowCreateBanner(true); // Show CreateBanner component
//     // };





//     useEffect(() => {
//         if (isSuccess && data) {

//             console.log('Fetched banner:', data);
//         }
//         if (error) {
//             console.error('Fetch Error:', error);
//         }
//         refetch();

//     }, [isSuccess, data, error]);


//     let list = data?.data?.homeBannerList

//     // Conditionally render based on the loading state
//     if (isLoading) {
//         return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
//     }

//     // If there's no data but the request was successful, you might want to handle the case
//     if (!isLoading && isSuccess && !data) {
//         return <div>No data available</div>;
//     }

//     // Handle error state
//     if (isError) {
//         return <div>Error: {error?.message}</div>;
//     }




//     return (
//         <>
//             {/* <Stack
//                 direction={"row"}
//                 alignItems={"center"}
//                 justifyContent={"space-between"}
//                 px={5}
//                 py={1}
//             >
//                 <Typography variant="body1">
//                     <h1 className="ml-5 space-x-9 text-xl text-gray-700 font-bold">
//                         Live Banner
//                     </h1>
//                 </Typography>

//                 <div className="flex gap-2 items-center">
//                     <CustomButton
//                         style={{ ...CustomButtonStyle, marginRight: "35px" }}
//                         startIcon={<PlusCircle />}
//                     >
//                         <CreateBanner />
//                     </CustomButton>
//                 </div>
//             </Stack> */}



//             <div className="rounded-md px-8 py-4 flex flex-wrap justify-around gap-5 overflow-scroll max-h-[100vh]">
//                 {/* {[...Array(20)].map((item, ind) => ( */}
//                 {list.map((item, ind) => (
//                     // <Card
//                     //     sx={{
//                     //         width: "30%", // 3 cards per row
//                     //         height: "auto",
//                     //         borderRadius: 2,
//                     //         border: "1px solid #D8D8D8",
//                     //         boxShadow: "none",
//                     //         position: "relative", // For positioning the overlay
//                     //         overflow: "hidden", // Ensure content stays within the card
//                     //     }}
//                     //     key={ind}
//                     //     onClick={()=>{
//                     //         console.log(item._id);

//                     //     }}

//                     // >
//                     //     <CardMedia
//                     //         component="img"
//                     //         sx={{
//                     //             height: "13.625rem",
//                     //             width: "100%",
//                     //             borderRadius: 2,
//                     //             m: "auto",
//                     //         }}
//                     //         // image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU"
//                     //         image={item.thumbnail}
//                     //         alt="Banner"
//                     //     />

//                     //     {/* Normal Content */}
//                     //     <CardContent
//                     //         sx={{
//                     //             position: "absolute",
//                     //             top: 0,
//                     //             left: 0,
//                     //             right: 0,
//                     //             padding: "16px",
//                     //             color: "#fff",
//                     //             display: "flex",
//                     //             flexDirection: "column",
//                     //             justifyContent: "space-between",
//                     //         }}

//                     //     >
//                     //         <div>
//                     //             <h5 className="text-lg font-bold">
//                     //                 {item.title}
//                     //             </h5>
//                     //             <p className="text-sm mt-2">
//                     //                 {item.description}
//                     //             </p>
//                     //         </div>
//                     //         <div className="text-sm mt-2">
//                     //             Expires on <span className="font-bold">21 Aug, 21</span>
//                     //         </div>
//                     //     </CardContent>


//                     //     {/* Discount Section with Black Shadow */}
//                     //     <div
//                     //         style={{
//                     //             position: "absolute",
//                     //             bottom: "16px", // Adjusts the position near the bottom
//                     //             left: "16px",
//                     //             background: "rgba(0, 0, 0, 0.8)", // Dark background for discount section
//                     //             padding: "8px 12px",
//                     //             borderRadius: "4px",
//                     //             display: "flex",
//                     //             justifyContent: "space-between",
//                     //             alignItems: "center",
//                     //             width: "calc(100% - 32px)", // Adjusts to fit within the card's width
//                     //         }}
//                     //     >
//                     //         <span className="text-lg font-bold text-white">50% Off</span>
//                     //         <CustomButton
//                     //             style={{
//                     //                 backgroundColor: "#fff",
//                     //                 color: "#000",
//                     //                 padding: "6px 12px",
//                     //                 borderRadius: "4px",
//                     //             }}
//                     //         >
//                     //             Buy Now
//                     //         </CustomButton>
//                     //     </div>
//                     // </Card>
//                     <Link
//                         to={`/main/sales/banner/detail/${item._id}`}
//                         sx={{
//                             width: "30%", // 3 cards per row
//                             height: "auto",
//                             borderRadius: 2,
//                             border: "1px solid #D8D8D8",
//                             boxShadow: "none",
//                             position: "relative", // For positioning the overlay
//                             overflow: "hidden", // Ensure content stays within the card
//                         }}
//                         key={ind}
//                         onClick={() => {
//                             console.log(item._id);

//                         }}

//                     >
//                         <CardMedia
//                             component="img"
//                             sx={{
//                                 height: "13.625rem",
//                                 width: "300px",
//                                 borderRadius: 2,
//                                 m: "auto",
//                             }}
//                             // image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCpj7w5FSVRyofzC1reh7jGN87NEmeCb7amwsyc2xEmjwv0cIYrR0MKpvzrCAKWgOOwiY&usqp=CAU"
//                             image={item.thumbnail}
//                             alt="Banner"
//                         />

//                         {/* Normal Content */}
//                         <CardContent
//                             sx={{
//                                 position: "absolute",
//                                 top: 0,
//                                 left: 0,
//                                 right: 0,
//                                 padding: "16px",
//                                 color: "#fff",
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 justifyContent: "space-between",
//                             }}

//                         >
//                             <div>
//                                 <h5 className="text-lg font-bold">
//                                     {item.title}
//                                 </h5>
//                                 <p className="text-sm mt-2">
//                                     {item.description}
//                                 </p>
//                             </div>
//                             <div className="text-sm mt-2">
//                                 Expires on <span className="font-bold">21 Aug, 21</span>
//                             </div>
//                         </CardContent>


//                         {/* Discount Section with Black Shadow */}
//                         <div
//                             style={{
//                                 position: "absolute",
//                                 bottom: "16px", // Adjusts the position near the bottom
//                                 left: "16px",
//                                 background: "rgba(0, 0, 0, 0.8)", // Dark background for discount section
//                                 padding: "8px 12px",
//                                 borderRadius: "4px",
//                                 display: "flex",
//                                 justifyContent: "space-between",
//                                 alignItems: "center",
//                                 width: "calc(100% - 32px)", // Adjusts to fit within the card's width
//                             }}
//                         >
//                             <span className="text-lg font-bold text-white">50% Off</span>
//                             <CustomButton
//                                 style={{
//                                     backgroundColor: "#fff",
//                                     color: "#000",
//                                     padding: "6px 12px",
//                                     borderRadius: "4px",
//                                 }}
//                             >
//                                 Buy Now
//                             </CustomButton>
//                         </div>
//                     </Link>
//                 ))}
//             </div>





//         </>
//     );
// }


// export default BannerList;



// import React, { useEffect } from "react";
// import { Card, CardContent, CardMedia } from "@mui/material";
// import { CustomButton } from "../../../../styles/muiRoot";
// import { useGetHomeBannersListQuery } from "../../../../services/apis/bannerApi";
// import { homeBannerApi } from "../../../../services/Constant";
// import { Link, Outlet, useNavigate } from "react-router-dom";

// function BannerList() {
//     let { isSuccess, isError, error, data, isLoading, refetch } = useGetHomeBannersListQuery(`${homeBannerApi.endPoint}/list`, {
//         refetchOnMountOrArgChange: true,
//     });



//     useEffect(() => {
//         if (isSuccess && data) {
//             console.log('Fetched banner:', data);
//         }
//         if (error) {
//             console.error('Fetch Error:', error);
//         }
//         refetch();
//     }, [isSuccess, data, error]);

//     let list = data?.data?.homeBannerList;




//     if (isLoading) {
//         return <div>Loading...</div>;
//     }

//     if (!isLoading && isSuccess && !data) {
//         return <div>No data available</div>;
//     }

//     if (isError) {
//         return <div>Error: {error?.message}</div>;
//     }


//     return (
//         <div className="rounded-md px-8 py-4 flex flex-wrap justify-around gap-5 overflow-scroll max-h-[100vh]">
//             {list.map((item, ind) => {


//                 const start = new Date(item?.startDate);
//                 const end = new Date(item?.endDate);

//                 // Convert to local date format
//                 const startDate = start.toLocaleDateString(); // Default locale
//                 const endDate = end.toLocaleDateString(); // Default local

//                 return (
//                     <Link
//                         to={`/main/sales/banner/detail/${item._id}`}
//                         key={ind}
//                         style={{
//                             width: "30%",
//                             position: "relative",
//                             display: "block",
//                             textDecoration: "none", // To remove the underline from the Link
//                         }}

//                     >
//                         <Card
//                             sx={{
//                                 borderRadius: 2,
//                                 overflow: "hidden",
//                                 boxShadow: "none",
//                                 position: "relative", // To enable absolute positioning for the overlay
//                             }}
//                         >
//                             <CardMedia
//                                 component="img"
//                                 sx={{
//                                     height: "13.625rem",
//                                     width: "100%", // Adjusts to the card width
//                                     objectFit: "cover", // Ensures the image covers the card area
//                                 }}
//                                 image={item.thumbnail}
//                                 alt="Banner"
//                             />

//                             <CardContent
//                                 sx={{
//                                     position: "absolute",
//                                     top: 0,
//                                     left: 0,
//                                     right: 0,
//                                     bottom: 0,
//                                     background: "rgba(0, 0, 0, 0.1)", // Slightly darkens the background for better text readability
//                                     color: "#fff",
//                                     display: "flex",
//                                     flexDirection: "column",
//                                     justifyContent: "space-between",
//                                     padding: "16px",
//                                     boxSizing: "border-box", // Ensures padding doesn't cause overflow
//                                 }}
//                             >
//                                 <div>
//                                     <h5 className="text-lg font-bold">{item.title}</h5>
//                                     <p className="text-sm mt-2">{item.description}</p>
//                                 </div>
//                                 {/* <div className="text-sm mt-2">
//                                     Expires on <span className="font-bold">{endDate}</span>
//                                 </div> */}
//                             </CardContent>

//                             <div
//                                 style={{
//                                     position: "absolute",
//                                     bottom: "16px",
//                                     left: "16px",
//                                     background: "rgba(0, 0, 0, 0.8)",
//                                     padding: "8px 12px",
//                                     borderRadius: "4px",
//                                     display: "flex",
//                                     justifyContent: "space-between",
//                                     alignItems: "center",
//                                     width: "calc(100% - 32px)",
//                                 }}
//                             >
//                                 <span className="text-lg font-bold text-white">{item.offer}</span>
//                                 <CustomButton
//                                     style={{
//                                         backgroundColor: "#fff",
//                                         color: "#000",
//                                         padding: "6px 12px",
//                                         borderRadius: "4px",
//                                     }}
//                                 >
//                                     {item.buttonType}
//                                 </CustomButton>

//                             </div>
//                             <div className="text-sm mt-2">
//                                 Expires on <span className="font-bold">{endDate}</span>
//                             </div>
//                         </Card>
//                     </Link>
//                 )
//             })}
//         </div>
//     );
// }

// export default BannerList;


import React, { useEffect } from "react";
import { Card, CardContent, CardMedia } from "@mui/material";
import { CustomButton } from "../../../../styles/muiRoot";
import { useGetHomeBannersListQuery } from "../../../../services/apis/bannerApi";
import { homeBannerApi } from "../../../../services/Constant";
import { Outlet, useNavigate } from "react-router-dom";

function BannerList() {
    const { isSuccess, isError, error, data, isLoading, refetch } = useGetHomeBannersListQuery(`${homeBannerApi.endPoint}/list`, {
        refetchOnMountOrArgChange: true,
    });

    const navigate = useNavigate();



    useEffect(() => {
        if (isSuccess && data) {
            console.log('Fetched banner:', data);
        }
        if (error) {
            console.error('Fetch Error:', error);
        }
        refetch();
    }, [isSuccess, data, error]);

    let list = data?.data?.homeBannerList;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isLoading && isSuccess && !data) {
        return <div>No data available</div>;
    }

    if (isError) {
        return <div>Error: {error?.message}</div>;
    }

    const handleBannerClick = (id) => {
        // Navigate to the BannerDetail component with the selected banner ID
        navigate(`/main/sales/banner/detail/${id}`);
    };

    return (
        <>
            <div className="rounded-md px-8 py-4 flex flex-wrap justify-around gap-5 overflow-scroll max-h-[100vh]">
                {list.map((item, ind) => {
                    const start = new Date(item?.startDate);
                    const end = new Date(item?.endDate);

                    const startDate = start.toLocaleDateString();
                    const endDate = end.toLocaleDateString();

                    return (
                        <Card
                            key={ind}
                            sx={{
                                width: "30%",
                                borderRadius: 2,
                                overflow: "hidden",
                                boxShadow: "none",
                                cursor: "pointer", // Add cursor pointer to indicate it's clickable
                            }}
                            onClick={() => handleBannerClick(item._id)} // Handle click to navigate
                        >
                            <CardMedia
                                component="img"
                                sx={{
                                    height: "13.625rem",
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                                image={item.media[0]}
                                alt="Banner"
                            />
                            <CardContent
                                sx={{
                                    position: "relative",
                                    background: "rgba(0, 0, 0, 0.1)",
                                    color: "#fff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    padding: "16px",
                                    boxSizing: "border-box",
                                }}
                            >
                                <div>
                                    <h5 className="text-lg font-bold">{item.title}</h5>
                                    <p className="text-sm mt-2">{item.description}</p>
                                </div>
                            </CardContent>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "16px",
                                    left: "16px",
                                    background: "rgba(0, 0, 0, 0.8)",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "calc(100% - 32px)",
                                }}
                            >
                                <span className="text-lg font-bold text-white">{item.offer}</span>
                                <CustomButton
                                    style={{
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        padding: "6px 12px",
                                        borderRadius: "4px",
                                    }}
                                >
                                    {item.buttonType}
                                </CustomButton>
                            </div>
                            <div className="text-sm  bg-black text-white">
                                Expires on <span className="font-bold">{endDate}</span>
                            </div>
                        </Card>
                    );
                })}
            </div>
            {/* <Outlet/> */}
        </>
    );
}

export default BannerList;
