import React, { useEffect, useState } from 'react';
import DashboardHeader from './dashboardHeader';
import DashboardSidebar from './dashboardSidebar';
import MainContent from './mainContent';
import { useGetDashboardAnalyticsQuery } from '../../../services/apis/dashboardApi';
import { dashboardApi } from '../../../services/Constant';

const DashboardPage = () => {
  const [filterType, setFilterType] = useState(200);

  // Fetch the data from the API
  const { data, isLoading, isSuccess } = useGetDashboardAnalyticsQuery(`${dashboardApi.endPoint}/all/list?days=${filterType}&minAge=20&maxAge=30`);
  
  // Log the fetched data once it is successfully retrieved
  useEffect(() => {
    alert("dashboard")
    if (isSuccess && data) {
      console.log('Fetched Data:', data);
    }
  }, [isSuccess, data]);

  // Handle active section state for the sidebar
  const [activeSection, setActiveSection] = useState("");

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleSectionClose = () => {
    setActiveSection("");
  };

  // Conditionally render based on the loading state
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
  }

  // If there's no data but the request was successful, you might want to handle the case
  if (!isLoading && isSuccess && !data) {
    return <div>No data available</div>;
  }

  // If data is successfully fetched, render the content
  return (
    <div className="flex flex-col">
      <DashboardHeader />
      <DashboardSidebar
        activeSection={activeSection}
        onSectionChange={handleSectionChange}
      />
      <div className="flex-1 p-4">
        <MainContent
          activeSection={activeSection}
          onSectionClose={handleSectionClose}
          setFilterTypevalue={setFilterType}
          data={data}  // Pass the fetched data to MainContent
        />
      </div>
    </div>
  );
};

export default DashboardPage;
