import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    viewDetails: {},
    id: "",
    content: "",
    feedType: "",
    algoType: "",
    engagement: "",
    engagementParameters: {
        title:"",
        weightage:0
    },
    // content: "",
    contentParameters: {
        title:'',
        weightage:0
    },
    interest: "",
    interestWeightage:0,
    interestParameters: {
        title:'',
        weightage:0
    },
}

export const feedAlgoSlice = createSlice({
    name: "feedAlgoSlice",
    initialState,
    reducers: {

        viewFeedAlgo: (state = initialState, { payload }) => {
            return {
                ...state,
                viewDetails: payload,
            };
        },

        setFeedAlgo: (state = initialState, { payload }) => {
            console.log(payload);
            
            return {
                ...state,
                id: payload.id,
                content: payload.content,
                feedType: payload.feedType,
                algoType: payload.algoType,
                engagement: payload.engagement,
                engagementParameters: payload.engagementParameters,
                interest: payload.interest,
                interestWeightage: payload.interestWeightage,
                interestParameters: payload.interestParameters
               
            }
        }




        // setId: (initialState, { payload }) => {
        //     initialState.id = payload
        // },
        // setContent: (initialState, { payload }) => {
        //     initialState.content = payload
        // },
        // setFeedType: (initialState, { payload }) => {
        //     initialState.feedType = payload
        // },
        // setAlgoType: (initialState, { payload }) => {
        //     initialState.algoType = payload
        // },
        // setEngagement: (initialState, { payload }) => {
        //     initialState.engagement = payload
        // },
        // setEngagementParameters: (initialState, { payload }) => {
        //     initialState.engagementParameters = payload
        // },
        // setContent: (initialState, { payload }) => {
        //     initialState.content = payload
        // },
        // setContentParameters: (initialState, { payload }) => {
        //     initialState.contentParameters = payload
        // },
        // setInterest: (initialState, { payload }) => {
        //     initialState.interest = payload
        // },
        // setInterestParameters: (initialState, { payload }) => {
        //     initialState.interestParameters = payload
        // },


        // resetFeedAlgo: () => initialState,

    }
})


export const {
    viewFeedAlgo,
    setFeedAlgo
    // setId,
    // setContent,
    // setEngagement,
    // setAlgoType,
    // setContentParameters,
    // setEngagementParameters,
    // setFeedType,
    // setInterest,
    // setInterestParameters,


} = feedAlgoSlice.actions