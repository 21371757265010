// import React from "react";

// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { MoreVertical } from "lucide-react";

// import ProductGraph from "./productGraph";

// const Product = ({product}) => {
//   const [age, setAge] = React.useState("");
//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

// console.log(product);


//   return (
//     <>
//       <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
//         <div>
//           <div>
//             <div className="flex  w-full text-center h-12 justify-between p-2">
//               <h2 className=" text-lg font-bold">Product Performance</h2>
//               <div className="mr-6">
//                 <Box sx={{ minWidth: 150 }} height="5px">
//                   <FormControl fullWidth size="small">
//                     <Select
//                       id="demo-simple-select"
//                       value={age}
//                       onChange={handleChange}
//                       displayEmpty
//                       renderValue={(selected) => {
//                         if (selected.length === 0) {
//                           return <em>Past one Month</em>;
//                         }
//                         return selected;
//                       }}
//                     >
//                       <MenuItem disabled value="">
//                         <em>Past one Month</em>
//                       </MenuItem>
//                       <MenuItem value={"Past One Month"}>
//                         Past one Month
//                       </MenuItem>

//                       <MenuItem value={10}>Ten</MenuItem>
//                       <MenuItem value={20}>Twenty</MenuItem>
//                       <MenuItem value={30}>Thirty</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Box>
//                 <div className="flex justify-end -mr-7">
//                   <MoreVertical />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
//           <div>
//             <h2 className=" text-xl font-bold">11,780</h2>
//             <p className="mt-3 text-sm">Total Users</p> <br />
//             <h2 className=" text-xl font-bold">7,780</h2>
//             <p className="mt-3 text-sm">Total Premium User</p>
//           </div>

//           <div className=" ml-3 h-72">
//             <ProductGraph product = {product} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Product;



// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { MoreVertical } from "lucide-react";
// import ProductGraph from "./productGraph";

// const Product = ({ product }) => {
//   const [age, setAge] = useState(""); // Time period selection (in days)
//   const [filteredProduct, setFilteredProduct] = useState([]);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [totalPremiumUsers, setTotalPremiumUsers] = useState(0);

//   // Function to filter product data based on selected time period
//   useEffect(() => {
//     const filterProductData = (days) => {
//       const now = new Date();
//       const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
//       return product.filter((item) => {
//         const itemDate = new Date(item.date);
//         return itemDate >= daysAgo;
//       });
//     };

//     const transformData = (data) => {
//       return data.map((item) => ({
//         name: item.date,  // Assuming 'date' is the key in the data
//         TotalUsers: item.totalUsers,  // Assuming 'totalUsers' is the key in the data
//         TotalPremiumUser: item.totalPremiumUsers,  // Assuming 'totalPremiumUsers' is the key in the data
//       }));
//     };

//     const calculateTotals = (data) => {
//       const totalUsers = data.reduce((sum, item) => sum + item.totalUsers, 0);
//       const totalPremiumUsers = data.reduce((sum, item) => sum + item.totalPremiumUsers, 0);
//       setTotalUsers(totalUsers);
//       setTotalPremiumUsers(totalPremiumUsers);
//     };

//     let dataToUse = product;
//     if (age) {
//       dataToUse = filterProductData(age);
//     }

//     const transformedData = transformData(dataToUse);
//     setFilteredProduct(transformedData);
//     calculateTotals(dataToUse);
//   }, [age, product]);

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   return (
//     <>
//       <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
//         <div>
//           <div>
//             <div className="flex  w-full text-center h-12 justify-between p-2">
//               <h2 className=" text-lg font-bold">Product Performance</h2>
//               <div className="mr-6">
//                 <Box sx={{ minWidth: 150 }} height="5px">
//                   <FormControl fullWidth size="small">
//                     <Select
//                       id="demo-simple-select"
//                       value={age}
//                       onChange={handleChange}
//                       displayEmpty
//                       renderValue={(selected) =>
//                         selected.length === 0 ? <em>Past one Month</em> : `${selected} Days`
//                       }
//                     >
//                       <MenuItem disabled value="">
//                         <em>Past one Month</em>
//                       </MenuItem>
//                       <MenuItem value={30}>Past One Month</MenuItem>
//                       <MenuItem value={20}>Past Twenty Days</MenuItem>
//                       <MenuItem value={10}>Past Ten Days</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Box>
//                 <div className="flex justify-end -mr-7">
//                   <MoreVertical />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
//           <div>
//             <h2 className="text-xl font-bold">{totalUsers.toLocaleString()}</h2>
//             <p className="mt-3 text-sm">Total Users</p> <br />
//             <h2 className="text-xl font-bold">{totalPremiumUsers.toLocaleString()}</h2>
//             <p className="mt-3 text-sm">Total Premium Users</p>
//           </div>

//           <div className="ml-3 h-72">
//             <ProductGraph product={filteredProduct} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Product;


import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import ProductGraph from "./productGraph";

const Product = ({ product }) => {
  const [age, setAge] = useState(""); // Time period selection (in days)
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPremiumUsers, setTotalPremiumUsers] = useState(0);

  // Function to filter product data based on selected time period
  useEffect(() => {
    const filterProductData = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
      return product?.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= daysAgo;
      });
    };

    const transformData = (data) => {
  
      return data.map((item) => {
        const dateString = item.date;
        const date = new Date(dateString);
    
        const options = { day: 'numeric', month: 'short' };
        const formattedDate = date.toLocaleDateString('en-GB', options);
       return {
        name: formattedDate,  // Assuming 'date' is the key in the data
        TotalUsers: item.totalUsers,  // Assuming 'totalUsers' is the key in the data
        TotalPremiumUser: item.totalPremiumUsers,  // Assuming 'totalPremiumUsers' is the key in the data
      }});
    };
    

    if (age) {
      const filteredData = filterProductData(age);
      setFilteredProduct(transformData(filteredData));
    } else {
      setFilteredProduct(transformData(product));
    }

    // Calculate total users and total premium users
    const totalUsers = product?.reduce((sum, item) => sum + item.totalUsers, 0);
    const totalPremiumUsers = product?.reduce((sum, item) => sum + item.totalPremiumUsers, 0);

    setTotalUsers(totalUsers);
    setTotalPremiumUsers(totalPremiumUsers);
  }, [age, product]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
        <div>
          <div>
            <div className="flex  w-full text-center h-12 justify-between p-2">
              <h2 className=" text-lg font-bold">Product Performance</h2>
              <div className="mr-6">
                <Box sx={{ minWidth: 150 }} height="5px">
                  <FormControl fullWidth size="small">
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) =>
                        selected.length === 0 ? <em>Past one Month</em> : `${selected} Days`
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Past one Month</em>
                      </MenuItem>
                      <MenuItem value={30}>Past One Month</MenuItem>
                      <MenuItem value={20}>Past Twenty Days</MenuItem>
                      <MenuItem value={10}>Past Ten Days</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex justify-end -mr-7">
                  <MoreVertical />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div>
            <h2 className=" text-xl font-bold">{totalUsers.toLocaleString()}</h2> {/* Original Total Users */}
            <p className="mt-3 text-sm">Total Users</p> <br />
            <h2 className=" text-xl font-bold">{totalPremiumUsers.toLocaleString()}</h2> {/* Original Total Premium Users */}
            <p className="mt-3 text-sm">Total Premium User</p>
          </div>

          <div className=" ml-3 h-72">
            <ProductGraph product={filteredProduct} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
