
import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
  Chip,
  Switch,
  Box,
} from "@mui/material";

const FeedList = ({ feeds }) => {
  const customSwitchStyles = (checked) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: checked ? "green" : "red",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: checked ? "green" : "red",
    },
  });

  return (
    <Grid container spacing={3}>
      {feeds?.map((feed, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              width: "100%",
              fontFamily: "var(--font-inter)",
              borderRadius: 2,
              p: 2,
              height: "auto",
              boxShadow: 3,
              border: "1px solid #e0e0e0",
              position: "relative",
            }}
          >
            <Chip
              className="absolute top-0 right-0"
              label="Feed Info"
              sx={{
                background: feed.feedType === "user" ? "#3f51b5" : "#f50057",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0 0 0 2px",
              }}
            />

            <CardHeader
              sx={{ p: 0, mb: 1 }}
              avatar={
                <Avatar
                  alt="User Avatar"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 60, height: 60 }}
                />
              }
              title={
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Feed Info
                </Typography>
              }
              subheader={
                <Typography variant="body2" color="textSecondary">
                  Feed Type: {feed.feedType} | Algo Type: {feed.algoType}
                </Typography>
              }
            />
            <Divider />

            <CardContent sx={{ p: 2 }}>
              <List
                subheader={
                  <ListSubheader disableGutters>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="text.primary"
                    >
                      Details
                    </Typography>
                  </ListSubheader>
                }
              >
                {[
                  {
                    label: "Engagement",
                    component: (
                      <Switch
                        checked={feed.engagement}
                        sx={customSwitchStyles(feed.engagement)}
                        inputProps={{ "aria-label": "Engagement" }}
                      />
                    ),
                  },
                  {
                    label: "Engagement Parameters",
                    component: feed.engagementParameters?.map((param, idx) => (
                      <Box key={idx} mb={1}>
                        <Typography variant="body1">{param.param}</Typography>
                      </Box>
                    )),
                  },
                  {
                    label: "Content",
                    component: (
                      <Switch
                        checked={feed.content}
                        sx={customSwitchStyles(feed.content)}
                        inputProps={{ "aria-label": "Content" }}
                      />
                    ),
                  },
                  {
                    label: "Content Parameters",
                    component: feed.contentParameters?.map((param, idx) => (
                      <Box key={idx} mb={1}>
                        <Typography variant="body1">{param.param}</Typography>
                      </Box>
                    )),
                  },
                  {
                    label: "Interest",
                    component: (
                      <Switch
                        checked={feed.interest}
                        sx={customSwitchStyles(feed.interest)}
                        inputProps={{ "aria-label": "Interest" }}
                      />
                    ),
                  },
                  {
                    label: "Interest Weight",
                    component: (
                      <Box mb={1}>
                        <Typography variant="body1">
                          Weight: {feed.interestWeightage}
                        </Typography>
                      </Box>
                    ),
                  },
                ]?.map(({ label, component }) => (
                  <ListItem disableGutters key={label}>
                    <ListItemText>
                      <Typography variant="body2" color="textSecondary">
                        {label}:
                      </Typography>
                    </ListItemText>
                    <ListItemAvatar>{component}</ListItemAvatar>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeedList;
