import React from "react";
import Container from "./container";

const AnalyticMain = () => {
  return (
    <>
      <Container />
    </>
  );
};

export default AnalyticMain;
