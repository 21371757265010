// question bank sample

import React from "react";
import { Routes, Route } from "react-router-dom";
import Promotion from "./promotion";
import HomePage from "./homePage";
import BannerDetail from "./banner/bannerDetail";
import CreateBanner from "./banner/createBanner";
import BannerMain from "./banner/bannerMain";
import AnalyticMain from "./sales_Analytic/analyticMain";
import NotificationMain from "./notification/notificationMain";
import HelpRequest from "./help-request/helpRequest";
import Schedule from "./schedule/schedule";
import ScheduleDetail from "./schedule/scheduleDetail";

function SalesMain() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/schedule/create" element={<Schedule />} />
      <Route path="/schedule/edit/:id" element={<Schedule />} />
      <Route path="/schedule/detail/:id" element={<ScheduleDetail />} />
      <Route path="/banner" element={<BannerMain />} />
      <Route path="/banner/detail/:id" element={<BannerDetail />} />
      <Route path="/banner/edit/:id" element={<CreateBanner />} />
      <Route path="/analystic" element={<AnalyticMain />} />
      <Route path="/help-request" element={<HelpRequest />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/push-notification" element={<NotificationMain />} />

      {/* </Route> */}
    </Routes>
  );
}

export default SalesMain;



// import React from "react";
// import { Routes, Route } from "react-router-dom";
// import Schedule from "./schedule";
// import BannerMain from "./banner/bannerMain";
// import BannerDetail from "./banner/bannerDetail";
// import CreateBanner from "./banner/createBanner";
// import Promotion from "./promotion";
// import NotificationMain from "./notification/notificationMain";
// import HomePage from "./homePage";
// import AnalyticMain from "./sales_Analytic/analyticMain";

// function SalesMain() {
//   return (
//     <Routes>
//       <Route path="/" element={<HomePage />}>
//         {/* Nested Routes for /main/sales */}
//         <Route path="analystic" element={<AnalyticMain />} />
//         <Route path="help-request" element={<Promotion />} />
//         <Route path="promotion" element={<Promotion />} />
//         <Route path="banner" element={<BannerMain />}>
//           {/* Nested Routes for /main/sales/banner */}
//           <Route path="detail/:id" element={<BannerDetail />} />
//           <Route path="edit/:id" element={<CreateBanner />} />
//         </Route>
//         <Route path="push-notification" element={<NotificationMain />} />
//         <Route path="schedule/create" element={<Schedule />} />
//       </Route>
//     </Routes>
//   );
// }

// export default SalesMain;



