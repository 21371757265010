
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const generateDefaultData = (filterType) => {
  const today = new Date();
  const defaultData = [];

  for (let i = 0; i < filterType; i++) {
    const date = new Date();
    date.setDate(today.getDate() - (filterType - i - 1));
    defaultData.push({
      date: date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
      count: 0,
    });
  }

  return defaultData;
};

const DoubtsGraph = ({ data, filterType }) => {
  const formattedData = (data && data.length > 0)
    ? data.map((item) => ({
        date: new Date(item.date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        }),
        count: item.count,
      }))
    : generateDefaultData(filterType);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={formattedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            label={{
              value: "Number of Doubts",
              angle: -90,
              position: "insideLeft",
              dx: -5,
              dy: 110,
              style: { fontWeight: 600 },
            }}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#8884d8"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default DoubtsGraph;

