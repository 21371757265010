
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import ActivityGraph from "./activityGraph";
import { useGetGroupAnalyticsQuery } from "../../../../../../../services/apis/exam/group";
import { groupApi } from "../../../../../../../services/Constant";
import { useParams } from "react-router-dom";

const Activity = () => {
  const params = useParams();
  const [filterType, setFilterType] = useState(30);
  const [selectedData, setSelectedData] = useState({
    activeUsersData: [],
    inactiveUsersData: [],
  });
  const [graphData, setGraphData] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);

  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };

  const { data, isLoading, isSuccess, isError, error } =
    useGetGroupAnalyticsQuery(
      `${groupApi.endPoint}/analytics/list/${params.groupId}/activity?days=${filterType}&minAge=10&maxAge=30`
    );

  useEffect(() => {
    if (isSuccess && data && data.data.activitiesByDate) {
      const transformedData = data.data.activitiesByDate.map((activity) => ({
        date: new Date(activity.date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        }),
        activeUsers: activity.activeUsers,
        inactiveUsers: activity.inactiveUsers,
      }));
      setGraphData(transformedData);

      const labels = transformedData.map((entry) => entry.date);
      setXAxisLabels(labels);
    } else {
      setGraphData([]);
      setXAxisLabels([]);
    }
  }, [data, isSuccess, error, isLoading, filterType]);

  useEffect(() => {
    if (isSuccess && data) {
      setSelectedData({
        activeUsersData: [
          { label: "Total", value: data.data.activityAllTotal },
          { label: "Discussion", value: data.data.discussion },
          { label: "Challenges", value: data.data.challengeTotalCount },
          { label: "Doubt", value: data.data.doubtTotalCount },
        ],
        inactiveUsersData: [
          { label: "Total", value: data.data.activityAllTotal },
          { label: "Discussion", value: data.data.discussion },
          { label: "Challenges", value: data.data.challengeTotalCount },
          { label: "Doubt", value: data.data.doubtTotalCount },
        ],
      });
    }
  }, [data, isSuccess]);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleBarClick = (data) => {
    const { activeUsers, inactiveUsers } = data.payload;
    setSelectedData({
      activeUsersData: [
        { label: "Total", value: activeUsers.reduce((a, b) => a + b, 0) },
        { label: "Discussion", value: activeUsers[0] },
        { label: "Challenges", value: activeUsers[1] },
        { label: "Doubt", value: activeUsers[2] },
      ],
      inactiveUsersData: [
        { label: "Total", value: inactiveUsers.reduce((a, b) => a + b, 0) },
        { label: "Discussion", value: inactiveUsers[0] },
        { label: "Challenges", value: inactiveUsers[1] },
        { label: "Doubt", value: inactiveUsers[2] },
      ],
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="w-3/4 h-7rem border border-#cfd0d1-50 rounded-md">
        <div className="flex w-full text-center h-12 justify-between p-2">
          <h2 className="text-lg font-bold">User Activity</h2>
          <div className="mr-6">
            <Box sx={{ minWidth: 150 }} height="5px">
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterType}
                  onChange={handleFilterChange}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length === 0 ? (
                      <em>This Week</em>
                    ) : (
                      filterTypeLabels[selected]
                    )
                  }
                  size="small"
                >
                  <MenuItem value={7}>
                    <em>This Week</em>
                  </MenuItem>
                  <MenuItem value={30}>Month</MenuItem>
                  <MenuItem value={90}>3 Month</MenuItem>
                  <MenuItem value={180}>6 Month</MenuItem>
                  <MenuItem value={365}>1 Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <div className="flex justify-end -mr-7">
              <MoreVertical />
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div className="w-full ml-3 h-72 mb-4">
            <ActivityGraph
              data={graphData}
              onBarClick={handleBarClick}
              xAxisLabels={xAxisLabels}
            />
          </div>
        </div>

        <div className="flex justify-between">
          <div className="w-1/2 h-22 rounded-lg border border-#cfd0d1-50 mr-2 ml-6 mb-6">
            <div className="p-4">
              <p className="font-bold text-base mb-2 border-b border-gray-300 pb-2">
                Average Active Users
              </p>
              <div className="flex justify-between">
                {selectedData.activeUsersData.map((item, index) => (
                  <div key={index} className="text-center">
                    <p className="font-bold text-xs">{item.label}</p>
                    <p className="text-xs">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-1/2 h-22 rounded-lg border border-#cfd0d1-50 ml-2 mr-6 mb-6">
            <div className="p-4">
              <p className="font-bold text-base mb-2 border-b border-gray-300 pb-2">
                Average Inactive Users
              </p>
              <div className="flex justify-between">
                {selectedData.inactiveUsersData.map((item, index) => (
                  <div key={index} className="text-center">
                    <p className="font-bold text-xs">{item.label}</p>
                    <p className="text-xs">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity;

