import React from "react";
import Container from "./container";

const AnalyticsMains = () => {
  return (
    <>
      <Container />
    </>
  );
};

export default AnalyticsMains;
