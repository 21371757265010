import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardHeader,
  Avatar,
  Chip,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Switch,
} from "@mui/material";
import { EditForm } from "./editForm";
import dayjs from "dayjs";

export const AddForm = ({ onSave }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (formData) => {
    if (onSave) {
      onSave(formData);
    }

    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ float: "right", margin: "10px" }}
        onClick={handleClickOpen}
      >
        ⊕ Add Doubt Setting
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Doubt Setting Form</DialogTitle>
        <DialogContent>
          <EditForm onSave={handleSave} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const customSwitchStyles = (checked) => ({
  "& .MuiSwitch-switchBase": {
    color: checked ? "#3f51b5" : "#bdbdbd",
    "&.Mui-checked": {
      color: "#3f51b5",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "#3f51b5" : "#bdbdbd",
  },
});

const DoubtSettingList = ({ settings }) => {
  return (
    <Grid container spacing={3}>
      {settings.map((setting, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              width: "100%",
              fontFamily: "var(--font-inter)",
              borderRadius: 2,
              p: 2,
              height: "auto",
              boxShadow: 3,
              border: "1px solid #e0e0e0",
              position: "relative",
            }}
          >
            <Chip
              className="absolute top-0 right-0"
              label="Doubt Query"
              sx={{
                background:
                  setting.doubtId?.type === "group"
                    ? "#3f51b5"
                    : setting.doubtId?.type === "class"
                    ? "#f50057"
                    : "#9e9e9e",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0 0 0 2px",
              }}
            />

            <CardHeader
              sx={{ p: 0, mb: 1 }}
              avatar={
                <Avatar
                  alt={setting.doubtId?.createdBy?.name}
                  src={setting.doubtId?.createdBy?.profilePic}
                  sx={{ width: 60, height: 60 }}
                />
              }
              title={
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Doubt Info
                </Typography>
              }
              subheader={
                <Typography variant="body2" color="textSecondary">
                  Created by {setting.doubtId?.createdBy?.name} on{" "}
                  {dayjs(setting.doubtId?.createdAt).format("MMMM D, YYYY")}
                </Typography>
              }
            />
            <Divider />

            <CardContent sx={{ p: 2 }}>
              <List
                subheader={
                  <ListSubheader disableGutters>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="text.primary"
                    >
                      Details
                    </Typography>
                  </ListSubheader>
                }
              >
                {[
                  {
                    label: "Entity Type",
                    value: setting.entityType,
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        {setting.entityType}
                      </Typography>
                    ),
                  },
                  {
                    label: "Category",
                    value: setting.category,
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        {setting.category}
                      </Typography>
                    ),
                  },
                  {
                    label: "Setting Type",
                    value: setting.settingType,
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        {setting.settingType}
                      </Typography>
                    ),
                  },
                  {
                    label: "Time Limit",
                    value: setting.timeLimit,
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        {setting.timeLimit} minutes
                      </Typography>
                    ),
                  },
                  {
                    label: (
                      <Box mb={2}>
                        <h1>Coin Chart:</h1>
                        <Grid container spacing={2} mb={1}>
                          <Grid item xs={4}>
                            <Typography variant="body1" fontWeight="bold">
                              Minute
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body1" fontWeight="bold">
                              Coin
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body1" fontWeight="bold">
                              Point
                            </Typography>
                          </Grid>
                        </Grid>

                        {setting.coinChart?.map((coin, idx) => (
                          <Box key={idx} mb={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography variant="body1">
                                  {coin.minute}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="body1">
                                  {coin.coin}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="body1">
                                  {coin.point}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                      </Box>
                    ),
                  },
                  {
                    label: "Extension Allowed",
                    value: setting.extensionAllowed,
                    component: (
                      <Switch
                        checked={setting.extensionAllowed}
                        sx={customSwitchStyles(setting.extensionAllowed)}
                        inputProps={{ "aria-label": "Extension Allowed" }}
                      />
                    ),
                  },
                  {
                    label: "Extended Duration",
                    component: setting.extensionAllowed && (
                      <>
                        {setting.extendedDuration?.map((duration, idx) => (
                          <Box key={idx} mb={1}>
                            <Typography variant="body1">
                              Penalty: {duration.penalty} | Extension Period:{" "}
                              {duration.extensionPeriod}
                            </Typography>
                          </Box>
                        ))}
                      </>
                    ),
                  },
                  {
                    label: "Auto Pass Allowed",
                    value: setting.autoPassAllowed,
                    component: (
                      <Switch
                        checked={setting.autoPassAllowed}
                        sx={customSwitchStyles(setting.autoPassAllowed)}
                        inputProps={{ "aria-label": "Auto Pass Allowed" }}
                      />
                    ),
                  },
                  {
                    label: "Auto Pass Duration",
                    value: setting.autoPassAllowed
                      ? setting.autoPassDuration
                      : null,
                    component: setting.autoPassAllowed && (
                      <Typography variant="body1" fontWeight="bold">
                        {setting.autoPassDuration} minutes
                      </Typography>
                    ),
                  },
                  {
                    label: "Incorrect Penalty",
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        Coin: {setting.incorrectPenalty.coin}| Point:{" "}
                        {setting.incorrectPenalty.point}
                      </Typography>
                    ),
                  },
                  {
                    label: "Review Rewards",
                    component: (
                      <Typography variant="body1" fontWeight="bold">
                        Coin: {setting.reviewRewards.coin}| Point:{" "}
                        {setting.reviewRewards.point}
                      </Typography>
                    ),
                  },
                ].map(({ label, value, component }) => (
                  <ListItem disableGutters key={label}>
                    <ListItemText>
                      <Typography variant="body2" color="textSecondary">
                        {label}:
                      </Typography>
                    </ListItemText>
                    <ListItemAvatar>
                      {component || (
                        <Typography variant="body1">{value}</Typography>
                      )}
                    </ListItemAvatar>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DoubtSettingList;