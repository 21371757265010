import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {  scheduleApi } from '../../../../services/Constant';
import { AppBar, Toolbar, IconButton, Grid, Typography, Paper, Avatar, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import PublishIcon from '@mui/icons-material/Publish';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../../../common/header';
import { useGetScheduleByIdQuery, useStatusScheduleMutation } from '../../../../services/apis/schedule';



export default function ScheduleDetail() {
    let { id } = useParams()

    const navigate = useNavigate()

    const [status, setStatus] = useState(false);
    const [promoted, setPromoted] = useState(false);



    let [stats] = useStatusScheduleMutation()



    let { isSuccess, isError, error, data, isLoading, refetch } = useGetScheduleByIdQuery(`${scheduleApi.endPoint}/details/${id}`, {
        refetchOnMountOrArgChange: true,

    })






    useEffect(() => {
        if (isSuccess && data) {

            console.log('Fetched banner:', data);
        }
        if (error) {
            console.error('Fetch Error:', error);
        }
        refetch();
    }, [isSuccess, data, error]);


    let datas = data?.data?.scheduledSpot



    const start = new Date(datas?.startDate);
    const end = new Date(datas?.endDate);

    // Convert to local date format
    const startDate = start.toLocaleDateString(); // Default locale
    const endDate = end.toLocaleDateString(); // Default locale




    // Conditionally render based on the loading state
    if (isLoading) {
        return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
    }

    // If there's no data but the request was successful, you might want to handle the case
    if (!isLoading && isSuccess && !data) {
        return <div>No data available</div>;
    }

    // Handle error state
    if (isError) {
        return <div>Error: {error?.message}</div>;
    }




    const statusChange = () => {
        setStatus(prevState => !prevState)

        stats({
            isPublished: status,
            id
        }).unwrap()

    }



    return (
        <>


            <Header content={"Schedule Details"} />

            <Box
                sx={{
                    height: '100vh', // Full screen height
                    width: '90vw', // Full screen width
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Header with Back Button */}
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar>
                        <IconButton color="primary" onClick={() =>
                            // navigate('/main/sales/banner')
                            navigate('/main/sales')
                        }>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>
                            Schedule Detail
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                            <IconButton color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Link to={`/main/sales/schedule/edit/${id}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                    <EditIcon />
                                    <Typography variant="body1" sx={{ marginLeft: 1 }}>EDIT</Typography>
                                </Link>
                            </IconButton>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2, }}>
                            <IconButton
                                color="primary"
                                onClick={() => setPromoted((prev) => !prev)}>
                                <UnarchiveIcon />
                                <Typography variant="body1">{promoted === true ? "PROMOTED" : "UNPROMOTED"}</Typography>
                            </IconButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="primary"
                                onClick={statusChange}
                            >
                                {/* Icon or Content inside IconButton */}
                                <PublishIcon />
                                <Typography variant="body1">{status === true ? "PUBLISH" : "UNPUBLISH"}</Typography>
                            </IconButton>
                            {/* </IconButton> */}
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* Main Content */}
                <Grid container sx={{ padding: 4, flexGrow: 1 }}>
                    {/* Left Side (Details) */}
                    <Grid item xs={12} md={12} sx={{ height: '100%' }}> {/* Ensures the container takes full height */}
                        <Paper
                            elevation={2}
                            sx={{
                                padding: 2,
                                height: '100%', // Makes the paper occupy the full height of its container
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between' // Spread the content evenly vertically
                            }}
                        >
                            {/* Content Section */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> {/* Aligns content and image side by side */}
                                <Box >
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>Exam Name: </strong>{datas.exam}</Typography>
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>Sub Module: </strong>{datas.subModule}</Typography>
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>From: </strong>{startDate}</Typography>
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>To: </strong>{endDate}</Typography>
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>Resource To: </strong>{datas.sourceType}</Typography>
                                    <Typography variant="h6" style={{ marginBottom: "25px" }}><strong>sourceId: </strong>{datas.sourceId}</Typography>


                                </Box>

                                {/* Image Section */}
                                <Avatar
                                    variant="square"
                                    alt="BETA"
                                    src={datas.thumbnail} // Replace with your image path
                                    sx={{ width: 200, height: 200, objectFit: 'contain', marginLeft: 2 }} // Ensure the image doesn't overflow
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box >


        </>
    )
}
