// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { feedAlgo } from "../Constant";

export const feedAlgoApi = createApi({
  reducerPath: "feedAlgoApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
  tagTypes: ["getFeedAlgoList", "getFeedAlgoPackage"],
  endpoints: (builder) => ({
    getFeedAlgoList: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: ["getFeedAlgoList"],
    }),
    // getFeedAlgo: builder.query({
    //   query: (endpoint) => `${endpoint}`,
    //   providesTags: ["getFeedAlgo"],
    // }),


    getFeedAlgoDetail: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: ["getFeedAlgoDetail"],
    }),

    // createFeedAlgo: builder.mutation({
    //   query: ({ endPoint, algoData }) => {
    //     console.log(endPoint,algoData);
        
    //     return {
    //       url: endPoint,
    //       method: "POST",
    //       body: algoData,

    //     };
    //   },
    //   invalidatesTags: [],
    // }),

    createFeedAlgo: builder.mutation({
      query: ({ feedType, algoType, newFeed }) => ({
        url: `${feedAlgo.endPoint}/add/${feedType}/${algoType}`,
        method: "POST",
        body: newFeed,
        
      }),
      invalidatesTags: [], // Add relevant tags here if necessary
    }),

    updateFeedAlgo: builder.mutation({
      query: ({ endpoint, updatedData }) => {
        return {
          url: endpoint,
          method: "PATCH",
          body: updatedData,

        };
      },
      invalidatesTags: [],
    }),

    // updateFeedAlgo: builder.mutation({
    //   query: ({ endpoint, updatedData }) => {
    //     return {
    //       url: endpoint,
    //       method: "PATCH",
    //       body: updatedData,

    //     };
    //   },
    //   invalidatesTags: [],
    // }),

    // createFeedAlgo: builder.mutation({
    //   query: ({ endpoint,newFeedAlgo }) => {
    //     return {
    //       url: endpoint,
    //       method: "POST",
    //       body: newFeedAlgo,

    //     };
    //   },
    //   invalidatesTags: [],
    // }),
  }),
});

export const {
  useCreateFeedAlgoMutation,
  useGetFeedAlgoListQuery,
  useUpdateFeedAlgoMutation,
  useGetFeedAlgoDetailQuery,
} = feedAlgoApi;


// create
// {{5005}}/exams/v1/feedAlgo/add/:feedType/:algoType

// getList
// {{5005}}/exams/v1/feedAlgo/list

// getbyId
// {{5005}}/exams/v1/feedAlgo/detail/:id

// delete
// {{5005}}/exams/v1/feedAlgo/delete/:id

// update
// {{5005}}/exams/v1/feedAlgo/update/:id