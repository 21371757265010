import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import { CircleDot } from "lucide-react";
import TuserGraph from "./tuserGraph";
import { useGetCoursesAnalyticsQuery } from '../../../../../../services/apis/exam/courses';
import { courseApi } from '../../../../../../services/Constant';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
const Tuser = () => {
  const params = useParams();
  const [filterType, setFilterType] = useState(30);
  const { data, isLoading, isSuccess } = useGetCoursesAnalyticsQuery(`${courseApi.endPoint}/analysis/all/${params.examId}/?days=${filterType}`);
  const [courseData, setCourseData] = useState([]);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const colors = ["#5146D6", "#D28715", "#8AF1B9", "#d62728"];
  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year"
  };
  useEffect(() => {
    if (isSuccess && data) {
      const transformData = (courseData) =>
        courseData.map((item) => ({
          count: item.count,
          title: item.courseId.title

        }));

      setCourseData(transformData(data.data.usersByCourse));

    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess || !data) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <>
      <Card sx={{ width: "50%", height: "fit-content" }}>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              Total Users
            </Typography>

            <Box display={"flex"} alignItems={"center"} height={"30%"}>
              <FormControl sx={{ width: "170px" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterType}
                  onChange={handleFilterChange}
                  displayEmpty
                  renderValue={(selected) => (selected.length === 0 ? <em>This Week</em> : filterTypeLabels[selected])}
                  size="small"
                >
                  <MenuItem value={7}>
                    <em>This Week</em>
                  </MenuItem>
                  <MenuItem value={30}>Month</MenuItem>
                  <MenuItem value={90}>3 Month</MenuItem>
                  <MenuItem value={180}>6 Month</MenuItem>
                  <MenuItem value={365}>1 Year</MenuItem>
                </Select>
              </FormControl>
              <MoreVertical />
            </Box>
          </Stack>
        </CardContent>

        <div className="w-full flex justify-center items-center">
          <div className="w-[450px]">
            <TuserGraph data={courseData} totalCount={data.data.totalUsersCount} />
          </div>
        </div>

        <div className="flex items-center flex-wrap gap-y-2 p-2">
          {data.data.usersByCourse ? (
            data.data.usersByCourse.map((course,index) => (
              <div key={course.courseId._id} className="flex gap-2 items-center text-sm text-gray-800 basis-[50%]">
                <CircleDot
                  size={20}
                  color={colors[index % colors.length]}
                  strokeWidth={4}
                  style={{ marginRight: "3px" }}
                />
                {course.courseId.title} : <strong>{course.count}</strong>
              </div>
            ))
          ) : (
            <Typography>No courses data available</Typography>
          )}
        </div>
      </Card>
    </>
  );
};

export default Tuser;
