
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import Switch from "@mui/material/Switch";

import SalesGraph from "./salesGraph";

const Sales = ({ sales }) => {
  const [age, setAge] = useState("");
  const [checked, setChecked] = useState(false);
  const [filteredSalesCount, setFilteredSalesCount] = useState(sales);

  useEffect(() => {
    const filterSalesCount = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
      return sales?.result?.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= daysAgo;
      });
    };

    if (age) {
      setFilteredSalesCount({
        ...sales,
        result: filterSalesCount(age),
      });
    } else {
      setFilteredSalesCount(sales);
    }
  }, [age, sales]);
  // console.log(sales);



  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
      <div>
        <div className="flex w-full text-center h-12 justify-between p-2">
          <h2 className="text-lg font-bold">Sales Count</h2>
          <div className="mr-6">
            <Box sx={{ minWidth: 150 }} height="5px">
              <FormControl fullWidth size="small">
                <Select
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length === 0 ? <em>Past one Month</em> : `${selected} Days`
                  }
                >
                  <MenuItem disabled value="">
                    <em>Past one Month</em>
                  </MenuItem>
                  {/* <MenuItem value={"Past One Month"}>Past one Month</MenuItem> */}
                  <MenuItem value={30 }>Past One Month</MenuItem>
                  <MenuItem value={20 }>Past 20 Days</MenuItem>
                  <MenuItem value={10 }>Past 10 Days</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <div className="flex justify-end -mr-7">
              <MoreVertical />
            </div>
            <div className="mt-3">
              <span>Show Comparison</span>
              <Switch
                checked={checked}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex p-2 mt-10 overflow-hidden rounded-lg">

        <div>
          <h2 className=" text-xl font-bold">{sales.totalSalesCount}</h2>
          <p className="mt-3 text-sm">Total Sales in Units</p>

        </div>
        <div className="w-full ml-3 h-72">
          <SalesGraph showComparison={checked} sales={filteredSalesCount} />
        </div>
      </div>
    </div>
  );
};

export default Sales;
