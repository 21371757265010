import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const salesAnalysticApi = createApi({
    reducerPath: "salesAnalysticApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
    tagTypes: ["getSalesAnalysticList",],
    endpoints: (builder) => ({
        getSalesAnalysticList: builder.query({
            query: (endpoint) => `${endpoint}`,
            providesTags: ["getSalesAnalysticList"],
        }),

        getSalesAnalysticById: builder.query({
            query: (endpoint) => `${endpoint}`,
            providesTags: [""],
        }),

    }),


});



export const {
   
    useGetSalesAnalysticListQuery
  } = salesAnalysticApi;