import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Button, Chip, Stack, Avatar } from '@mui/material';
import { deepOrange } from '@mui/material/colors';

const initialData = [
    {
        id: 1,
        type: 'Bug',
        name: 'Preethi Bajaj',
        contact: '+91-9126344614',
        query: 'Will I get direct one on one coaching sessions from the faculties when I purchase a course',
        date: '25 Jun',
    },
    {
        id: 2,
        type: 'Query',
        name: 'Atif Aslam',
        contact: '+91-9126344614',
        query: 'I want to select a crash course that will help me prepare for GMAT in one month.',
        date: '25 Jun',
    },
    {
        id: 3,
        type: 'Query',
        name: 'Tamanna Begum',
        contact: '+91-9126344614',
        query: 'Need to inquire about how should I start preparing for my exam,',
        date: '25 Jun',
    },
    {
        id: 4,
        name: "Ravi Kumar",
        contact: "+91-9876543210",
        query: "Can I get a refund if I cancel the course after a week?",
        type: "Query",
        date: "26 Jun"
    },
    {
        id: 5,
        name: "Anjali Sharma",
        contact: "+91-8765432109",
        query: "What are the available slots for one-on-one coaching?",
        type: "Bug",
        date: "26 Jun"
    },
    {
        id: 6,
        name: "Vikram Singh",
        contact: "+91-7654321098",
        query: "Is there any discount available on the GMAT course?",
        type: "Query",
        date: "27 Jun"
    },
    {
        id: 7,
        name: 'Rahul Kumar',
        contact: '+91-9988776655',
        type: 'Query',
        query: 'Can you help me understand the enrollment process for the advanced Java course?',
        date: '26 Jun',
    },
    {
        id: 8,
        name: 'Sania Mirza',
        contact: '+91-9911223344',
        type: 'Bug',
        query: 'I am facing issues while making payment for the Python course.',
        date: '26 Jun',
    },
    {
        id: 9,
        name: 'Aman Gupta',
        contact: '+91-9001122334',
        type: 'Query',
        query: 'What are the pre-requisites for joining the Data Science course?',
        date: '27 Jun',
    },
    // Additional data here...
];

export default function QueryList() {
    const [data, setData] = useState(initialData);

    const handleAction = (id, actionType) => {
        setData((prevData) => prevData.filter((item) => item.id !== id)

        );
        let d = data.filter((d) => d.id !== id)
        console.log(d);
        
        console.log(`${actionType} action taken for ID: ${id}`);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <Grid item xs={12} md={6} lg={4} key={item.id}>
                        <Card>
                            <CardContent>
                                <Chip
                                    label={item.type}
                                    color={item.type === 'Bug' ? 'error' : 'warning'}
                                    style={{ marginBottom: '10px' }}
                                />
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar sx={{ bgcolor: deepOrange[500] }}>
                                        {item.name.charAt(0)}
                                    </Avatar>
                                    <Stack>
                                        <Typography variant="h6">{item.name}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            ID: {item.id} • {item.contact}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Typography variant="body1" style={{ margin: '30px 0  100px' }}>
                                    {item.query}
                                </Typography>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleAction(item.id, 'Mark Done')}
                                        >
                                            Mark Done
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => handleAction(item.id, 'Assign')}
                                        >
                                            Assign
                                        </Button>
                                    </Stack>
                                    <Typography variant="caption" color="textSecondary">
                                        {item.date}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
