import React from "react";
import DatamapsIndia from "react-datamaps-india";

const Map = ({ selectedCardData, handleMapClick, selectedState }) => {
  const regionData = selectedCardData
    ? selectedCardData.stateCounts.reduce((acc, state) => {
        if (state.count > 0) {
          acc[state.state] = { value: state.count };
        }
        return acc;
      }, {})
    : {};
  console.log("regionData", regionData);
  const handleMapClickInternal = (geo) => {
    alert('map click internal')
    console.log(geo, '16');
    handleMapClick(geo.id);
  };

  return (
    <div className="relative w-full scale-150">
      <DatamapsIndia
        regionData={regionData}
        hoverComponent={({ value }) => (
          <div className="w-1/3 h-20 p-2 bg-white">
            <p>{value.name}</p>
            <p>{value.value} users</p>
          </div>
        )}
        mapLayout={{
          title: "",
          legendTitle: "",
          startColor: "#17cf0a",
          endColor: "#FF6347",
          hoverTitle: "User Count",
          noDataColor: "#f5f5f5",
          borderColor: "#8D8D8D",
          hoverBorderColor: "#8D8D8D",
          hoverColor: "green",
        }}
        onClick={handleMapClickInternal}
      />
      {selectedState && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "red",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(252, 132, 3, 1)",
          }}
        >
          <p>
            State: {selectedState}
            <br />
            Users:{" "}
            {
              selectedCardData.stateCounts.find(
                (state) => state.state === selectedState
              ).count
            }
          </p>
        </div>
      )}
    </div>
  );
};

export default Map;


