import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { month: "challenge", CAT: 59, GMAT: 57, TOFEL: 86, Others: 21 },
  { month: "Cource", CAT: 50, GMAT: 52, TOFEL: 78, Others: 28 },
  { month: "MockTest", CAT: 47, GMAT: 53, TOFEL: 106, Others: 41 },
  {
    month: "Practice",
    CAT: 54,
    GMAT: 56,
    TOFEL: 92,
    Others: 73,
  },
  {
    month: "Learn",
    CAT: 54,
    GMAT: 56,
    TOFEL: 92,
    Others: 73,
  },
];

const HourGraph = () => (
  <ResponsiveContainer width={1300} height={280}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey="month" />
      <YAxis
        label={{
          value: "Hours",
          angle: -90,
          position: "insideLeft",
        }}
      />
      <Tooltip />
      <Legend
        verticalAlign="top"
        height={36}
        wrapperStyle={{ marginBottom: 30 }}
      />
      <Bar dataKey="CAT" fill="#4DA1FF" barSize={8} radius={[10, 10, 0, 0]} />
      <Bar dataKey="GMAT" fill="#6A6A9F" barSize={8} radius={[10, 10, 0, 0]} />
      <Bar
        dataKey="TOFEL"
        fill="#FFD12F"
        barSize={8}
        radius={[10, 10, 0, 0]}
      />
      <Bar
        dataKey="Others"
        fill="#E56C51"
        barSize={8}
        radius={[10, 10, 0, 0]}
      />
    </BarChart>
  </ResponsiveContainer>
);

export default HourGraph;
