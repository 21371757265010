import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ProductGraph = ({product}) => {
  
 
  return (
    <>
      <LineChart
        width={600}
        height={290}
        data={product}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: "Users (in Lakhs)",
            angle: -90,
            position: "insideLeft",
            dx: -5,
            dy: 70,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="TotalUsers"
          stroke="#82ca9d"
          strokeWidth={3}
        />

        <Line
          type="monotone"
          dataKey="TotalPremiumUser"
          stroke="#8884d8"
          strokeWidth={3}
        />
      </LineChart>
    </>
  );
};

export default ProductGraph;
