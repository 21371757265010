
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const colorsActive = ["#5146D6", "#5146D6", "#5146D6", "#5146D6"];
const colorsInactive = ["#24B670", "#24B670", "#24B670", "#24B670"];

const ActivityGraph = ({ data = [], onBarClick, xAxisLabels }) => {
 
  const graphData =
    data.length > 0
      ? data
      : [
          {
            date: xAxisLabels[0], 
            activeUsers: 0,
            inactiveUsers: 0,
          },
        ];

  return (
    <ResponsiveContainer width="100%" height={280}>
      <BarChart
        data={graphData}
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={(value) => value} />
        <YAxis
          label={{
            value: "Users",
            angle: -90,
            position: "insideLeft",
            dx: -5,
            dy: 30,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Bar
          dataKey="activeUsers"
          fill={colorsActive[0]}
          barSize={17}
          name="Active Users"
          onClick={(payload, index) =>
            onBarClick({
              payload,
              index,
              type: "active",
            })
          }
        />
        <Bar
          dataKey="inactiveUsers"
          fill={colorsInactive[0]}
          barSize={17}
          name="Inactive Users"
          onClick={(payload, index) =>
            onBarClick({
              payload,
              index,
              type: "inactive",
            })
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ActivityGraph;

