import React from "react";
import Product from "./product";
import Revenue from "./revenue";
import Courses from "./cources";
import Mock from "./mock";
import Learn from "./learn";
import Groups from "./groups";
import Coins from "./coins";
import Express from "./express";
import ExamList from "./examList";

const Container = ({ data }) => {


  let revenue = data?.data?.revenueTrend
  const totalUserAndPremiumUser = data?.data?.totalUserAndPremiumUser;
  const courseSale = data?.data?.courseSale;
  const coinBundleSale = data?.data?.bundleSale;
  const doubtRaised = data?.data?.doubtRaised;
  const mockPackageSale = data?.data?.mockPackageSale;
  const examList = data?.data?.breakdown;


  // console.log(revenue);

  return (
    <>

      <div>
        <h1 className="m-5 space-x-9 text-lg font-bold">SALES OVERVIEW</h1>
        <div className=" flex space-x-7 m-6">
          <Product product={totalUserAndPremiumUser} />
          <Revenue revenue={revenue} />
        </div>
      </div>
      <div>
        <h1 className="m-5 space-x-9 text-lg font-bold">MODULE OVERVIEW</h1>

        <div className=" flex space-x-7 m-6 ml-0">
          <Courses course={courseSale} />
          <Mock mock = {mockPackageSale} />
        </div>
        {/* <div className=" flex space-x-7 m-6 ml-0">
          <Learn learn = {courseSale} />
          <Groups groups = {mockPackageSale} />
        </div> */}
      </div>
      <div>
        <h1 className="m-5 space-x-9 text-lg font-bold">COINS SALES</h1>

        <div className=" flex space-x-7 m-6">
          <Coins coin={coinBundleSale} />
          <Express doubt={doubtRaised} />
        </div>
      </div>
      <div className="p-1 , m-3 rounded-md border border-r-emerald-50">
        <h1 className="m-5 space-x-9 text-lg font-bold">Exam Wise Breakdown</h1>
        <ExamList examList={examList} />
      </div>
    </>
  );
};

export default Container;
