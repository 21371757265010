
// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { MoreVertical } from "lucide-react";
// import Switch from "@mui/material/Switch";
// import RevenueGraph from "./revenueGraph";

// const Revenue = ({revenue}) => {
//   const [age, setAge] = useState("");
//   const [checked, setChecked] = useState(false);



//   // console.log(revenue);


//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   const handleSwitchChange = (event) => {
//     setChecked(event.target.checked);




//   };

//   return (
//     <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
//       <div>
//         <div className="flex w-full text-center h-12 justify-between p-2">
//           <h2 className="text-lg font-bold">Revenue Trend</h2>
//           <div className="mr-6">
//             <Box sx={{ minWidth: 150 }} height="5px">
//               <FormControl fullWidth size="small">
//                 <Select
//                   id="demo-simple-select"
//                   value={age}
//                   onChange={handleChange}
//                   displayEmpty
//                   renderValue={(selected) =>
//                     selected.length === 0 ? <em>Past one Month</em> : selected
//                   }
//                 >
//                   <MenuItem disabled value="">
//                     <em>Past one Month</em>
//                   </MenuItem>
//                   {/* <MenuItem value={"Past One Month"}>Past one Month</MenuItem> */}
//                   <MenuItem value={10}>Ten</MenuItem>
//                   <MenuItem value={20}>Twenty</MenuItem>
//                   <MenuItem value={30}>Thirty</MenuItem>
//                 </Select>
//               </FormControl>
//             </Box>
//             <div className="flex justify-end -mr-7">
//               <MoreVertical />
//             </div>
//             <div className="mt-3">
//               <span>Show Comparison</span>
//               <Switch
//                 checked={checked}
//                 onChange={handleSwitchChange}
//                 inputProps={{ "aria-label": "controlled" }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
//         <div>
//           <h2 className="text-xl font-bold">25,468</h2>
//           <p className="mt-3 text-sm">Total Revenue</p>
//         </div>
//         <div className="w-full ml-3 h-72">
//           <RevenueGraph showComparison={checked} revenue = {revenue}/>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Revenue;




import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import Switch from "@mui/material/Switch";
import RevenueGraph from "./revenueGraph";

const Revenue = ({ revenue }) => {
  const [age, setAge] = useState("");
  const [checked, setChecked] = useState(false);
  const [filteredRevenue, setFilteredRevenue] = useState(revenue);




  useEffect(() => {
    const filterRevenue = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
      return revenue?.result?.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= daysAgo;
      });
    };

    if (age) {
      setFilteredRevenue({
        ...revenue,
        result: filterRevenue(age),
      });
    } else {
      setFilteredRevenue(revenue);
    }
  }, [age, revenue]);

  const handleChange = (event) => {
    setAge(event.target.value );
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md">
      <div>
        <div className="flex w-full text-center h-12 justify-between p-2">
          <h2 className="text-lg font-bold">Revenue Trend</h2>
          <div className="mr-6">
            <Box sx={{ minWidth: 150 }} height="5px">
              <FormControl fullWidth size="small">
                <Select
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length === 0 ? <em>Past one Month</em> : `${selected} Days`
                  }
                >
                  <MenuItem disabled value="">
                    <em>Past one Month</em>
                  </MenuItem>
                  <MenuItem value={30}>Past One Month</MenuItem>
                  <MenuItem value={20}>Past Twenty Days</MenuItem>
                  <MenuItem value={10}>Past Ten Days</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <div className="flex justify-end -mr-7">
              <MoreVertical />
            </div>
            <div className="mt-3">
              <span>Show Comparison</span>
              <Switch
                checked={checked}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
        <div>
          <h2 className="text-xl font-bold">{revenue.total}</h2>
          <p className="mt-3 text-sm">Total Revenue</p>
        </div>
        <div className="w-full ml-3 h-72">
          <RevenueGraph showComparison={checked} revenue={filteredRevenue} />
        </div>
      </div>
    </div>
  );
};

export default Revenue;



