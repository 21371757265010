import React from "react";
import Container2 from "./container2";

const LearnAnalyticsMain = () => {
  return (
    <div>
      <Container2 />
    </div>
  );
};

export default LearnAnalyticsMain;
