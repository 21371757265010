// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { homeBannerApi } from "../Constant";

export const HomeBannerApi = createApi({
  reducerPath: "HomeBannerApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
  tagTypes: ["getHomeBanner", "getHomeBannerPackage"],
  endpoints: (builder) => ({

    getHomeBannersList: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: ["getHomeBannersList"],
    }),
    getHomeBannerById: builder.query({
      query: (endpoint) => `${endpoint}`,
      providesTags: [""],
    }),
    createHomeBanner: builder.mutation({
      query: ({ bannerData }) => ({
        url: `${homeBannerApi.endPoint}/add`,
        method: "POST",
        body: bannerData,

      }),
      invalidatesTags: [], // Add relevant tags here if necessary
    }),
    uploadMedia: builder.mutation({
      query: (formData) => {
        return {
          url: `${homeBannerApi.endPoint}/upload/image`,
          method: 'POST',
          body: formData,
          headers: {
            // Removing 'Content-Type' header lets the browser set the correct boundary
          },
        };
      },
    }),

    status: builder.mutation({
      query: ({ isPublished, id }) => {
        console.log(id);

        return {
          url: `${homeBannerApi.endPoint}/status/${id}`,
          method: 'PATCH',
          body: {
            isPublished
          },

        };
      },
    }),


    updateHomeBanner: builder.mutation({
      query: ({ bannerData, id }) => ({
        url: `${homeBannerApi.endPoint}/update/${id}`,
        method: "PATCH",
        body: bannerData,

      }),
      invalidatesTags: [], // Add relevant tags here if necessary
    }),




  }),
});

export const {
  useCreateHomeBannerMutation,
  useGetHomeBannersListQuery,
  useGetHomeBannerByIdQuery,
  useUpdateHomeBannerMutation,
  useUploadMediaMutation,
  useStatusMutation

} = HomeBannerApi;
