import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const SalesGraph = ({ salesData }) => {

  if (!salesData || salesData.length === 0) {
    return <div>No sales data available</div>;
  }


  const flattenedData = salesData.flatMap(course =>
    course.sales.map(sale => ({
      date: new Date(sale.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }), // Format date to 'MMM DD' (e.g., 'May 29')
      TotalSales: sale.totalSales,

    }))
  );
  console.log("mera", flattenedData);
  return (
    <>
      <LineChart
        width={500}
        height={290}
        data={flattenedData}
        margin={{
          top: 5,
          right: 20,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          label={{
            value: "Sales units (in thousands)",
            angle: -90,
            position: "insideLeft",
            dx: -3,
            dy: 80,
            style: { fontWeight: 600 },
          }}
        />
        <Tooltip />
        <Legend />

        <Line
          type="monotone"
          dataKey="TotalSales"
          stroke="#82ca9d"
          strokeWidth={4}
        />
      </LineChart>
    </>
  );
};

export default SalesGraph;
