import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    viewDetails: {},
    title: "",
    notificationType:"",
    url: "",
    image:null,
    description: "",
    topic: "",
    sourceType: "",
    sourceId: "",

}

export const notificationSlice = createSlice({


    name: "notificationSlice",
    initialState,
    reducers: {

        viewNotifications: (state = initialState, { payload }) => {
            return {
                ...state,
                viewDetails: payload,
            };
        },

        setNotification: (state = initialState, { payload }) => {
            console.log(payload);

            return {
                ...state,
                notificationTitle: payload.title,
                notificationType: payload.notificationType,
                url: payload.url,
                description: payload.description,
                image: payload.image,
                topic: payload.topic,
                sourceType: payload.sourceType,
                sourceId: payload.sourceId,

            }
        }








    }
})


export const {
    viewNotifications,
    setNotification
    // setId,
    // setContent,
    // setEngagement,
    // setAlgoType,
    // setContentParameters,
    // setEngagementParameters,
    // setFeedType,
    // setInterest,
    // setInterestParameters,


} = notificationSlice.actions