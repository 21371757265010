import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import CmlgGraph from "./cmlgGraph";

const Courses = ({ course }) => {
  const [age, setAge] = useState(30); // Default to 30 days (one month)
  const [filteredCourse, setFilteredCourse] = useState(course);
  // console.log(course);


  useEffect(() => {
    const filterCourse = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

      return course?.map((item) => {
        const filteredSales = item.dailySales.filter((sales) => {
          const salesDate = new Date(sales.date);
          return salesDate >= daysAgo;
        });
        return { ...item, dailySales: filteredSales };
      });
    };

    setFilteredCourse(filterCourse(age));
  }, [age, course]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md ml-4">
        <div>
          <div>
            <div className="flex w-full text-center h-12 justify-between p-2">
              <h2 className="text-lg font-bold">Courses</h2>
              <div className="mr-6">
                <Box sx={{ minWidth: 150 }} height="5px">
                  <FormControl fullWidth size="small">
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      renderValue={(selected) =>
                        selected === 30 ? (
                          <em>Past one Month</em>
                        ) : (
                          `${selected} Days`
                        )
                      }
                    >
                      <MenuItem disabled value={30}>
                        <em>Past one Month</em>
                      </MenuItem>
                      <MenuItem value={30}>Thirty Days</MenuItem>
                      <MenuItem value={20}>Twenty Days</MenuItem>
                      <MenuItem value={10}>Ten Days</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex justify-end -mr-7">
                  <MoreVertical />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div className="w-full ml-3 h-72" >
          <CmlgGraph course={filteredCourse} />
        </div>
      </div>
    </div >
    </>
  );
};

export default Courses;
