import React, { useEffect, useState } from "react";
import { HeaderWithNavigation } from "../../../common/header";
import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCoursesQuery } from "../../../../services/apis/exam/courses";
import { blogApi, courseApi, entityTypeApi, eventApi, groupApi, mockPackageApi, rewardsApi, scheduleApi } from "../../../../services/Constant";
import { useGetEntityTypeListQuery } from "../../../../services/apis/dataManagement/entityType";
import { useGetGroupListQuery } from "../../../../services/apis/exam/group";
import { useGetMockPackagesQuery } from "../../../../services/apis/exam/mock";
import { useGetEventListQuery } from "../../../../services/apis/eventApi";
import { useGetBlogListQuery } from "../../../../services/apis/blogApi";
import { useGetBundleListQuery, useGetRewardListQuery } from "../../../../services/apis/rewardsApi";
import { useCreateScheduleMutation, useGetScheduleByIdQuery, useUpdateScheduleMutation, useUploadMediaMutation } from "../../../../services/apis/schedule";

// const topics = [
//   {
//     _id: "64feef6274d1bdc726bdc5c8",

//     title: "Arithmatic",
//   },
//   {
//     _id: "64feef6274d1bdc726bdc5c8",

//     title: "Arithmatic",
//   },
// ];

// const subTopics = [
//   { _id: "64fef00db3d7035a12c35390", title: " Percentages" },
//   {
//     _id: "64fef00db3d7035a12c35390",
//     title: "Percentages",
//   },
// ];

// const initialState = {
//   qid: "1234",
//   uid: "",
//   entity_type_id: "650445c6fc904d452998918c",
//   subject_id: "",
//   topic_id: "",
//   subtopic_id: "",
//   difficulty_level: "",
//   question: null,
//   answer: null,
//   options: [],
//   explanations: null,
//   video_link: "",
//   video_data: "",
//   allowed_for: [],
//   marks: "",
//   question_type: "",
//   check: false,
//   context: null,
// };



const initialState = {
  exam: '',
  subModule: '',
  startDate: '',
  endDate: '',
  sourceType: '',
  sourceId: '',
  thumbnail: ''
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Schedule() {

  const navigate = useNavigate()
  let { id } = useParams()

  const [createSchedule] = useCreateScheduleMutation()
  const [updateSchedule] = useUpdateScheduleMutation()
  const [uploadMedia] = useUploadMediaMutation()


  const {
    isSuccess: isCoursesSuccess,
    isLoading: isCoursesLoading,
    isError: isCoursesError,
    data: coursesData,
    error: coursesError
  } = useGetCoursesQuery(`${courseApi.endPoint}/basic`);

  const {
    isSuccess: isGroupSuccess,
    isLoading: isGroupLoading,
    isError: isGroupError,
    data: groupData,
    error: groupError
  } = useGetGroupListQuery(`${groupApi.endPoint}`);

  const {
    isSuccess: isMockPackageSuccess,
    isLoading: isMockPackageLoading,
    isError: isMockPackageError,
    data: mockPackageData,
    error: mockPackageError
  } = useGetMockPackagesQuery(`${mockPackageApi.endPoint}`);

  const {
    isSuccess: isEventSuccess,
    isLoading: isEventLoading,
    isError: isEventError,
    data: eventData,
    error: eventError
  } = useGetEventListQuery(`${eventApi.endPoint}/list`);


  const {
    isSuccess: isBlogSuccess,
    isLoading: isBlogLoading,
    isError: isBlogError,
    data: blogData,
    error: blogError
  } = useGetBlogListQuery(`${blogApi.endPoint}/list`);

  const {
    isSuccess: isBundleSuccess,
    isLoading: isBundleLoading,
    isError: isBundleError,
    data: bundleData,
    error: bundleError
  } = useGetBundleListQuery(`${rewardsApi.bundleEndPoint}/list`);


  const {
    isSuccess: isRewardSuccess,
    isLoading: isRewardLoading,
    isError: isRewardError,
    data: rewardData,
    error: rewardError
  } = useGetRewardListQuery(`${rewardsApi.rewardsEndPoint}/list`);
  const {
    isSuccess: isExamSuccess,
    isLoading: isExamLoading,
    isError: isExamError,
    data: examData,
    error: examError
  } = useGetEntityTypeListQuery(`${entityTypeApi.endPoint}`);


  let { isSuccess, isError, error, data, isLoading, refetch } = useGetScheduleByIdQuery(`${scheduleApi.endPoint}/details/${id}`, {

  })



  const [formData, setFormData] = useState(initialState);

  let { exam, subModule, startDate, endDate, sourceType, sourceId, thumbnail } = formData




  const handleApiResponse = (isSuccess, data, isError, error, apiName) => {
    if (isSuccess && data) {
      console.log(`Fetched ${apiName} Data:`, data);
    }
    if (isError) {
      console.error(`${apiName} Fetch Error:`, error);
    }
  };





  // variables
  let courses = coursesData?.data?.courseList;
  let exams = examData?.data
  let group = groupData?.data;
  let mock = mockPackageData?.data;
  let event = eventData?.data;
  let blog = blogData?.data?.blogs
  let reward = rewardData?.data?.rewards
  let bundle = bundleData?.data?.bundleList;
  let datas = data?.data?.scheduledSpot




  useEffect(() => {
    handleApiResponse(isCoursesSuccess, coursesData, isCoursesError, coursesError, 'Courses');
    handleApiResponse(isGroupSuccess, groupData, isGroupError, groupError, 'Group');
    handleApiResponse(isMockPackageSuccess, mockPackageData, isMockPackageError, mockPackageError, 'MockPackage');
    handleApiResponse(isEventSuccess, eventData, isEventError, eventError, 'Event');
    handleApiResponse(isBlogSuccess, blogData, isBlogError, blogError, 'Blog');
    handleApiResponse(isRewardSuccess, rewardData, isRewardError, rewardError, 'Reward');
    handleApiResponse(isBundleSuccess, bundleData, isBundleError, bundleError, 'Bundle');
    handleApiResponse(isExamSuccess, examData, isExamError, examError, 'Exams');
    handleApiResponse(isSuccess, data, isError, error, 'Schedule');



    if (id) {


      if (id && datas?._id === id) {
        setFormData({
          exam: datas.exam,
          subModule: datas.subModule,
          startDate: datas.startDate,
          endDate: datas.endDate,
          sourceType: datas.sourceType,
          sourceId: datas.sourceId,
          thumbnail: datas.thumbnail
        })

      } else {
        exam = '';
        subModule = '';
        startDate = '';
        endDate = '';
        sourceType = '';
        sourceId = '';
        thumbnail = ''
      }
    } else {
      exam = '';
      subModule = '';
      startDate = '';
      endDate = '';
      sourceType = '';
      sourceId = '';
      thumbnail = ''
    }


  }, [
    isCoursesSuccess, coursesData, isCoursesError, coursesError,
    isGroupSuccess, groupData, isGroupError, groupError,
    isMockPackageSuccess, mockPackageData, isMockPackageError, mockPackageError,
    isEventSuccess, eventData, isEventError, eventError,
    isBlogSuccess, blogData, isBlogError, blogError,
    isRewardSuccess, rewardData, isRewardError, rewardError,
    isBundleSuccess, bundleData, isBundleError, bundleError,
    isExamSuccess, examData, isExamError, examError,
    isSuccess, data, isError, error,
    id
  ]);






  // Loading
  if (isCoursesLoading || isGroupLoading || isMockPackageLoading || isEventLoading || isBlogLoading || isBundleLoading || isRewardLoading || isExamLoading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (isCoursesError || isGroupError || isMockPackageError || isEventError || isBlogError || isBundleError || isRewardError || isExamError) {
    return <div>Error: {coursesError?.message || groupError?.message || mockPackageError?.message || eventError?.message || blogError?.message || bundleError?.message || rewardError?.message || examError?.message}</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'startDate' || name === 'endDate') {
      setFormData({
        ...formData,
        [name]: new Date(value).toISOString()
      });
    } else if (name === 'sourceType') {
      setFormData({
        ...formData,
        [name]: value,
        sourceId: '' // Reset sourceId when sourceType changes
      });
    } else {

      setFormData({
        ...formData,
        [name]: value,

      });
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (id) {

      await updateSchedule({
        formData, id
      }).unwrap()

    } else {

      await createSchedule({
        formData
      }).unwrap()
      console.log(formData);
    }

    setTimeout(() => {
      navigate('/main/sales')
    }, 1000);

  }



  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // Update the bannerData state with the selected file (optional if needed)
    setFormData((prev) => ({
      ...prev,
      image: file,
    }));

    // Create FormData and append the file under the 'file' key
    const form = new FormData();
    form.append('image', file);  // Key should match what the API expects

    try {
      // Upload the file using the uploadMedia mutation
      const response = await uploadMedia(form).unwrap();

      // Assuming `response.url` contains the image URL returned by the API
      const imageURL = response.data;

      console.log(imageURL);

      // Update the bannerData state with the new image URL
      setFormData((prev) => ({
        ...prev,
        thumbnail: imageURL,
      }));
    } catch (error) {
      console.error('Upload failed:', error);
    }
  };


  return (
    <div className="h-full bg-white">
      <HeaderWithNavigation cont="Schedule Spot" />
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <div className="flex h-[93vh] ">
          <div className="basis-9/12 5 mt-3 ml-8">
            <div className="w-5/6 flex flex-col gap-8">
              <div>
                <label
                  htmlFor="Exam name"
                  className="text-base font-semibold ms-1"
                >
                  Exam Name
                </label>

                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <InputLabel id="demo-multiple-name-label">Option</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={exam}
                    name="exam"
                    required
                    onChange={handleChange}
                    input={<OutlinedInput label="Option" />}
                    MenuProps={MenuProps}
                  >
                    {
                      exams?.map((value, index) => (
                        <MenuItem key={index} value={value.title}> {value.title}  </MenuItem>

                      ))
                    }
                    {/* <MenuItem value="NMAT"> NMAT  </MenuItem>
                    <MenuItem value="PGDCA"> PGDCA  </MenuItem>
                    <MenuItem value="TISS"> TISS  </MenuItem> */}
                  </Select>
                </FormControl>
              </div>

              <div>
                <label
                  htmlFor="Sub Module"
                  className="text-base font-semibold ms-1"
                >
                  Sub Module
                </label>

                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <InputLabel id="demo-multiple-name-label">Option</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={subModule}
                    name="subModule"
                    required
                    onChange={handleChange}
                    input={<OutlinedInput label="Option" />}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="homeFeed"> Home Feed  </MenuItem>
                    <MenuItem value="groupFeed"> Group Feed  </MenuItem>
                    <MenuItem value="read"> Read  </MenuItem>
                    <MenuItem value="pushNotification"> Push Notification  </MenuItem>
                    <MenuItem value="userProfile"> User Profile  </MenuItem>
                    <MenuItem value="learn"> Learn  </MenuItem>
                    <MenuItem value="course"> Course  </MenuItem>
                    <MenuItem value="eventSection"> Event Section  </MenuItem>

                  </Select>
                </FormControl>
              </div>

              <div className="flex justify-start gap-x-5 ">
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="From"
                    type="date"
                    name="startDate"
                    value={startDate ? new Date(startDate).toISOString().split('T')[0] : ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="To"
                    type="date"
                    name="endDate"
                    value={endDate ? new Date(endDate).toISOString().split('T')[0] : ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </div>
              {/* Course and select gt */}

              <div className="mt-10">
                <label
                  htmlFor="redirect to "
                  className="text-base font-semibold ms-1"
                >
                  Redirect to
                </label>

                <div className="flex gap-5">
                  <FormControl sx={{ mt: 2, width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Course </InputLabel>
                    <Select
                      value={sourceType}
                      onChange={handleChange}
                      name="sourceType"
                    >
                      <MenuItem value="course">Course </MenuItem>
                      <MenuItem value="event">Event</MenuItem>
                      <MenuItem value="photo">Photo</MenuItem>
                      <MenuItem value="video">Video</MenuItem>
                      <MenuItem value="mockPackage">Mock-Package</MenuItem>
                      <MenuItem value="bundle">Bundle</MenuItem>
                      <MenuItem value="post">Post</MenuItem>
                      <MenuItem value="profile">Profile</MenuItem>
                      <MenuItem value="groups">Groups</MenuItem>
                      <MenuItem value="blog">Blog</MenuItem>
                      <MenuItem value="rewards">Rewards</MenuItem>
                    </Select>

                  </FormControl>
                  <FormControl sx={{ mt: 2, width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">
                      -select Course -
                    </InputLabel>

                    <Select
                      value={sourceId}
                      onChange={handleChange}
                      name="sourceId"
                    >
                      {sourceType == 'course' && courses?.map((course, index) => (
                        <MenuItem key={index} value={course._id}>
                          {course.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'groups' && group?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'mockPackage' && mock?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'event' && event?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'blog' && blog?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'bundle' && bundle?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}
                      {sourceType == 'rewards' && reward?.map((value, index) => (
                        <MenuItem key={index} value={value._id}>
                          {value.title}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>


                </div>
                <FormControl sx={{ mt: 2, width: "40%" }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontSize: 15, fontWeight: 'bold', marginBottom: "10px" }}
                    >
                      Background Image
                    </Typography>
                    <Button
                      variant="outlined"
                      component="label"
                      style={{
                        height: "150px",  // Adjusted height
                        width: "100%",    // Full width to align with other components
                        backgroundColor: "whiteSmoke",
                        backgroundImage: thumbnail ? `url(${thumbnail})` : 'none',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        marginBottom: "20px", // Added margin at the bottom
                      }}
                    >
                      + Add Photo/Video
                      <input
                        type="file"
                        hidden
                        onChange={handleImageChange}
                      />
                    </Button>
                  </Grid>
                </FormControl>
              </div>
            </div>
          </div>
          <div className=" px-3 py-1 w-full basis-3/12">
            <div className="bg-medGrey h-full rounded-md px-2">
              <h5 className="text-primary text-xl font-bold font-inder p-2">
                Preview
              </h5>
              <div className="bg-white h-[84vh] m-1 rounded-md">
                <Box border={1} borderColor="grey.300" padding={2} height="60%">
                  <Typography variant="h6">Preview</Typography>
                  <p><strong>Exam Name:</strong> {exam}</p>
                  <p><strong>SubModule:</strong> {subModule}</p>
                  <p><strong>From:</strong> {startDate ? new Date(startDate).toLocaleDateString() : ''}</p>
                  <p><strong>To:</strong> {endDate ? new Date(endDate).toLocaleDateString() : ''}</p>
                  <p><strong>Redirect To:</strong> {sourceType}</p>
                  <p>
                    <strong>Background Image:</strong>
                    {thumbnail && (
                      <img
                        src={thumbnail}
                        alt="Preview"
                        style={{ width: '100px', height: "100px", marginTop: '10px' }}
                      />
                    )}
                  </p>

                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, }} >
                  <Button type="submit" variant="contained" color="primary"  >
                    Send
                  </Button>

                  <Button variant="contained" color="primary" style={{ marginLeft: "5px" }} onClick={() => navigate('/main/sales')}>Cancel</Button>



                </Box>
              </div>

            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Schedule;
