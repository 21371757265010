import React from "react";
import DatamapsIndia from "react-datamaps-india";

const Mapping = ({
  selectedCardData,
  handleMapClick,
  selectedState,
}) => {
  const regionData = selectedCardData
   ? selectedCardData.stateCounts.reduce((acc, state) => {
        if (state.count > 0) {
          acc[state.state] = { value: state.count };
        }
        return acc;
      }, {})
    : {};

  const handleMapClickInternal = (geo) => {
    handleMapClick(geo.id);
  };

  return (
    <div className="relative w-full scale-150">
      <DatamapsIndia
        regionData={regionData}
        hoverComponent={({ value }) => (
          <div className="w-1/3 h-20 p-2 bg-white">
            <p>{value?.name}</p>
            <p>{value?.value} users</p>
          </div>
        )}
        mapLayout={{
          title: "",
          legendTitle: "",
          startColor: "#17cf0a",
          endColor: "#FF6347",
          hoverTitle: "User Count",
          noDataColor: "#f5f5f5",
          borderColor: "#8D8D8D",
          hoverBorderColor: "#8D8D8D",
          hoverColor: "green",
        }}
        onClick={handleMapClickInternal}
      />
      {selectedState && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "red",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p>
            State: {selectedState}
            <br />
            Users:{" "}
            {selectedCardData.stateCounts.find(
              (state) => state.state === selectedState
            ).count}
          </p>
        </div>
      )}
    </div>
  );
};

export default Mapping;

// import React from "react";
// import DatamapsIndia from "react-datamaps-india";

// const Mapping = ({ selectedCardData }) => {
//   const regionData = selectedCardData
//     ? selectedCardData.stateCounts.reduce((acc, state) => {
//         if (state.count > 0) {
//           acc[state.state] = { value: state.count };
//         }
//         return acc;
//       }, {})
//     : {};

//   const handleMapClick = (geo) => {
//     const stateData = selectedCardData.stateCounts.find(
//       (state) => state.state === geo.id
//     );
//     if (stateData) {
//       alert(`State: ${geo.id}\nUsers: ${stateData.count}`);
//     } else {
//       alert(`State: ${geo.id}\nNo data available`);
//     }
//   };

//   return (
//     <div className="relative w-full scale-150">
//       <DatamapsIndia
//         regionData={regionData}
//         hoverComponent={({ value }) => (
//           <div className="w-1/3 h-20 p-2 bg-white">
//             <p>{value?.name}</p>
//             <p>{value?.value} users</p>
//           </div>
//         )}
//         mapLayout={{
//           title: "",
//           legendTitle: "",
//           startColor: "#17cf0a",
//           endColor: "#FF6347",
//           hoverTitle: "User Count",
//           noDataColor: "#f5f5f5",
//           borderColor: "#8D8D8D",
//           hoverBorderColor: "#8D8D8D",
//           hoverColor: "green",
//         }}
//         onClick={handleMapClick}
//       />
//       {selectedCardData &&
//         selectedCardData.stateCounts.map((state) => (
//           <div
//             key={state.state}
//             className="absolute text-xs mt-5 h-13 top-14 left-1/2 transform -translate-x-1/2 translate-y-60 bg-white p-2 rounded shadow-md opacity-80"
//           >
//             <h3>State: {state.state}</h3>
//             <p>Users: {state.count}</p>
//           </div>
//         ))}
//     </div>
//   );
// };

// export default Mapping;

