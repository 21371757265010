// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const interestApi = createApi({
  reducerPath: "interestApi",
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_LOCAL_URL }),
  tagTypes: ["Interest"],
  endpoints: (builder) => ({
 
    getSingleInterest: builder.query({
      query: (endpoint) => `${endpoint}`,
    }),

 

    getInterestList: builder.query({
      query: (endpoint) => `${endpoint}`,
    }),

    deleteInterest: builder.mutation({
      query: (endpoint) => ({
        url: `${endpoint}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Interest"],
    }),

    addInterest: builder.mutation({
      query: ({ endpoint, newInterest }) => ({
        url: `${endpoint}`,
        method: "POST",
        body: newInterest
      }),
      invalidatesTags: ["Interest"],
    }),

    updateInterest: builder.mutation({
      query: ({ endpoint, updateInterest }) => ({
        url: `${endpoint}`,
        method: "PATCH",
        body:updateInterest,
      }),
      invalidatesTags: ["Interest"],
    }),
   
  }),
});

export const {
  useGetSingleInterestQuery,
  useDeleteInterestMutation,
  useAddInterestMutation,
  useUpdateInterestMutation,
  useGetInterestListQuery
} = interestApi;
