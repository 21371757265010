import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns"; // Import the date formatting function

// const NotificationList = ({ notifications }) => {
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedNotification, setSelectedNotification] = useState(null);

//   const handleCardClick = (notification) => {
//     setSelectedNotification(notification);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   return (
//     <>
//       <Grid container spacing={2}>
//         {notifications?.map((notification, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 borderRadius: 2,
//                 boxShadow: 3,
//                 transition: "transform 0.3s ease",
//                 cursor: "pointer",
//                 "&:hover": {
//                   transform: "scale(1.03)",
//                   boxShadow: 6,
//                 },
//               }}
//               onClick={() => handleCardClick(notification)}
//             >
//               {notification.image && (
//                 <CardMedia
//                   component="img"
//                   alt="Notification Image"
//                   height="140"
//                   image={URL.createObjectURL(notification.image)}
//                   sx={{ objectFit: "cover" }}
//                 />
//               )}
//               <CardContent
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: 1,
//                   p: 2,
//                   bgcolor: "background.default",
//                 }}
//               >
//                 <Typography variant="caption" color="text.secondary">
//                   {notification.date
//                     ? format(new Date(notification.date), "MMMM d, yyyy h:mm a")
//                     : "Date not available"}
//                 </Typography>
//                 <Typography
//                   variant="h6"
//                   component="div"
//                   sx={{ fontWeight: 600 }}
//                 >
//                   {notification.notificationTitle}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {notification.url}
//                 </Typography>
//                 <Typography variant="body2">
//                   {notification.description}
//                 </Typography>
//                 <Typography variant="body2" sx={{ mt: 1, fontStyle: "italic" }}>
//                   Audience: {notification.audience}
//                 </Typography>
//                 {notification.audience === "Entity" && (
//                   <Typography variant="body2">
//                     Entity: {notification.entity}
//                   </Typography>
//                 )}
//                 {notification.audience === "EntityType" && (
//                   <Typography variant="body2">
//                     Entity Type: {notification.entityType}
//                   </Typography>
//                 )}
//                 {notification.audience === "course" && (
//                   <Typography variant="body2">
//                     Course: {notification.course}
//                   </Typography>
//                 )}
//                 {notification.audience === "interest" && (
//                   <Typography variant="body2">
//                     Interest: {notification.interest}
//                   </Typography>
//                 )}
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Dialog to show full details */}
//       <Dialog
//         open={openDialog}
//         onClose={handleCloseDialog}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Typography variant="h6">Notification Details</Typography>
//             <IconButton onClick={handleCloseDialog}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//         </DialogTitle>
//         <DialogContent>
//           {selectedNotification && (
//             <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
//               {selectedNotification.image && (
//                 <CardMedia
//                   component="img"
//                   alt="Notification Image"
//                   height="200"
//                   image={URL.createObjectURL(selectedNotification.image)}
//                   sx={{ objectFit: "cover" }}
//                 />
//               )}
//               <Typography variant="h6" component="div">
//                 {selectedNotification.notificationTitle}
//               </Typography>
//               <Typography variant="body1" color="text.secondary">
//                 URL: {selectedNotification.url}
//               </Typography>
//               <Typography variant="body1">
//                 Description: {selectedNotification.description}
//               </Typography>
//               <Typography variant="body1" sx={{ fontStyle: "italic" }}>
//                 Audience: {selectedNotification.audience}
//               </Typography>
//               <Typography variant="body1" color="text.secondary">
//                 Created on:{" "}
//                 {selectedNotification.date
//                   ? format(
//                       new Date(selectedNotification.date),
//                       "MMMM d, yyyy h:mm a"
//                     )
//                   : "Date not available"}
//               </Typography>
//               {/* Conditionally render additional fields in the dialog */}
//               {selectedNotification.audience === "Entity" && (
//                 <Typography variant="body1">
//                   Entity: {selectedNotification.entity}
//                 </Typography>
//               )}
//               {selectedNotification.audience === "EntityType" && (
//                 <Typography variant="body1">
//                   Entity Type: {selectedNotification.entityType}
//                 </Typography>
//               )}
//               {selectedNotification.audience === "course" && (
//                 <Typography variant="body1">
//                   Course: {selectedNotification.course}
//                 </Typography>
//               )}
//               {selectedNotification.audience === "interest" && (
//                 <Typography variant="body1">
//                   Interest: {selectedNotification.interest}
//                 </Typography>
//               )}
//             </Box>
//           )}
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };





const NotificationList = ({ notifications }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const handleCardClick = (notification) => {
    const notificationData = notification.body[0]; // Assuming body contains the relevant data as an object

    console.log(notificationData);


    const extractedNotification = {
      id: notification._id,
      date: notification.createdAt,
      notificationTitle: notificationData.title || "No Title",
      description: notificationData.description || "No Description",
      url: notificationData.url || "No URL",
      sourceType: notificationData.sourceType || "No sourceType",
      sourceId: notificationData.sourceId || "No sourceId",

      image: notificationData.thumbnail || null,
    };

    setSelectedNotification(extractedNotification);

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {notifications?.map((notification, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                boxShadow: 3,
                transition: "transform 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: 6,
                },
              }}
              onClick={() => handleCardClick(notification)}
            >
              {notification.body[0].image && (
                <CardMedia
                  component="img"
                  alt="Notification Image"
                  height="140"
                  image={notification.body[0].image}  // Directly use the image URL
                  sx={{ objectFit: "cover" }}
                />
              )}
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  p: 2,
                  bgcolor: "background.default",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  {notification.createdAt
                    ? format(new Date(notification.createdAt), "MMMM d, yyyy h:mm a")
                    : "Date not available"}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: 600 }}
                >
                  {notification.body[0].title || "No Title"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {notification.body[0].url || "No URL"}
                </Typography>
                <Typography variant="body2">
                  {notification.body[0].description || "No Description"}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, fontStyle: "italic" }}>
                  Audience: {notification.body[0].topic || "No Audience"}
                </Typography>
                {notification.body[0].audience === "Entity" && (
                  <Typography variant="body2">
                    Entity: {notification.body[0].entity}
                  </Typography>
                )}
                {notification.body[0].audience === "EntityType" && (
                  <Typography variant="body2">
                    Entity Type: {notification.body[0].entityType}
                  </Typography>
                )}
                {notification.body[0].audience === "course" && (
                  <Typography variant="body2">
                    Course: {notification.body[0].course}
                  </Typography>
                )}
                {notification.body[0].audience === "interest" && (
                  <Typography variant="body2">
                    Interest: {notification.body[0].interest}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog to show full details */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Notification Details</Typography>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {console.log(selectedNotification)
          }
          {selectedNotification && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {selectedNotification.image && (
                <CardMedia
                  component="img"
                  alt="Notification Image"
                  height="200"
                  image={selectedNotification.image}  // Directly use the image URL
                  sx={{ objectFit: "cover" }}
                />
              )}
              <Typography variant="h6" component="div">
                {selectedNotification.topic}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                URL: {selectedNotification.url}
              </Typography>
              <Typography variant="body1">
                Description: {selectedNotification.description}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                Audience: {selectedNotification.sourceType}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Created on:{" "}
                {selectedNotification.date
                  ? format(
                    new Date(selectedNotification.date),
                    "MMMM d, yyyy h:mm a"
                  )
                  : "Date not available"}
              </Typography>
              {/* Conditionally render additional fields in the dialog */}
              {selectedNotification.audience === "Entity" && (
                <Typography variant="body1">
                  Entity: {selectedNotification.entity}
                </Typography>
              )}
              {selectedNotification.audience === "EntityType" && (
                <Typography variant="body1">
                  Entity Type: {selectedNotification.entityType}
                </Typography>
              )}
              {selectedNotification.audience === "course" && (
                <Typography variant="body1">
                  Course: {selectedNotification.course}
                </Typography>
              )}
              {selectedNotification.audience === "interest" && (
                <Typography variant="body1">
                  Interest: {selectedNotification.interest}
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NotificationList;


// export default NotificationList;
