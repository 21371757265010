// import React, { useEffect } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// const data = [
//   {
//     Cources: "CAT 2021",
//     Sales: 100,
//   },
//   {
//     Cources: "GMAT 2021",
//     Sales: 140,
//   },
//   {
//     Cources: "NMAT Crash",
//     Sales: 110,
//   },
//   {
//     Cources: "TOFEL Full",
//     Sales: 120,
//   },
//   {
//     Cources: "GRE Crash",
//     Sales: 100,
//   },
//   {
//     Cources: "Others",
//     Sales: 130,
//   },
// ];

// export default function CmlgGraph({course}) {
//   // console.log(course);


  
//   return (
//     <BarChart
//       width={700}
//       height={280}
//       data={data}
//       margin={{
//         top: 5,
//         right: 30,
//         left: 20,
//         bottom: 5,
//       }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="Courses" />
//       <YAxis />
//       <Tooltip />
//       <Legend />
//       <Bar
//         dataKey="Sales"
//         fill="#ffb24d"
//         barSize={20}
//         radius={[10, 10, 0, 0]}
//       />
//     </BarChart>
//   );
// }


// import React, { useEffect, useState } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

// export default function CmlgGraph({ course }) {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     if (course && Array.isArray(course)) {
//       const transformedData = course.map((c) => ({
//         Courses: c.title,
//         Sales: c.dailySales.reduce((acc, item) => acc + item.sales, 0), // Sum of sales for the course
//       }));

//       setChartData(transformedData);
//     }
//   }, [course]);

//   return (
//     <BarChart
//       width={700}
//       height={280}
//       data={chartData}
//       margin={{
//         top: 5,
//         right: 30,
//         left: 20,
//         bottom: 5,
//       }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="Courses" />
//       <YAxis />
//       <Tooltip />
//       <Legend />
//       <Bar
//         dataKey="Sales"
//         fill="#ffb24d"
//         barSize={20}
//         radius={[10, 10, 0, 0]}
//       />
//     </BarChart>
//   );
// }



// import React, { useEffect, useState } from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// export default function CmlgGraph({ course }) {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     if (course && Array.isArray(course)) {
//       const transformedData = course.map((c) => ({
//         Courses: c.title.length > 10 ? `${c.title.substring(0, 10)}...` : c.title, // Shorten title if too long
//         Sales: c.dailySales.reduce((acc, item) => acc + item.sales, 0), // Sum of sales for the course
//       }));

//       setChartData(transformedData);
//     }
//   }, [course]);

//   return (
//     <ResponsiveContainer width="100%" height={300}>
//       <BarChart
//         data={chartData}
//         margin={{
//           top: 5,
//           right: 30,
//           left: 20,
//           bottom: 50,  // Increased to give space for rotated labels
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis 
//           dataKey="Courses" 
//           interval={0} 
//           angle={-45}  // Rotates the labels for better readability
//           textAnchor="end"
//         />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Bar
//           dataKey="Sales"
//           fill="#ffb24d"
//           barSize={20}
//           radius={[10, 10, 0, 0]}
//         />
//       </BarChart>
//     </ResponsiveContainer>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CmlgGraph({ course }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (course && Array.isArray(course)) {
      const transformedData = course.map((c) => ({
        Courses: c.title,
        Sales: c.dailySales.reduce((acc, item) => acc + item.sales, 0), // Sum of sales for the course
      }));

      setChartData(transformedData);
    }
  }, [course]);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <ResponsiveContainer width={chartData.length * 100} height={300}>
        <BarChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 40, // Increased bottom margin for rotated labels
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Courses"
            interval={0}
            angle={-45} // Rotate labels to avoid overlap
            textAnchor="end"
            height={70} // Adjusted height for the rotated labels
            tick={{ fontSize: 12 }} // Smaller font size for labels
          />
          <YAxis />
          <Tooltip
            wrapperStyle={{ backgroundColor: "#fff", border: "1px solid #ccc", padding: "5px" }}
            contentStyle={{ fontSize: 12 }} // Smaller font size for tooltip content
          />
          <Legend />
          <Bar
            dataKey="Sales"
            fill="#ffb24d"
            barSize={20}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
