// import React, { useEffect, useState } from "react";

// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { MoreVertical } from "lucide-react";

// import ExpressGraph from "./expressGraph";

// const Express = ({ doubt }) => {
//   const [age, setAge] = useState(30); // Default to 30 days (one month)
//   const [filteredDoubt, setFilteredDoubt] = useState(doubt);
//   // console.log(doubt);


//   useEffect(() => {
//     const filterDoubt = (days) => {
//       const now = new Date();
//       const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

//       return doubt.map((item) => {
//         const filteredSales = item.dailySales.filter((sales) => {
//           const salesDate = new Date(sales.date);
//           return salesDate >= daysAgo;
//         });
//         return { ...item, dailySales: filteredSales };
//       });
//     };

//     setFilteredDoubt(filterDoubt(age));
//   }, [age, doubt]);

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   return (
//     <>
//       <div className="w-2/4 h-96   border border-#cfd0d1-50 rounded-md ml-4">
//         <div>
//           <div>
//             <div className="flex  w-full text-center h-12 justify-between p-2">
//               <h2 className=" text-lg font-bold">Express Doubt Solving</h2>
//               <div className="mr-6">
//                 <Box sx={{ minWidth: 150 }} height="5px">
//                   <FormControl fullWidth size="small">
//                     <Select
//                       id="demo-simple-select"
//                       value={age}
//                       onChange={handleChange}
//                       displayEmpty
//                       renderValue={(selected) =>
//                         selected === 30 ? (
//                           <em>Past one Month</em>
//                         ) : (
//                           `${selected} Days`
//                         )
//                       }
//                     >
//                       <MenuItem disabled value="">
//                         <em>Past one Month</em>
//                       </MenuItem>
//                       {/* <MenuItem value={"Past One Month"}>
//                         Past one Month
//                       </MenuItem> */}

//                       <MenuItem value={30}>Thirty</MenuItem>
//                       <MenuItem value={20}>Twenty</MenuItem>
//                       <MenuItem value={10}>Ten</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Box>
//                 <div className="flex justify-end -mr-7">
//                   <MoreVertical />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
//           <div className="w-full ml-3 h-72">
//             <ExpressGraph doubt={filteredDoubt} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Express;


import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";

import ExpressGraph from "./expressGraph";

const Express = ({ doubt }) => {
  const [age, setAge] = useState(30); // Default to 30 days (one month)
  const [filteredDoubt, setFilteredDoubt] = useState(doubt);

  useEffect(() => {
    const filterDoubt = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);

      return doubt.filter((item) => {
        const salesDate = new Date(item.date);
        return salesDate >= daysAgo;
      });
    };

    setFilteredDoubt(filterDoubt(age));
  }, [age, doubt]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="w-2/4 h-96 border border-#cfd0d1-50 rounded-md ml-4">
        <div>
          <div>
            <div className="flex w-full text-center h-12 justify-between p-2">
              <h2 className="text-lg font-bold">Express Doubt Solving</h2>
              <div className="mr-6">
                <Box sx={{ minWidth: 150 }} height="5px">
                  <FormControl fullWidth size="small">
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) =>
                        selected === 30 ? (
                          <em>Past one Month</em>
                        ) : (
                          `${selected} Days`
                        )
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Past one Month</em>
                      </MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex justify-end -mr-7">
                  <MoreVertical />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div className="w-full ml-3 h-72">
            <ExpressGraph doubt={filteredDoubt} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Express;
