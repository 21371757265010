import React from "react";
import { Stack, Typography } from "@mui/material";
import UageGraph from "./uageGraph";

const UserAge = ({ userData, ageStart, ageEnd }) => {
  // Count male and female users
  let maleCount = 0;
  let femaleCount = 0;
  let otherCount = 0;

  const graphData = userData.data.map((user) => {
    if (user.gender === "male") {
      maleCount += user.count;
    } else if (user.gender === "female") {
      femaleCount += user.count;
    } else if (user.gender === "others") {
      otherCount += user.count;
    }
    return {
      date: user.date,
      male: user.male,
      female: user.female,
      others: user.others,
    };
  });

  return (
    <>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"space-between"}
        sx={{ marginTop: "30px" }}
      >
        <Stack direction={"column"} spacing={1} padding={"10px"}>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {ageStart} - {ageEnd}
          </Typography>
          <Typography variant="subtitle2">Selected Age Range</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {maleCount}
          </Typography>
          <Typography variant="subtitle2">Total Male</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {femaleCount}
          </Typography>
          <Typography variant="subtitle2">Total Female</Typography>
          <Typography variant="h6" sx={{ fontWeight: "700" }}>
            {otherCount}
          </Typography>
          <Typography variant="subtitle2">Total Others</Typography>
        </Stack>

        <UageGraph graphData={graphData} />
      </Stack>
    </>
  );
};

export default UserAge;
