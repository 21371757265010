import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import { useGetCoursesAnalyticsQuery } from "../../../../../../services/apis/exam/courses";
import { courseApi } from "../../../../../../services/Constant";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import RevenueGraph from "./revenueGraph";

const Revenue = () => {
  const params = useParams();
  const [filterType, setFilterType] = useState(30);
  const [totalSales, setTotalSales] = useState(0);

  const { data, isLoading, isSuccess } = useGetCoursesAnalyticsQuery(
    `${courseApi.endPoint}/analysis/priceTrend/${params.examId}/?days=${filterType}`
  );

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };

  useEffect(() => {
    if (isSuccess && data) {
      const totalSalesCount = data.data.reduce((acc, course) => {
        return (
          acc +
          course.sales.reduce(
            (courseAcc, sale) => courseAcc + sale.totalPaidAmount,
            0
          )
        );
      }, 0);

      setTotalSales(totalSalesCount);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess || !data) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <>
      <div className="w-2/4 h-96 border border-gray-200 rounded-md ml-4">
        <div>
          <div className="flex w-full text-center h-12 justify-between p-2">
            <h2 className="text-lg font-bold">Revenue Trend</h2>
            <div className="mr-6">
              <Box sx={{ minWidth: 150 }} height="5px">
                <FormControl fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterType}
                    onChange={handleFilterChange}
                    displayEmpty
                    renderValue={(selected) =>
                      selected.length === 0 ? (
                        <em>This Week</em>
                      ) : (
                        filterTypeLabels[selected]
                      )
                    }
                    size="small"
                  >
                    <MenuItem value={7}>
                      <em>This Week</em>
                    </MenuItem>
                    <MenuItem value={30}>Month</MenuItem>
                    <MenuItem value={90}>3 Month</MenuItem>
                    <MenuItem value={180}>6 Month</MenuItem>
                    <MenuItem value={365}>1 Year</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div className="flex justify-end -mr-7">
                <MoreVertical />
              </div>
             
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div>
            <h2 className="text-xl font-bold">
              ₹{totalSales.toLocaleString()}
            </h2>
            <p className="mt-3 text-sm">Total Revenue</p>
          </div>
          <div className="ml-3 h-72">
            <RevenueGraph salesData={data.data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Revenue;
