import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MoreVertical } from "lucide-react";
import MockGraph from "./mockGraph";

const Mock = ({mock}) => {
  const [age, setAge] = useState("");
  const [checked, setChecked] = useState(false);
  const [filteredMock, setFilteredMock] = useState(mock);




  useEffect(() => {
    const filterMock = (days) => {
      const now = new Date();
      const daysAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
      return mock.filter((item) => {
        const itemDate = new Date(item.date);
        return itemDate >= daysAgo;
      });
    };

    if (age) {
      setFilteredMock({
        ...mock,
        result: filterMock(age),
      });
    } else {
      setFilteredMock(mock);
    }
  }, [age, mock]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <div className="w-2/4 h-96   border border-#cfd0d1-50 rounded-md ml-4">
        <div>
          <div>
            <div className="flex  w-full text-center h-12 justify-between p-2">
              <h2 className=" text-lg font-bold">Mock</h2>
              <div className="mr-6">
                <Box sx={{ minWidth: 150 }} height="5px">
                  <FormControl fullWidth size="small">
                    <Select
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) =>
                        selected.length === 0 ? <em>Past one Month</em> : `${selected} Days`
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Past one Month</em>
                      </MenuItem>
                      {/* <MenuItem value={"Past One Month"}>
                        Past one Month
                      </MenuItem> */}

                      <MenuItem value={30}>Thirty Days</MenuItem>
                      <MenuItem value={20}>Twenty Days</MenuItem>
                      <MenuItem value={10}>Ten Days</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex justify-end -mr-7">
                  <MoreVertical />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-2 mt-10 overflow-hidden rounded-lg">
          <div className="w-full ml-3 h-72">
            <MockGraph  mock = {filteredMock}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mock;
