import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const TuserGraph = ({ data, totalCount }) => {
  console.log("TuserGraphData", data);
  // const series = [788, 255, 458, 125];
  // const total = series.reduce((acc, curr) => acc + curr, 0);
  const titles = data.map(item => item.title);
  const counts = data.map(item => item.count);

  // Prepare series data for the chart
  const series = counts;
  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total Users",
              formatter: () => totalCount,
              style: {
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
                color: "#263238",
              },
            },
          },
        },
      },
    },
    labels: titles,
    dataLabels: {
      enabled: false, // Disable the data labels
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
};

export default TuserGraph;
