import { Stack, Typography, TextField, Input } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box, MoreVertical } from "lucide-react";
import { Square } from "lucide-react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useGetGroupAnalyticsQuery } from "../../../../../../../services/apis/exam/group";
import { groupApi } from "../../../../../../../services/Constant";
import { useParams } from "react-router-dom";
import Analytic from "./analytic"


const Navbars = () => {
  const params = useParams();
  const [ageStart, setAgeStart] = useState("");
  const [ageEnd, setAgeEnd] = useState("");
  const [filterType, setFilterType] = useState(30);
  

  const { data, isLoading, isSuccess, isError, error } =
    useGetGroupAnalyticsQuery(
      `${groupApi.endPoint}/analytics/list/${params.groupId}/gender?days=${filterType}&minAge=10&maxAge=30`
    );
  const handleAgeStartChange = (event) => {
    setAgeStart(event.target.value);
  };

  const handleAgeEndChange = (event) => {
    setAgeEnd(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };
  const filterTypeLabels = {
    7: "This Week",
    30: "Month",
    90: "3 Month",
    180: "6 Month",
    365: "1 Year",
  };
  useEffect(() => {
    console.log(data, "genderData");
  }, [data]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isSuccess || !data) {
    return <Typography>Error loading data</Typography>;
  }
  return (
    <>
      <div className="flex justify-between items-center">
        <Typography variant="body1" sx={{ fontWeight: "700", padding: "10px" }}>
          Users By Age and Gender
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="subtitle1">Set age range :</Typography>
          <TextField
            id="age-range-start"
            sx={{ width: "130px", height: "37px" }}
            variant="outlined"
            size="small"
            value={ageStart}
            onChange={handleAgeStartChange}
          />
          <Typography variant="subtitle2">to</Typography>
          <TextField
            id="age-range-end"
            sx={{ width: "130px", height: "37px" }}
            variant="outlined"
            size="small"
            value={ageEnd}
            onChange={handleAgeEndChange}
          />
          <FormControl>
            <Select
              labelId="sorted-by-select-label"
              id="sorted-by-select"
              value={filterType}
              onChange={handleFilterChange}
              displayEmpty
              renderValue={(selected) =>
                filterTypeLabels[selected] || <em>Sorted By</em>
              }
              sx={{ width: "150px", height: "37px" }}
            >
              <MenuItem disabled value="">
                <em>Sorted By</em>
              </MenuItem>
              <MenuItem value={7}>This Week</MenuItem>
              <MenuItem value={30}>Month</MenuItem>
              <MenuItem value={90}>3 Month</MenuItem>
              <MenuItem value={180}>6 Month</MenuItem>
              <MenuItem value={365}>1 Year</MenuItem>
            </Select>
          </FormControl>
          <MoreVertical />
        </Stack>
      </div>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ marginTop: "10px", marginRight: "10px" }}
      >
        <Square fill="#82ca9d" strokeWidth={0} />
        <Typography variant="body1">Male</Typography>
        <Square fill="#8884d8" strokeWidth={0} />
        <Typography variant="body1">Female</Typography>
        <Square fill="#f7c757" strokeWidth={0} />
        <Typography variant="body1">Others</Typography>
      </Stack>
      <Analytic userData={data} ageStart={ageStart} ageEnd={ageEnd} />
    </>
  );
};

export default Navbars;



