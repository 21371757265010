
import React from "react";

const Count = ({ experienceData }) => {
  const userCounts = experienceData.map((item) => item.users);
  const totalUsers = userCounts.reduce((acc, count) => acc + count, 0);
  const progressScale = experienceData.map((item, index) => ({
    value: (item.users / totalUsers) * 100,
    label: item.users,
    color:
      index === 0
        ? "#E9F2EE"
        : index === 1
        ? "#BEEAD5"
        : index === 2
        ? "#7DD2AA"
        : index === 3
        ? "#3CD38B"
        : index === 4
        ? "#24B670"
        : "#058849",
  }));

  return (
    <div>
      <h2 className="text-lg font-medium mb-4 text-gray-500">Users</h2>
      <div className="mb-8 overflow-hidden relative">
        <div className="h-full flex items-center">
          {progressScale.map((section, index) => (
            <div
              key={index}
              className="h-full"
              style={{
                height: "25px",
                position: "relative",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundColor: section.color,
                  height: "10px",
                  width: "100%",
                }}
              ></div>
              <div
                className="h-full w-9 flex items-center justify-center"
                style={{ position: "absolute", top: "6px" }}
              >
                <span className="text-xs font-bold text-gray-700">
                  {section.label}
                </span>
              </div>
            </div>
          ))}
        </div>
        {/* Display user counts outside the progress scale */}
        {progressScale.map((section, index) => (
          <div
            key={index}
            className="absolute"
            style={{
              left: `${section.value}%`,
              bottom: "-30px",
              transform: "translateX(-50%)",
            }}
          >
            <span className="text-xs font-bold text-gray-700">
              {section.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Count;


