import React, { useEffect, useState } from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import NotificationList from "./notificationList";
import AddNotification from "./addNotification";
import { useGetNotificationsListQuery } from "../../../../services/apis/notificationApi";
import { notificationApi } from "../../../../services/Constant";

const NotificationMain = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // {{8004}}/exams/v1/notification/all/list
  const { data, isLoading, isSuccess, error,refetch } = useGetNotificationsListQuery(`${notificationApi.endPoint}/all/list`,{
    refetchOnMountOrArgChange: true,

  });




  useEffect(() => {
    if (isSuccess && data) {
      console.log('Fetched Data:', data);
    }
    if (error) {
      console.error('Fetch Error:', error);
    }

    refetch()
  }, [isSuccess, data, error]);

  let datas = data?.data?.notificationList


  // Conditionally render based on the loading state
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
  }

  // If there's no data but the request was successful, you might want to handle the case
  if (!isLoading && isSuccess && !data) {
    return <div>No data available</div>;
  }

  

  // const datas = data.data.notificationList

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFormSubmit = (notification) => {
    setNotifications([...notifications, notification]);
    handleClose();
  };



  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        style={{ float: "right" }}
      >
        Add Notification
      </Button>
      <NotificationList notifications={datas} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-notification-modal"
        aria-describedby="add-notification-form"
      >
        <Box sx={modalStyle}>
          <Typography id="add-notification-modal" variant="h6" component="h2">
            Add Notification
          </Typography>
          <AddNotification
            onFormSubmit={handleFormSubmit}
            onCancel={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Increase width for better layout
  maxWidth: "800px", // Set max width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default NotificationMain;
