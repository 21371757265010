
import React, { useEffect, useState } from "react";
import FeedList from "./feedList";
import AddFeed from "./addFeed";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useGetFeedAlgoListQuery } from "../../../services/apis/feedAlgoApi";
import { feedAlgo } from "../../../services/Constant";

const FeedMain = () => {
  const [feeds, setFeeds] = useState([]);
  const [open, setOpen] = useState(false);


  const { data, isLoading, isSuccess, error } = useGetFeedAlgoListQuery(`${feedAlgo.endPoint}/list`);

  useEffect(() => {
    if (isSuccess && data) {
      console.log('Fetched Data:', data);
    }
    if (error) {
      console.error('Fetch Error:', error);
    }
  }, [isSuccess, data, error]);

  let datas = data?.data?.feedAlgo;


  // Conditionally render based on the loading state
  if (isLoading) {
    return <div>Loading...</div>;  // You can replace this with a loading spinner or custom loader
  }

  // If there's no data but the request was successful, you might want to handle the case
  if (!isLoading && isSuccess && !data) {
    return <div>No data available</div>;
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addFeed = (newFeed) => {
    setFeeds([...feeds, newFeed]);
    handleClose();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{ marginTop: "15px", marginRight: "10px" }}
        >
          ⊕ Add Feed Algo
        </Button>
      </div>
      <FeedList feeds={datas} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Feed</DialogTitle>
        <DialogContent>
          <AddFeed addFeed={addFeed} handleClose={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedMain;
