import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; 

function DashboardHeader() {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (date) => {
    setDate(date);
    
  };

  return (
    <div>
      <Toolbar className="flex justify-between">
        <Typography
          variant="h5"
          className="text-black"
          style={{ fontWeight: 700, marginTop: "35px" }}
        >
          Hi Dev, Welcome Back!
        </Typography>
        <div className="flex items-center">
          <Button
            color="inherit"
            startIcon={<CalendarTodayIcon />}
            onClick={handleClickOpen}
            style={{ fontWeight: 700 }}
          >
            View iQuanta Calendar
          </Button>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
        </div>
      </Toolbar>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Calendar</DialogTitle>
        <DialogContent>
          <Calendar onChange={onChange} value={date} />
        </DialogContent>
      </Dialog>
      <Typography
        variant="h7"
        className="text-blue-900"
        style={{ marginLeft: "25px", fontWeight: 600, marginBottom: "30px" }}
      >
        Admin
      </Typography>
     
    </div>
  );
}

export default DashboardHeader;
