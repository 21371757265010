import React from "react";
import Container from "./container";

function GroupAnalytics() {
  return (
    <>
      <Container />
    </>
  );
}

export default GroupAnalytics;
